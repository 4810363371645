import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";

function TotalNumberofShift() {
  const [boardType, setBoardType] = useState();
  const [shift, setShift] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const navigate = useNavigate();

  const handleBoard = (value) => {
    setBoardType(value);
  };

  const GotoGrade = () => {
    navigate(`/onboard/addShift`, { state: shift });
  };

  const handleChange = (value) => {
    setSelectedNumber(value);
    let data = [];
    for (let i = 1; i <= value; i++) {
      let val = {
        id: i,
        title: `Shift ${i}`,
      };
      data.push(val);
    }
    setShift(data);
  };

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.centerContainer}>
          <div className={styles.shifts}>
            <p className={styles.fontData}>Select total no. of shifts: </p>
            {[1, 2].map((v, i) => (
              <div
                className={`${styles.shiftContainer} ${
                  selectedNumber == v
                    ? `${styles.shiftSelected} ${styles.shiftSelection}`
                    : styles.shiftSelection
                }`}
                onClick={() => handleChange(v)}
              >
                {v}
              </div>
            ))}
          </div>

          <div className={styles.shifts}>
            <p className={styles.fontData}>Select shifts: </p>
            {shift &&
              shift.map((v, i) => (
                <div className={styles.shiftSelection}>{v.title}</div>
              ))}
          </div>
        </div>

        <div>
          <button
            className={styles.btns}
            onClick={() => GotoGrade()}
            disabled={!shift.length > 0}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default TotalNumberofShift;
