import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { api_open, api_token } from "../../Utils/Network";
import FormControl from "@mui/material/FormControl";
import {
  Alert,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import loading_screen from "../../Assets/Onboarding/LOADING_ANIMATIONF.mp4";
import DialogBox from "../../Components/DialogBox/DialogBox";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TimeTable() {
  const [value, setValue] = React.useState(0);
  const [classId, setClassId] = useState(null);
  const [timetableData, setTimetableData] = useState([]);
  const [typeShift, setTypeShift] = useState([]);
  const [isLoadDynamic, setIsLoadDynamic] = useState(false);
  const [dynamicList, setDynamicList] = useState([]);
  const [dynamicColor, setDynamicColor] = useState(null);
  const [generation, setGeneration] = useState(1);
  const [shifts, selectShift] = useState("");
  const [runFunction, setrunFunction] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { setUsers } = useContext(UserCredsContext);
  const [handleButton, setHandleButton] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    getShifts(); // To get the shift which is present
    setrunFunction(true);
  }, []);

  useEffect(() => {
    if (runFunction) {
      getData();
    }
  }, [value]);

  /***************       Initial state for getting state         ********************/
  const getShifts = () => {
    api_token
      .get(`/calendar/v1/shifts/`)
      .then((res) => {
        const resultData = res.data.data;
        if (res.data.data) {
          setTypeShift(resultData);
          getClasses(resultData); // Function to get classes and we have passed shift array inside it
        }
      })
      .catch((err) => console.log(err));
  };

  const getClasses = (v) => {
    setIsLoadDynamic(true);
    const filteredData = v && v.filter((obj) => obj.title === `Shift 1`);
    if (filteredData && filteredData.length > 0) {
      selectShift(filteredData[0]?.title);
      setDynamicColor(filteredData[0]?.id);
      api_token
        .get(
          `/calendar/v1/shifts/${filteredData[0]?.id}/shifts_listings_by_type/?type=1`
        )
        .then((res) => {
          setIsLoadDynamic(false);
          setDynamicList(res.data.data);
          setClassId(res.data.data[0].id);
          getdatas(filteredData[0]?.id, res.data.data); // function to generate the timetable
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getdatas = (shift, v) => {
    api_token
      .get(`/external/v2/time_table_generator/?shift=${shift}`)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getFirstGenTimetable(v[0]?.id, generation, shift);
        }
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    const filteredData =
      typeShift && typeShift.filter((obj) => obj.title === shifts);
    // typeShift.filter((obj) => obj.title === `Shift ${value + 1}`);
    const time = filteredData[0]?.id;
    setIsLoading(true);
    api_token
      .get(`/external/v2/time_table_generator/?shift=${time}`)
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          setClassId(res.data.data[0].id);
          gettimeTable(classId, generation);
        }
      })
      .catch((err) => console.log(err));
  };

  /**************************       End of Initial State        ****************************************/

  /*************************        Handle Data by event        ****************************************/
  const handleDragStart = (e, day, index) => {
    // debugger;
    console.log(e, day, index);

    let data = Object.keys(day);
    let period = day[data[0]][index].id;

    let dataToSubmit = {
      slot_type: value + 1,
      period_id: period,
    };
    e.dataTransfer.setData("day", data[0]);
    e.dataTransfer.setData("index", index);
    api_token
      .post(`/calendar/v1/time_table/slot_status_timetable/`, dataToSubmit)
      .then((res) => {
        setTimetableData(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetDay, targetIndex) => {
    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // debugger;
    let target = targetDay;
    // console.log(timetable, "inside drop")
    const draggedDay = e.dataTransfer.getData("day");
    const oldIndex = e.dataTransfer.getData("index");
    let index = days.findIndex((v) => {
      return v == draggedDay;
    });
    let targetInd = days.findIndex((v) => {
      return v == Object.keys(targetDay)[0];
    });
    const newTimetable = [...timetableData]; // Create a new copy
    const draggedItem = timetableData[index][draggedDay][oldIndex];
    const targetItem = target[Object.keys(targetDay)[0]][targetIndex];
    if (targetItem.is_slot_available !== false) {
      const swapConfirmation = window.confirm(
        `Do you want to swap this class with "${
          targetItem.subject?.title || "Free"
        }"?`
      );

      if (swapConfirmation) {
        let targetObj = {
          id: targetItem.id,
          commence: draggedItem.commence,
          conclude: draggedItem.conclude,
          day: draggedItem.day,
        };

        let dragedObj = {
          id: draggedItem.id,
          commence: targetItem.commence,
          conclude: targetItem.conclude,
          day: targetItem.day,
        };

        let arr = [targetObj, dragedObj];
        console.log(arr);

        api_token
          .post(`calendar/v1/time_table/update_timetable/`, arr)
          .then((res) => {
            if (res.status == 200) {
              gettimeTable(classId, generation);
            }
          })
          .catch((err) => console.log(err));
      } else {
        const modifiedData = newTimetable.map((dayObj) => {
          Object.keys(dayObj).forEach((key) => {
            dayObj[key] = dayObj[key].map((item) => {
              const { is_slot_available, ...rest } = item;
              return rest;
            });
          });
          return dayObj;
        });
        setTimetableData(modifiedData);
      }

      // Check if both items are
      //   newTimetable[index][draggedDay][oldIndex] = targetItem;
      //   newTimetable[targetInd][Object.keys(targetDay)[0]][targetIndex] =
      //     draggedItem;
    } else {
      const modifiedData = newTimetable.map((dayObj) => {
        // Iterate over the keys of each object in the dayObj
        Object.keys(dayObj).forEach((key) => {
          // Remove the is_slot_available key from each object in the array
          dayObj[key] = dayObj[key].map((item) => {
            const { is_slot_available, ...rest } = item;
            return rest;
          });
        });
        return dayObj;
      });
      setTimetableData(modifiedData);
    }
  };

  /*************************        End of Event                ****************************************/

  const handleChange = (event, newValue) => {
    // debugger;
    setIsLoadDynamic(true);
    setValue(newValue);
    const filteredData =
      typeShift && typeShift.filter((obj) => obj.title === `Shift 1`);
    setDynamicColor(filteredData[0]?.id);
    api_token
      .get(
        `/calendar/v1/shifts/${
          filteredData[0]?.id
        }/shifts_listings_by_type/?type=${newValue + 1}`
      )
      .then((res) => {
        setIsLoadDynamic(false);
        setDynamicList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFirstGenTimetable = (v, gen, shift) => {
    let names = "";
    let schedules = "";
    if (value) {
      names = "teacher";
      schedules = "weekend_teacher_schedule";
    } else {
      names = "assigned_class";
      schedules = "weekend_schedule";
    }
    // assigned_class
    api_token
      .get(
        `/calendar/v1/time_table/${schedules}?${names}=${v}&shift=${shift}&generation=${gen}`
      )
      .then((res) => {
        if (res.data.data) {
          setIsLoading(false);
          setTimetableData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const gettimeTable = (v, gen) => {
    // debugger;
    const filteredData =
      typeShift && typeShift.filter((obj) => obj.title === `${shifts}`);
    const time = filteredData[0]?.id;

    let names = "";
    let schedules = "";
    if (value) {
      names = "teacher";
      schedules = "weekend_teacher_schedule";
    } else {
      names = "assigned_class";
      schedules = "weekend_schedule";
    }
    // assigned_class
    api_token
      .get(
        `/calendar/v1/time_table/${schedules}?${names}=${v}&shift=${time}&generation=${gen}`
      )
      .then((res) => {
        if (res.data.data) {
          setIsLoading(false);
          setTimetableData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShift = (v) => {
    console.log(v, value, "VID");
    setDynamicColor(v.id);
    selectShift(v.title);
    setIsLoad(true);
    setIsLoadDynamic(true);
    api_token
      .get(
        `external/v1/validate_calender/?shift=${v?.id}&generation=${generation}`
      )
      .then((res) => {
        if (res.data.status) {
          api_token
            .get(
              `/calendar/v1/shifts/${v?.id}/shifts_listings_by_type?type=${
                value + 1
              }`
            )
            .then((res) => {
              setIsLoad(false);
              setIsLoadDynamic(false);
              setDynamicList(res.data.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setIsLoad(true);
          setIsLoadDynamic(true);
          api_token
            .get(`/external/v2/time_table_generator/?shift=${v?.id}`)
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                api_token
                  .get(
                    `/calendar/v1/shifts/${
                      v?.id
                    }/shifts_listings_by_type?type=${value + 1}`
                  )
                  .then((res) => {
                    setIsLoad(false);
                    setIsLoadDynamic(false);
                    setDynamicList(res.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const selectionClassStudent = (v) => {
    setClassId(v.id);
    gettimeTable(v.id, generation);
  };

  const handleForms = (v) => {
    setIsLoading(true);
    let data = v.target.value;
    const filteredData =
      typeShift &&
      typeShift.filter((obj) => obj.title === `Shift ${value + 1}`);
    const time = filteredData[0]?.id;

    let names = "";
    let schedules = "";
    if (value) {
      names = "teacher";
      schedules = "weekend_teacher_schedule";
    } else {
      names = "assigned_class";
      schedules = "weekend_schedule";
    }

    api_token
      .get(`external/v1/validate_calender/?shift=${time}&generation=${data}`)
      .then((res) => {
        if (res.data.status) {
          api_token
            .get(
              `/calendar/v1/time_table/${schedules}?${names}=${classId}&shift=${time}&generation=${data}`
            )
            .then((res) => {
              if (res.data.data) {
                setIsLoading(false);
                setTimetableData(res.data.data);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          api_token
            .get(`/external/v2/time_table_generator/?shift=${time}`)
            .then((res) => {
              console.log(res);
              if (res.status == 200 || res.status == 201) {
                gettimeTable(classId, data);
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  const dataSend = () => {
    let gen = generation;
    let completedRequests = 0; // Counter to track completed API requests
    const totalRequests = typeShift.length; // Total number of API requests
    setIsLoad(true);
    setHandleButton(false);
    typeShift.map((v, i) => {
      api_token
        .get(`external/v1/set_calender/?shift=${v.id}&generation=${gen}`)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            completedRequests++;

            if (completedRequests === totalRequests) {
              api_token
                .patch(`auth/v1/user/update_school_detail/`, {
                  is_onboarded: true,
                })
                .then((res) => {
                  if (res.status == 201 || res.status == 200) {
                    setUsers(res.data.data);
                    setIsLoad(false);
                    navigate(`/onboard/timetableDownload`);
                  }
                })
                .catch((err) => console.log(err));
            }
          } else {
            setHandleButton(true);
          }
        })
        .catch((err) => {
          setHandleButton(true);
          console.log(err);
        });
    });
  };

  const handleClose = () => {
    setHandleButton(false);
    setIsLoad(true);
    api_token
      .get(`external/v1/restore_previous_version`)
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          navigate(`/dashboard`);
          setOpen(false);
          setHandleButton(true);
          setIsLoad(false);
        }
      })
      .catch((err) => {
        setIsLoad(false);
        setHandleButton(true);
      });
  };
  const GotoGrade = () => {
    setOpen(true);
  };

  console.log(typeShift, timetableData, "ClassList");
  const days = timetableData.map((obj) => Object.keys(obj)[0]);
  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered by Ed5</p>
        </div>

        <div style={{ width: "20%", marginLeft: "auto" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Calendar
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Calendar"
              name="generation"
              value={generation}
              onChange={(e) => {
                handleForms(e);
                setGeneration(e.target.value);
              }}
              style={{ width: "80%", color: "white" }}
            >
              <MenuItem value={1}>Generated 1</MenuItem>
              <MenuItem value={2}>Generated 2</MenuItem>
              <MenuItem value={3}>Generated 3</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={styles.mainTable}>
          <div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {typeData.map((v, i) => (
                    <Tab
                      label={v.title}
                      {...a11yProps(0)}
                      sx={{
                        color: "white !important",
                        fontSize: "20px",
                        fontFamily: "Barlow",
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
              {typeData.map((datas, i) => (
                <TabPanel value={value} index={i}>
                  <div className={styles.shiftsTypes}>
                    {typeShift &&
                      typeShift.map((v, i) => (
                        <div
                          //   className={styles.singleShift}
                          className={`${styles.singleShift}
                                                            ${
                                                              v.id ==
                                                              dynamicColor
                                                                ? styles.selectedSingleShift
                                                                : ""
                                                            }
                          `}
                          onClick={() => handleShift(v)}
                        >
                          {v.title}
                        </div>
                      ))}
                  </div>
                  <div className={styles.cls}>
                    {isLoadDynamic ? (
                      <div>Loading ...</div>
                    ) : (
                      <div>
                        {dynamicList.map((v, i) => (
                          <div
                            onClick={() => selectionClassStudent(v)}
                            className={
                              v.id == classId
                                ? styles.selected
                                : styles.normalClass
                            }
                          >
                            {value
                              ? `${v?.user?.first_name} ${v?.user?.last_name}`
                              : v.title}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </TabPanel>
              ))}
            </Box>
          </div>

          <div className={styles.tableContainersMain}>
            <div>
              {isLoading ? (
                <div style={{ width: "100%" }}>
                  <video
                    style={{ width: "100%" }}
                    src={loading_screen}
                    muted
                    autoPlay
                  />
                </div>
              ) : (
                <table className={styles.tableData}>
                  <thead>
                    <tr>
                      <th style={{ color: "white" }}>Time</th>
                      {days.map((day) => (
                        <th key={day} style={{ color: "white" }}>
                          {day}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {timetableData[0] &&
                      timetableData[0][Object.keys(timetableData[0])[0]].map(
                        (timeSlot, index) => (
                          <tr key={index}>
                            <td style={{ color: "white" }}>
                              {moment(timeSlot?.commence, "HH:mm:ss").format(
                                "HH:mm"
                              )}{" "}
                              -{" "}
                              {moment(timeSlot?.conclude, "HH:mm:ss").format(
                                "HH:mm"
                              )}
                            </td>
                            {timetableData.map((dayData) => (
                              <td key={dayData}>
                                <div
                                  className={`${styles.da} ${
                                    dayData[Object.keys(dayData)[0]][index]
                                      ?.is_slot_available !== undefined
                                      ? dayData[Object.keys(dayData)[0]][index]
                                          ?.is_slot_available
                                        ? styles.green
                                        : styles.red
                                      : ""
                                  }`}
                                  onDragOver={(e) => handleDragOver(e)}
                                  onDrop={(e) => handleDrop(e, dayData, index)}
                                  draggable
                                  onDragStart={(e) =>
                                    handleDragStart(e, dayData, index)
                                  }
                                  //   style={{
                                  //     backgroundColor:
                                  //       dayData[Object.keys(dayData)[0]][index]
                                  //         ?.is_slot_available === false
                                  //         ? "red"
                                  //         : "green",
                                  //   }} // Set background color based on isSwappable value
                                  key={dayData}
                                >
                                  {dayData[Object.keys(dayData)[0]][index]
                                    ?.period_type == 1
                                    ? `${
                                        dayData[Object.keys(dayData)[0]][index]
                                          ?.subject?.title
                                      } (${
                                        value == 0
                                          ? dayData[Object.keys(dayData)[0]][
                                              index
                                            ]?.instructor?.user?.first_name
                                          : ""
                                      } ${
                                        value == 0
                                          ? dayData[Object.keys(dayData)[0]][
                                              index
                                            ]?.instructor?.user?.last_name
                                          : ""
                                      }) ${
                                        value == 1
                                          ? dayData[Object.keys(dayData)[0]][
                                              index
                                            ]?.assigned_class?.title
                                          : ""
                                      } `
                                    : dayData[Object.keys(dayData)[0]][index]
                                        ?.period_type == 2
                                    ? "Break"
                                    : "Free"}
                                </div>
                              </td>
                            ))}
                          </tr>
                        )
                      )}
                  </tbody>
                </table>

                // <table>
                //     <thead>
                //         <tr>
                //             <th>Time</th>
                //             {Object.keys(timetableData[0]).map(day => (
                //                 <th key={day}>{day}</th>
                //             ))}
                //         </tr>
                //     </thead>
                //     <tbody>
                //         {timetableData[0] &&
                //             timetableData[0][Object.keys(timetableData[0])[0]].map((timeSlot, index) => (
                //                 <tr key={index}>
                //                     <td>{timeSlot.time}</td>
                //                     {Object.entries(timetableData[0]).map(([day, items]) => (
                //                         <td
                //                             key={day}
                //                             onDragOver={(e) => handleDragOver(e)}
                //                             onDrop={(e) => handleDrop(e, day, index)}
                //                             draggable
                //                             onDragStart={(e) => handleDragStart(e, day, index)}
                //                         >
                //                             {items[index].class}
                //                         </td>
                //                     ))}
                //                 </tr>
                //             ))}
                //     </tbody>
                // </table>
              )}
            </div>
          </div>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{`Curriculum ${generation} Selected`}</DialogTitle>
        <DialogContent>
          {isLoad ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
              <p>Please Wait</p>
            </div>
          ) : (
            <p>Are you sure you want to proceed?</p>
          )}
        </DialogContent>
        <DialogActions>
          {handleButton && (
            <>
              {" "}
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button onClick={dataSend}>Ok</Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* <DialogBox
        open={open}
        title={`Curriculum ${generation} Selected`}
        dataSend={dataSend}
        onClose={() => {
          setOpen(false);
        }}
      >
        {isLoad ? (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>Please Wait</p>
          </div>
        ) : (
          <p>Are you sure you want to proceed?</p>
        )}
      </DialogBox> */}
    </div>
  );
}

export default TimeTable;

const typeData = [
  {
    id: 1,
    title: "Class",
  },
  {
    id: 2,
    title: "Teacher",
  },
];

const board = [
  {
    id: 1,
    title: "ICSE",
  },
  {
    id: 2,
    title: "CBSE",
  },
  {
    id: 3,
    title: "International",
  },
];

const timetableData = {
  Monday: [
    { time: "9:00 AM", class: "Maths" },
    { time: "11:00 AM", class: "Science" },
    { time: "1:00 PM", class: "English" },
  ],
  Tuesday: [
    { time: "10:00 AM", class: "History" },
    { time: "12:00 PM", class: "Geography" },
    { time: "2:00 PM", class: "Art" },
  ],
  Wednesday: [
    { time: "9:00 AM", class: "Maths" },
    { time: "11:00 AM", class: "Science" },
    { time: "1:00 PM", class: "English" },
  ],
  Thursday: [
    { time: "10:00 AM", class: "History" },
    { time: "12:00 PM", class: "Geography" },
    { time: "2:00 PM", class: "Art" },
  ],
  Friday: [
    { time: "9:00 AM", class: "Maths" },
    { time: "11:00 AM", class: "Science" },
    { time: "1:00 PM", class: "English" },
  ],
  Saturday: [
    { time: "10:00 AM", class: "History" },
    { time: "12:00 PM", class: "Geography" },
    { time: "2:00 PM", class: "Art" },
  ],
  Sunday: [],
};

const data = [
  {
    Monday: [
      { time: "9:00 AM", class: "Maths" },
      { time: "11:00 AM", class: "Science" },
      { time: "1:00 PM", class: "English" },
    ],
  },
  {
    Tuesday: [
      { time: "10:00 AM", class: "History" },
      { time: "12:00 PM", class: "Geography" },
      { time: "2:00 PM", class: "Art" },
    ],
  },
  {
    Wednesday: [
      { time: "9:00 AM", class: "Maths" },
      { time: "11:00 AM", class: "Science" },
      { time: "1:00 PM", class: "English" },
    ],
  },
  {
    Thursday: [
      { time: "10:00 AM", class: "History" },
      { time: "12:00 PM", class: "Geography" },
      { time: "2:00 PM", class: "Art" },
    ],
  },
];
