import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import { CloseOutlined } from "@mui/icons-material";
import DialogBox from "../../Components/DialogBox/DialogBox";

function AddTeachers() {
  const navigate = useNavigate();
  // const { subjectList, classList } = useContext(UserCredsContext)
  const [teacherSelection, setTeacherSelection] = useState(null);
  const [grouping, selectionGrouping] = useState([]);
  const [teachers, setTeacher] = useState([]);
  const [singleteacher, setSinglTeacher] = useState(0);
  const [totalCount, setTotalTeacherCount] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [subClass, setSubClass] = useState({ teacher: null, group: null });

  const [subjectList, setSubjectList] = useState([]);
  const [classList, setClassesList] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [opens, setOpens] = useState(false);

  useEffect(() => {
    getTeachers();
    getSubjects();
    getClasses();
  }, []);

  const getSubjects = () => {
    api_token.get(`/base/v1/subject`)
    api_token.get(`base/v1/subject/all_subjects`)
    .then((res) => {
      setSubjectList(res.data.data);
    });
  };

  const getClasses = () => {
    api_token.get(`/base/v1/class/`).then((res) => {
      setClassesList(res.data.data);
    });
  };

  const getTeachers = (
    paramObj = {
      ...filterObj,
      page_size: 100,
    }
  ) => {
    api_token
      .get(`profile/v1/teachers/all_teacher_allocated_subject_classes`)
      .then((res) => {
        setTeacher(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const selectTeacher = (v) => {
    setTeacherSelection(v);
    setSubClass({ teacher: null, group: null });
  };

  const addSubClasses = (teacherId, groupId) => {
    // debugger;
    console.log(groupId, "GROUPID");
    subClass.teacher = teacherId?.teacher?.id;
    subClass.group = groupId?.subject?.id;
    setSubClass({ ...subClass });
    setTeacherSelection(teacherId?.teacher?.id);
  };

  // Add Subject to the teacher

  const handleAddSubject = (obj) => {
    let data = {
      tid: teacherSelection,
    };

    console.log(data, obj, "OBJ");

    if (teacherSelection) {
      // Find the teacher in the teachers array based on the teacherSelection.
      const teacher = teachers.find((t) => t.teacher.id === teacherSelection);

      if (teacher) {
        // Check if the subject is not already added to the teacher's allocated_subject_classes.
        const subjectExists = teacher.allocated_subject_classes.some(
          (group) => group.subject.id === obj.id
        );

        if (!subjectExists) {
          // Add the subject to the teacher's allocated_subject_classes.
          teacher.allocated_subject_classes.push({
            subject: obj,
            classes: [], // Initialize an empty classes array for the subject.
          });

          // Update the state to reflect the changes.
          setTeacher([...teachers]);
        }
      }
    }
  };

  const removeSubject = (tid, vals) => {
    console.log(tid, vals, teachers, "teach");

    let findTeacherObj = teachers.findIndex(
      (items) => items.teacher.id === tid
    );
    const indexToRemove = teachers[
      findTeacherObj
    ]?.allocated_subject_classes.findIndex(
      (item) => item?.subject?.id === vals?.subject?.id
    );
    if (indexToRemove !== -1) {
      teachers[findTeacherObj]?.allocated_subject_classes?.splice(
        indexToRemove,
        1
      );
    }
    setTeacher([...teachers]);
  };

  // Add Classes to particular subject

  const handleAddClasses = (obj) => {
    console.log(obj, "OBJ");

    if (teacherSelection && subClass.teacher && subClass.group) {
      // Find the teacher in the teachers array based on teacherSelection.
      const teacher = teachers.find((t) => t.teacher.id === teacherSelection);

      if (teacher) {
        // Find the subject in the teacher's allocated_subject_classes array.
        const subjectGroup = teacher.allocated_subject_classes.find(
          (group) => group.subject.id === subClass.group
        );

        if (subjectGroup) {
          // Check if the class is not already added to the selected subject.
          const classExists = subjectGroup.classes.some(
            (cls) => cls.id === obj.id
          );

          if (!classExists) {
            // Add the class to the selected subject.
            subjectGroup.classes.push(obj);

            console.log(subjectGroup, "SubjectGroupssss")

            // Update the state to reflect the changes.
            setTeacher([...teachers]);
          }
        }
      }
    }
  };

  const removeClass = (tid, main, v) => {
    console.log(tid, main, v, teachers, "TEACHERTEACHERTEACHER");

    let findTeacherObj = teachers.findIndex(
      (items) => items.teacher.id === tid
    );
    console.log(
      findTeacherObj,
      teachers[findTeacherObj],
      "TEACHERTEACHERTEACHER"
    );

    const indexToRemove = teachers[
      findTeacherObj
    ]?.allocated_subject_classes.findIndex(
      (item) => item?.subject?.id === main?.subject?.id
    );
    const ind = teachers[findTeacherObj]?.allocated_subject_classes[
      indexToRemove
    ]?.classes?.findIndex((datas) => datas?.id === v);

    if (ind !== -1) {
      teachers[findTeacherObj]?.allocated_subject_classes[
        indexToRemove
      ]?.classes.splice(ind, 1);
    }
    setTeacher([...teachers]);
  };

  const SubmitFile = () => {navigate(`/onboard/addChapters`);};

  const GotoNext = () => {
    const transformedData = {};

    teachers.forEach((item) => {
      const teacherId = item.teacher.id;
      if (!transformedData[teacherId]) {
        transformedData[teacherId] = {
          teacher_id: teacherId,
          allocated_subject_class: [],
        };
      }

      item.allocated_subject_classes.forEach((subjectClass) => {
        transformedData[teacherId].allocated_subject_class.push({
          subject: subjectClass.subject.id,
          classes_id: subjectClass.classes.map((cls) => cls.id),
        });
      });
    });

    // Convert the mapping to an array
    const result = Object.values(transformedData);

    console.log(result, "TEACHERSsssssssssss");

    // api_token
    //   .patch(`/profile/v1/teachers/update_teacher_subject_class/`, {
    //     data: result,
    //   })
    //   .then((res) => {
    //     if (res) {
    //       //   navigate(`/onboard/addChapters`);
    //       api_token
    //         .get(`external/v1/health_check/`)
    //         .then((res) => {
    //           if (res.status == 200 || res.status == 201) {
    //             setShiftData(res.data.data);
    //             setOpens(true);
    //           }
    //         })
    //         .catch((err) => console.log(err));
    //     }
    //   })
    //   .catch((err) => console.log(err));
    // navigate(`/onboard/addChapters`)
  };

  console.log(teachers, "values");

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.teacherContainer}>
          <div className={styles.teacherBox}>
            <div className={styles.fontData}>Teacher</div>
            <div>
              {teachers &&
                teachers.map((v, i) => (
                  <div>
                    {
                      <div
                        className={
                          v?.teacher?.id == teacherSelection
                            ? styles.selectedTeacher
                            : ""
                        }
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          onClick={() => selectTeacher(v?.teacher?.id)}
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            marginLeft: "10px",
                          }}
                        >
                          {v?.teacher?.user?.first_name}
                        </div>
                        <div className={styles.groupingBox}>
                          {v?.allocated_subject_classes.length > 0 &&
                            v?.allocated_subject_classes.map((values, idx) => (
                              <div
                                onClick={() => addSubClasses(v, values)}
                                className={
                                  subClass?.teacher == v?.teacher?.id &&
                                  subClass?.group == values?.subject?.id
                                    ? styles.selectSubjectContainer
                                    : styles.subjectContainer
                                }
                              >
                                <CloseOutlined
                                  style={{
                                    position: "absolute",
                                    right: "0",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    removeSubject(v?.teacher?.id, values)
                                  }
                                />
                                <div
                                  className={
                                    subClass?.teacher == v?.teacher?.id &&
                                    subClass?.group == values?.subject?.id
                                      ? styles.selectedgroupTitle
                                      : styles.groupTitle
                                  }
                                >
                                  {values?.subject?.title}
                                </div>
                                {values?.classes?.map((vals, i) => (
                                  <div
                                    className={styles.subGrouping}
                                    key={vals.id}
                                  >
                                    {vals.title}{" "}
                                    <CloseOutlined
                                      style={{
                                        fontSize: "14px",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        removeClass(
                                          v?.teacher?.id,
                                          values,
                                          vals.id
                                        )
                                      }
                                    />{" "}
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    }
                    {/* {(singleteacher == i) && <div style={{ textAlign: 'right', marginTop: '20px' }}><buttom onClick={() => handleClick(i + 1)} className={styles.nextButton}>Submit</buttom></div>} */}
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.teacherBox}>
            <div>
              <div className={styles.fontData}>Subjects</div>
              <div className={styles.subjectMain}>
                {subjectList &&
                  subjectList.map((v, i) => (
                    <div
                      className={styles.subjectBox}
                      onClick={() => handleAddSubject(v)}
                      // onClick={() => handleAddSubject(v)}
                    >
                      {v.title}
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className={styles.fontData}>Classes</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {classList &&
                  classList.map((v, i) => (
                    <div
                      className={styles.subjectBox}
                      onClick={() => handleAddClasses(v)}
                    >
                      {v.title}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoNext()}>
            Next
          </button>
        </div>
      </div>

      <DialogBox
        open={opens}
        onClose={() => setOpens(false)}
        dataSend={() => SubmitFile()}
        title="Calendar Fitness"
      >
        {shiftData &&
          shiftData.length > 0 &&
          shiftData.map((v, i) => (
            <div>
              <h3>{v?.title}</h3>
              <p className={styles.overview}>{v?.overview}</p>
              <div className={styles.contentView}>
                {v?.analysis?.teachers.length > 0 &&
                  v?.analysis?.teachers?.map((vals, idx) => (
                    <div>
                      <p className={styles.messages}>{vals?.message}</p>
                      <p className={styles.messages}>{vals?.count}</p>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </DialogBox>
    </div>
  );
}

export default AddTeachers;

const teacher = [
  {
    id: 1,
    title: "Sharon",
  },
  {
    id: 2,
    title: "Christine",
  },
  {
    id: 3,
    title: "Rahul",
  },
  {
    id: 4,
    title: "Sameer",
  },
  {
    id: 5,
    title: "Vaibhav",
  },
];

const subject = [
  {
    id: 1,
    title: "Maths",
  },
  {
    id: 2,
    title: "Biology",
  },
  {
    id: 3,
    title: "Chemistry",
  },
  {
    id: 4,
    title: "Physics",
  },
  {
    id: 5,
    title: "History",
  },
  {
    id: 6,
    title: "Geography",
  },
  {
    id: 7,
    title: "English",
  },
];

const classes = [
  {
    id: 1,
    data: [
      {
        id: 1,
        title: "Class 1A",
      },
      {
        id: 2,
        title: "Class 1B",
      },
      {
        id: 3,
        title: "Class 1C",
      },
      {
        id: 4,
        title: "Class 1D",
      },
    ],
  },
  {
    id: 2,
    data: [
      {
        id: 21,
        title: "Class 2A",
      },
      {
        id: 22,
        title: "Class 2B",
      },
      {
        id: 23,
        title: "Class 2C",
      },
      {
        id: 24,
        title: "Class 2D",
      },
    ],
  },
  {
    id: 3,
    data: [
      {
        id: 31,
        title: "Class 3A",
      },
      {
        id: 32,
        title: "Class 3B",
      },
      {
        id: 33,
        title: "Class 3C",
      },
      {
        id: 34,
        title: "Class 3D",
      },
    ],
  },
];

/// To Hit API If it's set to single add

/**
 *     const handleClick = (v) => {

        console.log(v, "dd");
        let questSize = teachers.length;
        let currentIndex = v;
        let c = (currentIndex / questSize) * 100;
        console.log(c, teachers, "dd");
        // setNumber(x + 1);
        if (c > 75) {
            if (totalCount !== teachers.length) {

                getTeachers(filterObj);
            }
        }

        let data = v - 1;
        console.log(grouping[data], "VALSSSSSSSSSs")
        let class_id = [];
        let sub_id = [];

        grouping[data]?.sub?.map((v, i) => {
            sub_id.push(v.id);
            v?.data.map((da, i) => {
                class_id.push(da.id)
            })
        })

        console.log(class_id, sub_id, "SSSSSSSSSSSSSSSSSSs")
        let content = {
            classes_id: class_id,
            subject_id: sub_id,
        }
        api_token
        .patch(`profile/v1/teachers/${grouping[data].tid}/`, content )
        .then((res) => {
            console.log(res.data.data)
        })
        .catch(err => console.log(err));
        setSinglTeacher(v);
        setTeacherSelection(teachers[v].id)
    }

 */

/**
 * 
 * 
 * const getTeachersData = (v) => {
    api_token
    .get(`/profile/v1/teachers/${v}/get_teacher_allocated_subject_classes`)
    .then((res) => {
        console.log(res.data.data)
    })
    .catch(err => console.log(err))
}


const handleAddSubject = (v) => {

    let data = {
        tid: teacherSelection,
    }
    let ind = grouping.findIndex((obj) => obj.tid === data.tid);
    if (ind === -1) {
        data.sub = [{ id: v.id, title: v.title }];
        grouping.push(data);
        selectionGrouping([...grouping])
    } else {
        let newSub = { id: v.id, title: v.title };
        let indofSubject = grouping[ind].sub.findIndex((obj) => obj.id === newSub.id);

        if (indofSubject === -1) {
            grouping[ind].sub.push(newSub);
            selectionGrouping([...grouping])
        } else {
            grouping[ind].sub[indofSubject] = newSub;
            selectionGrouping([...grouping])
        }
    }

}



const handleAddClasses = (v) => {
    let teacherIndex = grouping.findIndex((obj) => obj.tid === subClass.teacher);
    let groupIndex = grouping[teacherIndex].sub.findIndex((obj) => obj.id === subClass.group);

    // let indofSubject = grouping[teacherIndex].sub[groupIndex].findIndex((obj) => obj.id === newSub.id);

    if ('data' in grouping[teacherIndex].sub[groupIndex]) {
        let dataId = grouping[teacherIndex].sub[groupIndex].data.findIndex((obj) => obj.id === v.id);
        if (dataId == -1) {
            grouping[teacherIndex].sub[groupIndex].data.push(v);
            selectionGrouping([...grouping])
        } else {
            grouping[teacherIndex].sub[groupIndex].data[dataId] = v;
            selectionGrouping([...grouping])
        }

    } else {
        grouping[teacherIndex].sub[groupIndex].data = [];
        grouping[teacherIndex].sub[groupIndex].data.push(v);
        selectionGrouping([...grouping])
    }

}

 */

/**
 * 
 
    {[
  {
    allocated_subject_classes:[
       
{subject: {id: 2, title: 'Biology', status: true, icon: 'http://dev-api.eduaratech.com/files/icon_media/biology.png'}, classes: [
{id: 47, title: 'Class 3A', colour: null, grade: 6, subjects: Array(4)}
{id: 50, title: 'Class 4B', colour: null, grade: 7, ]},

{subject: {…}, classes: []},

{subject: {…}, classes: []},
      ],
     
    teacher:{id: 16, user: {…}, title: 'Prof'}
  },
  {
    allocated_subject_classes:[
       
{subject: {…}, classes: [
{id: 47, title: 'Class 3A', colour: null, grade: 6, subjects: Array(4)}
{id: 50, title: 'Class 4B', colour: null, grade: 7, ]},

{subject: {…}, classes: []},

{subject: {…}, classes: []},
      ],
     
    teacher:{id: 16, user: {…}, title: 'Prof'}
  }
  ]}
 */
