import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
// import { makeStyles } from '@mui/styles';
// import Slider from '@mui/material/Slider';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function valuetext(value) {
  return `${value}°C`;
}

const PrettoSlider = withStyles({
  root: { color: "#e3e2de", height: 8 },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -5,
    marginLeft: -12,
    "&:focus, &:hover, &$active": { boxShadow: "inherit" },
  },
  active: {},
  valueLabel: { left: "calc(-50% + 4px)", bottom: "8000px" },
  track: {
    height: 14,
    borderRadius: 0,
    "&:nth-of-type(even)": { marginLeft: "2px" },
  },
  rail: { height: 14, borderRadius: 0 },
  mark: { backgroundColor: "#d", height: 14, width: 1, marginTop: 0 },
})(Slider);

function GradeSelection({ setBoardGrade = () => {}, boardGrade }) {
  // const classes = useStyles();
  const { userState } = useContext(UserCredsContext);
  const navigate = useNavigate();
  const [gradeList, setGradeList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;

  // useEffect(() => {
  //   if (userState?.school_profile?.is_onboarded) {
  //     // setBoardType(boardList[0]?.id)
  //   }
  // }, []);

  useEffect(() => {
    getGradeList();
  }, []);

  const getGradeList = () => {
    api_token
      .get(`/base/v1/assigned_grade`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setGradeList(res.data.data);
          if (userState?.school_profile?.is_onboarded) {
            let arr = [];
            let resData = res.data.data;
            resData.map((v, i) => {
              arr.push(v?.id);
            });
            setSelectedItems(arr);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const GotoGrade = () => {
    if (selectedItems.length > 0) {
      setBoardGrade({ ...boardGrade, grade: [...selectedItems] });
      navigate(`/onboard/addsubject`);
    } else {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please Select Grade",
        errorType: "error",
      });
    }
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const handleSelectGrade = (index) => {
    console.log(index, "indexxxx");
    const updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(index)) {
      //If grade is present in the array, then removing using splice method.
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(index), 1);
    } else {
      updatedSelectedItems.push(index); //If grade is not present, then adding using push method.
    }

    setSelectedItems(updatedSelectedItems);
  };

  console.log(selectedItems, "selectedItemssss");
  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p className={styles.heads}>Powered by Ed5</p>
        </div>

        <div className={styles.centerContainer}>
          <p className={styles.selectionHeading}>Select Grades</p>

          <div style={{ display: "flex" }}>
            {gradeList &&
              gradeList.map((v, i) => (
                <div className={styles.boxData}>
                  <div className={styles.titles}>{v?.title}</div>
                  <div
                    style={{
                      border: "1px solid #fbd7e9",
                      marginTop: "20px",
                      height: "40px",
                      borderRadius: "8px",
                      backgroundColor: selectedItems.includes(v.id)
                        ? "white"
                        : "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleSelectGrade(v?.id);
                    }}
                  ></div>
                </div>
              ))}
          </div>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GradeSelection;

const tabSwitch = [
  {
    title: "Product",

    id: 1,
  },

  {
    title: "WishList",

    id: 2,
  },
];

const board = [
  {
    id: 1,
    title: "ICSE",
  },
  {
    id: 2,
    title: "CBSE",
  },
  {
    id: 3,
    title: "International",
  },
];
