import React, { useState, useEffect } from "react";
import CustomCalendar from "../../../Components/CustomCalendar/CustomCalendar";
import { convertCalendarDate, getCurrentDateTime } from "../../../Utils/Utils";
import { Snackbar } from "@material-ui/core";
import { api_token, base_url, _access_token } from "../../../Utils/Network";
import moment from "moment";
import {
  Alert,
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Box,
  Tabs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DrawerComp from "../../../Components/DrawerComp/DrawerComp";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import DialogBox from "../../../Components/DialogBox/DialogBox";
import Files from "../../../File/events.xlsx";
import axios from "axios";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function DailyClasses() {
  const [value, setValue] = React.useState(0);
  const [calendarData, setCalendarData] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  const [open, setOpen] = useState(false);
  const [typeShift, setTypeShift] = useState([]);
  const [classDetails, setClassDetail] = useState({
    id: null,
    event_type_id: "",
    title: "",
    description: "",
    start: "",
    end: "",
    commence: "",
    conclude: "",
    event_for: null,
    chapter_id: null,
    classes: null,
    subject_id: null,
    event_category_id: null,
    teacher: null,
  });
  const [classes, setClasses] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [selectSub, setSelectSub] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [selectGrade, setSelectGrade] = useState(null);
  const [eventCategory, setEventCategory] = useState([]);
  const [dynamicList, setDynamicList] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [teacherList, setTeacher] = useState([]);
  const [shifts, selectShift] = useState("");
  const [dynamicColor, setDynamicColor] = useState(null);
  const [isLoadDynamic, setIsLoadDynamic] = useState(false);
  const [classId, setClassId] = useState(null);
  const [editData, setEditData] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [eventOpDialog, setEventOpDialog] = useState(false);
  const [message, setMessage] = useState({});
  const [file, setFile] = useState(null);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;


  console.log(dateTime, "date");
  useEffect(() => {
    getClass();
    getEventCategory();
    getEventType();
    getTeachers();
    getShifts();
  }, []);

  useEffect(() => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    value ?
      getListing(mnth, yrs, "teacher", classId) :
      getListing(mnth, yrs, "classes", classId)
  }, [dateTime]);

  const getShifts = () => {
    api_token
      .get(`/calendar/v1/shifts/`)
      .then((res) => {
        const resultData = res.data.data;
        if (res.data.data) {
          setTypeShift(resultData);
          getClasses(resultData); // Function to get classes and we have passed shift array inside it
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const selectionClassStudent = (v) => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    {
      value ?
        setClassId(v?.user?.id) :
        setClassId(v.id);

    }
    {
      value ?
        getListing(mnth, yrs, "teacher", v?.user?.id) :
        getListing(mnth, yrs, "classes", v.id)
    }
  };

  const getClasses = (v) => {
    // setIsLoadDynamic(true);
    const filteredData = v && v.filter((obj) => obj.title === `Shift 1`);
    if (filteredData && filteredData.length > 0) {
      selectShift(filteredData[0]?.title);
      setDynamicColor(filteredData[0]?.id);
      api_token
        .get(
          `/calendar/v1/shifts/${filteredData[0]?.id}/shifts_listings_by_type/?type=1`
        )
        .then((res) => {
          // setIsLoadDynamic(false);
          setDynamicList(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleShift = (v) => {
    console.log(v, value, "VID");
    setDynamicColor(v.id);
    selectShift(v.title);
    api_token
      .get(
        `/calendar/v1/shifts/${v?.id}/shifts_listings_by_type?type=${value + 1}`
      )
      .then((res) => {
        setIsLoadDynamic(false);
        setDynamicList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getClass = () => {
    api_token
      .get(`base/v1/class`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEventCategory = () => {
    api_token
      .get(`/base/v1/event_category`)
      .then((res) => {
        setEventCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTeachers = (id, classes) => {

    if (id || classes) {
      api_token
        .get(`/profile/v1/teachers/?page_size=100&subject=${id}&classes=${classes}`)
        .then((res) => {
          setTeacher(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      api_token
        .get(`/profile/v1/teachers/?page_size=100`)
        .then((res) => {
          setTeacher(res.data.data);
        })
        .catch((err) => console.log(err));
    }

  };

  useEffect(() => {

    if (selectGrade == null || selectSub == null) {
      getTeachers()
    } else {
      getTeachers(selectSub, selectGrade)
    }

  }, [selectSub, selectGrade])

  const getEventType = () => {
    api_token
      .get(`/base/v1/event_type`)
      .then((res) => {
        setEventType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getListing = (month, year, str, ids) => {
    // const { month, year } = dateTime;
    // &month=${month}&year=${year}
    let url;
    if (str && ids) {
      url = `calendar/v1/events?event_type=2&month=${month}&year=${year}&${str}=${ids}&page_size=300`
    } else {
      url = `calendar/v1/events?event_type=2&month=${month}&year=${year}&page_size=300`
    }
    api_token
      .get(url)
      .then((response) => {
        console.log(response.data.data, "response");
        const new_data = response?.data?.data.map((obj) => {
          var s_time = moment(obj?.commence, 'HH:mm:ss').format('h:mm A')
          var e_time = moment(obj?.conclude, 'HH:mm:ss').format('h:mm A')
          return {
            ...obj,
            start_time: s_time,
            end_time: e_time,
          }
        }
        )
        // const data = convertCalendarDate(response.data.data);
        const data = convertCalendarDate(new_data);
        console.log(data, "datatatataa");
        setCalendarData(data);
      })
      .catch((error) => { });
  };

  const handleClicked = (id) => {
    setOpen(!open);
    setSelectGrade();
    setSelectSub();

    if (id) {
      setClassDetail({})
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChange = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChanges = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleSubject = (e) => {
    setClassDetail({ ...classDetails, subject_id: e.target.value });
    console.log(e, selectGrade, "E");
    setSelectSub(e?.target.value)
    api_token
      .get(`cms/v1/chapter/?subject=${e.target.value}&grade=${selectGrade}&page_size=20`)
      .then((res) => {
        setChapters(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const addSubjects = (e) => {
    // debugger;
    console.log(e, "ererere");
    let subject = e?.subjects_detail || [];
    setSubject(subject);
    let grade = e?.grade;
    setSelectGrade(grade);
  };



  const handleChangeText = (event, newValue) => {
    setIsLoadDynamic(true);
    setValue(newValue);
    const filteredData =
      typeShift && typeShift.filter((obj) => obj.title === `Shift 1`);
    setDynamicColor(filteredData[0]?.id);
    api_token
      .get(
        `/calendar/v1/shifts/${filteredData[0]?.id
        }/shifts_listings_by_type/?type=${newValue + 1}`
      )
      .then((res) => {
        setIsLoadDynamic(false);
        setDynamicList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // edit function 

  const handleCalendarDataUpdate = (data) => {
    // Handle the received data here in the parent component
    console.log('Received data in parent component:', data);


    // Example: Update state with received data
    // setCalendarData(data);
    // setEditData(data)
    handleClicked(data?.id)
    setClassDetail({
      event_type_id: data?.event_type?.id,
      title: data?.title?.props?.children[0]?.props?.children[1],
      description: data?.description,
      start: data?.date,
      end: data?.date,
      commence: data?.commence,
      conclude: data?.conclude,
      event_for: data?.event_for,
      chapter_id: data?.chapter?.id,
      classes: data?.classes[0],
      subject_id: data?.subject?.id,
      event_category_id: data?.event_category?.id,
      teacher: data?.teacher[0],
      id: data?.id,
    })

    setSelectGrade(data?.classes[0])
    setSelectSub(data?.subject?.id)
  }

  const openBulk = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    getClass()
    if (classDetails?.classes) {
      console.log(classes, classDetails?.classes, "useEffectclassDetails");
      const newObj = classes.find(item => item?.id === classDetails?.classes)
      console.log(newObj, "newObj");
      let subject2 = newObj?.subjects_detail || []
      setSubject(subject2);
      let subject = classDetails?.subject_id

      let grade = newObj?.grade
      // setSelectGrade(grade);
      forEditchapterApi(subject, grade)
    }
  }, [classDetails?.id])

  const forEditchapterApi = (subject, grade) => {
    console.log(subject, grade, "forEditchapterApi");
    api_token
      .get(`cms/v1/chapter/?subject=${subject}&grade=${grade}&page_size=20`)
      .then((res) => {
        setChapters(res.data.data);
      })
      .catch((err) => console.log(err));
  }


  const OpenMessage = () => {
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }

    console.log(obj, "let obj");
    const { classes, teacher, ...rest } = classDetails;

    const data = {
      classes: [classes],
      teacher: [teacher],
      ...rest,
    };

    delete data?.id;

    let checkData = {
      date: data.start,
      commence: data.commence,
      conclude: data.conclude,
      class_id: data.classes[0],
      teacher_id: data.teacher[0],
    };
    api_token
      .post(`calendar/v1/events/validate_event/`, checkData)
      .then((res) => {
        const result = res.data.data[0];
        setOpen(false);
        setMessage(result);
        setEventOpDialog(true);
      })
      .catch((err) => { });
  };
  const SubmitData = () => {
    // console.log(classDetails, "classDetails")
    const { classes, teacher, ...rest } = classDetails;

    const data = {
      classes: [classes],
      teacher: [teacher],
      ...rest,
    };
    delete data?.id
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }
    let holidays = eventType && eventType.length > 0 && eventType?.filter((data) => data?.title == "Daily Classes")
    data.event_type_id = holidays[0]?.id;

    if (classDetails?.id) {

      console.log(classDetails?.id, "hitpatch ");
        api_token
      .patch(`calendar/v1/events/${classDetails?.id}/`, data)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          let mnth;
          let yrs;
          const { month, year } = currenctDate;
          mnth = month + 1;
          yrs = year;
          getListing(mnth, yrs);
          setOpen(false);
          setEventOpDialog(false)
          setClassDetail({})
        }
      })
      .catch((err) => console.log(err));

    } else {

      console.log(data, "hitpatch ");
      api_token
      .post(`calendar/v1/events/create_events/`, data)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          let mnth;
          let yrs;
          const { month, year } = currenctDate;
          mnth = month + 1;
          yrs = year;
          getListing(mnth, yrs);
          setOpen(false);
          setEventOpDialog(false)
          setClassDetail({})
        }
      })
      .catch((err) => console.log(err));
    }

    console.log(data, "classDetails");
    // api_token
    //   .post(`calendar/v1/events/create_events/`, data)
    //   .then((res) => {
    //     if (res.status == 200 || res.status == 201) {
    //       let mnth;
    //       let yrs;
    //       const { month, year } = currenctDate;
    //       mnth = month + 1;
    //       yrs = year;
    //       getListing(mnth, yrs);
    //       setOpen(!open);
    //     }
    //   })
    //   .catch((err) => console.log(err));

    // if()

  };
  let holidays = eventType && eventType.length > 0 && eventType?.filter((data) => data?.title == "Daily Classes")


  const SubmitFile = async () => {
    let data = {
      file: file,
    };
    const formData = new FormData();
    if (data.file) formData.append("file", data.file);
    try {
      await axios({
        method: "Post",
        url: `${base_url}external/v1/sync_events`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        headers: { Authorization: `Bearer ${_access_token}` },
      })
        .then((response) => {
          // setLoading(false)
          console.log(response, "rRRRRRRR");
          if (response.status === 200 || response.status === 201) {

            // alert("PPT Bulk Data Uploaded Successfully")
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "PPT Bulk Data Uploaded Successfully",
              errorType: "success",
            });
            setFile(null);
            setOpenDialog(false);
            // getEbookData();

          } else {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Error Filling Data",
              errorType: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Error Filling Data",
            errorType: "error",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangefile = (e) => {
    setFile(e.target.files[0]);
    // setFile(e);
  };


  console.log(calendarData, "calendarData");
  return (
    <div>
      <div>
        <Button
          variant="contained"
          onClick={handleClicked}
          style={{ marginBottom: "10px", marginRight: '10px' }}
        >
          Add Class
        </Button>

        <Button variant="contained"
          style={{ marginBottom: "10px" }} onClick={() => openBulk()}>Bulk Upload</Button>

      </div>
      <div style={{ display: "flex" }}>
        <div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChangeText}
                aria-label="basic tabs example"
              >
                {typeData.map((v, i) => (
                  <Tab
                    label={v.title}
                    {...a11yProps(0)}
                    sx={{
                      color: "#ED388F !important",
                      fontSize: "20px",
                      fontFamily: "Barlow",
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            {typeData.map((v, i) => (
              <TabPanel value={value} index={i}>
                <div className={styles.shiftsTypes}>
                  {typeShift &&
                    typeShift.map((v, i) => (
                      <div
                        //   className={styles.singleShift}
                        className={`${styles.singleShift}
                            ${v.id == dynamicColor
                            ? styles.selectedSingleShift
                            : ""
                          }
                          `}
                        onClick={() => handleShift(v)}
                      >
                        {v.title}
                      </div>
                    ))}
                </div>
                <div className={styles.cls}>
                  {isLoadDynamic ? (
                    <div>Loading ...</div>
                  ) : (
                    <div>
                      {dynamicList.map((v, i) => (
                        <div
                          onClick={() => selectionClassStudent(v)}
                          className={(value) ?
                            v?.user?.id == classId
                              ? styles.selected
                              : styles.normalClass
                            :
                            v.id == classId
                              ? styles.selected
                              : styles.normalClass
                          }
                        >
                          {value
                            ? `${v?.user?.first_name} ${v?.user?.last_name}`
                            : v.title}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </TabPanel>
            ))}
          </Box>
        </div>
        <CustomCalendar calendarData={calendarData} setDateTime={setDateTime} onUpdateCalendarData={handleCalendarDataUpdate} />
        {/* <CustomCalendar calendarData={events} /> */}
      </div>
      <div>
        <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
          <div className={styles.drawerContain}>
            <h2>Add Class</h2>
            <div className={styles.textData}>
              <TextField
                name="title"
                label="Title"
                style={{ width: "100%" }}
                value={classDetails?.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                name="description"
                label="Description"
                style={{ width: "100%" }}
                value={classDetails?.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="start"
                label="start date"
                value={classDetails?.start}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="end"
                label="end date"
                value={classDetails?.end}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="commence"
                label="start time"
                value={classDetails?.commence}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="conclude"
                label="end time"
                value={classDetails?.conclude}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event for</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_for"
                  value={classDetails?.event_for}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Student</MenuItem>
                  <MenuItem value={3}>Teacher</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Class</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="classes"
                  value={classDetails?.classes}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {classes &&
                    classes.map((v, i) => (
                      <MenuItem value={v.id} onClick={() => addSubjects(v)}>
                        {v.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Subject</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  // value={selectedOption}
                  value={classDetails?.subject_id}
                  onChange={handleSubject}
                >
                  {subjects &&
                    subjects.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Chapter</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="chapter_id"
                  value={classDetails?.chapter_id}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {chapters &&
                    chapters.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Type</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_type_id"
                  // value={selectedOption}
                  value={holidays[0]?.id}
                  disabled
                  onChange={handleDropdownChange}
                >
                  {eventType &&
                    eventType.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Category</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_category_id"
                  value={classDetails?.event_category_id}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {eventCategory &&
                    eventCategory.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Teacher</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="teacher"
                  value={classDetails?.teacher}
                  // value={selectedOption}
                  onChange={handleDropdownChanges}
                >
                  {teacherList &&
                    teacherList.map((v, i) => (
                      <MenuItem
                        value={v?.user?.id}
                      >{`${v?.user?.first_name} ${v?.user?.last_name}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <Button onClick={() => OpenMessage()} variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </DrawerComp>


        <div>
          <Dialog open={eventOpDialog} onClose={() => setEventOpDialog(false)}>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>{message?.message}</DialogContent>
            <DialogActions>
              <Button onClick={() => setEventOpDialog(false)}>Cancel</Button>
              <Button onClick={SubmitData}>Override and schedule</Button>
            </DialogActions>
          </Dialog>


          <DialogBox
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            dataSend={() => SubmitFile()}
          >
            <div>
              <h3>Upload File</h3>
              <p>
                Get Sample File here
                <a href={Files} download>
                  {" "}
                  Click to download
                </a>
              </p>
              <input type="file" accept="file/*" onChange={handleChangefile} />
              {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} dropMessageStyle={{backgroundColor: 'orange'}}/> */}
            </div>
          </DialogBox>

        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        style={{ zIndex: 9999 }}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DailyClasses;

const typeData = [
  {
    id: 1,
    title: "Class",
  },
  {
    id: 2,
    title: "Teacher",
  },
];

const events = [
  {
    title: 'Event 1',
    start: new Date('2023-12-01T10:00:00'),
    end: new Date('2023-12-01T12:00:00'),
    backgroundColor: 'blue', // Background color
    textColor: 'white', // Text color
  },
  {
    title: 'Event 2',
    start: new Date('2023-12-02T14:00:00'),
    end: new Date('2023-12-02T16:00:00'),
    backgroundColor: 'green',
    textColor: 'black',
  },
  // Add more events as needed
];
