import React, { useState, useEffect } from "react";
import CustomCalendar from "../../../Components/CustomCalendar/CustomCalendar";
import {
  convertCalendarDate,
  getCurrentDateTime,
  getDate,
  getTime,
} from "../../../Utils/Utils";
import { api_token } from "../../../Utils/Network";
import moment from "moment";
import { Button } from "@mui/material";

function Birthday() {
  const [calendarData, setCalendarData] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  console.log(dateTime, "date");
  useEffect(() => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    getListing(mnth, yrs);
  }, [dateTime]);

  console.log(dateTime, "dateTimeee");

  const getListing = (month, year) => {
    // const { month, year } = dateTime;
    // &month=${month}&year=${year}
    api_token
      .get(`calendar/v1/birthdays`)
      .then((response) => {
        console.log(response, "hariResponseee");
        // const data = convertCalendarDate(response.data.data.data);
        setCalendarData(response.data.data);
      })
      .catch((error) => {});
  };

  const transformedData = calendarData.map((obj) => {
    const { year, month, date } = getDate(obj?.dob);

    const { hr: s_hr, min: s_min, sec: s_sec } = getTime("00:00:00");
    const { hr: e_hr, min: e_min, sec: e_sec } = getTime("23:59:59");

    let startTime = new Date(year, month - 1, date, s_hr, s_min, s_sec);
    let endTime = new Date(year, month - 1, date, e_hr, e_min, e_sec);

    return {
      title: obj.name, // Set the name as the title
      dob: obj.dob, //chekkk
      class: obj.class,
      avatar: obj.avatar,
      user_type: obj.user_type,
      color: "red",
      start: startTime,
      end: endTime,
    };
  });

  return (
    <div>
      <div>
        {/* <Button>Add Birthday</Button> */}
      </div>
      <div>
        <CustomCalendar
          calendarData={transformedData}
          setDateTime={setDateTime}
        />
      </div>
    </div>
  );
}

export default Birthday;
