import React, {useState} from 'react'
import { Route, Routes } from 'react-router-dom'
import AddChapters from '../AddChapters/AddChapters'
import AddSection from '../AddSection/AddSection'
import AddShift from '../AddShift/AddShift'
import AddSubjects from '../AddSubjects/AddSubjects'
import AddTeachers from '../AddTeachers/AddTeachers'
import BoardSelection from '../Board/BoardSelection'
import DivideShift from '../DivideShift/DivideShift'
import GradeSelection from '../GradeSelection/GradeSelection'
import TimeTable from '../TimeTable/TimeTable'
import TotalNumberofShift from '../TotalNumberofShift/TotalNumberofShift'
import UploadTeacher from '../UploadTeacher/UploadTeacher'
import DivideTeacher from '../DivideTeacher/DivideTeacher'
import AddSubject from '../AddSubjects/AddSubject'
import Academic from '../Academic/Academic'
import Holiday from '../Holiday/Holiday'
import AddTeacher from '../AddTeachers/AddTeacher'
import TimeTables from '../TimeTable/TimeTables'
import DownloadTimeTable from '../DownloadTimetable/DownloadTimeTable'
import AddNewTeacher from '../AddTeachers/AddNewTeacher'

function OnboardDash() {
  const [boardGrade, setBoardGrade] = useState({
    grade: [],
    board: null,
    subjects: [],
  })
  return (
    <div>
        <Routes>
            <Route path="board" element={<BoardSelection boardGrade={boardGrade} setBoardGrade={setBoardGrade} />}/>  {/*Done*/}
            <Route path="grade" element={<GradeSelection boardGrade={boardGrade} setBoardGrade={setBoardGrade} />}/>  {/*Done*/}
            {/* <Route path="addsubject" element={<AddSubjects />}/>  */}
             {/*Done*/}
            <Route path="addsubject" element={<AddSubject boardGrade={boardGrade} setBoardGrade={setBoardGrade}/>}/>  {/*Done*/}
            <Route path="addsection" element={<AddSection />}/>   {/*Done*/}
            <Route path="uploadteacher" element={<UploadTeacher />}/> {/* Pending the date format */}
            
            <Route path="academic" element={<Academic />}/>
            <Route path="holiday" element={<Holiday />}/>


            {/* Academic year and calendar issue*/}
            <Route path="selectShift" element={<TotalNumberofShift />}/>
            <Route path="addShift" element={<AddShift />} />
            <Route path="divideShift" element={<DivideShift />} />
            <Route path="divideTeacher" element={<DivideTeacher />} />
            <Route path="addTeachers" element={<AddNewTeacher />}/>
            {/* <Route path="addTeachers" element={<AddTeacher />}/> */}
            {/* <Route path="addTeachers" element={<AddTeachers />}/> */}
            <Route path="addChapters" element={<AddChapters />}/>
            {/* <Route path="timetable" element={<TimeTable />} /> */}
            <Route path="timetable" element={<TimeTables />} />
            <Route path="timetableDownload" element={<DownloadTimeTable />} />

            
        </Routes>
    </div>
  )
}

export default OnboardDash