import React, {useState} from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styles from "./PptFullScreenSlide.module.css";
import smallScreen from "../../Assets/ContentMangement/ppt/smallScreen.png";
import { api_token } from "../../Utils/Network";


const PptFullScreenSlide = (props) => {
    const {openMod, slide,closeFullScreen} = props;

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
      };
      const handleSmallSize = () => {
        closeFullScreen()
      }

    return (
        <div>
        {/* <Button variant="contained" color="primary" onClick={handleOpen}>
          Give Feedback
        </Button> */}
        <Modal
          open={openMod}
          onClose={handleClose}
          aria-labelledby="feedback-modal-title"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
  
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
            className={styles.popUpCon}
            style={{
            //   textAlign: "center",
            padding : "0px"
            }}
          >

            <div style = {{width : "100%", position : "relative", padding : "0px", borderRadius : "20px"}}>
                <img src = {slide?.thumbnail} style = {{width : "100%", borderRadius : "20px",  margin : "0px", objectFit: "cover", height : "100%", top : "0%", bottom : "0%"}}/>
                <img src = {smallScreen} style = {{width : "40px", height : "40px", position : "absolute", top : "90%", right : "3%", cursor : "pointer"}} onClick = {() => handleSmallSize()}/>

</div>

               
            
          </Box>
        </Modal>
      </div>
    )
}

export default PptFullScreenSlide;