import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import styles from "./PptSlideEdit.module.css";
import replaceSide from "../../Assets/ContentMangement/ppt/replaceSide.png";
import { api_token , base_url, _access_token} from "../../Utils/Network";

const PptSlideEdit = ({ slide, openMod, navCallBack, handleClikSave }) => {
  const [newSlide, setNewSlide] = useState(null);
  const [open, setOpen] = useState(false);

  console.log(slide, "slideee");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {

    const formData = new FormData();
    if (slide?.id) formData.append("slide_id", slide?.id);
    // if (slide?.is_file_str) formData.append("slide_id", slide?.is_file_str);
    if (newSlide) formData.append("images", newSlide);

    axios ({
      method: "POST",
        url: `${base_url}content/v1/ppt_slides/update_ppt_slides_v2/`,         //content/v1/ppt_slides/update_ppt_slides/
       data: formData,
               config: { headers: { 'Content-Type': 'multipart/form-data' } },
              headers: { 'Authorization': `Bearer ${_access_token}` },
    })
    .then((response) =>{

      if (response?.data?.data) {
        setNewSlide(null)
        navCallBack()
      }

    })
    .catch((error) => {
      console.log(error)
    })

  };

  const discardEdit = () => {
    setNewSlide(null);
    navCallBack();
  };

  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  const handleImageChange = (event) => {
    const files = event.target.files[0];

    console.log(files, "filessss")
    if (files) {
      setNewSlide(files)
    }
  }


  console.log(newSlide, "newSlideeee")

  return (
    <div>

      <Modal
        open={openMod}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          className={styles.popUpCon}
          style={{
            backgroundColor: "#ED388F",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <h2 className={styles.editHead}>Edit Slide PPT</h2>
            <p className={styles.editPara}>
              Drag and drop the desired slide to replace this slide
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5%",
              }}
              className={styles.imagesCon}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "47%",
                }}
              >
                <img
                  src={slide?.thumbnail}
                  style={{ width: "100%", borderRadius: "20px" }}
                />
                <p className={styles.slideType}>Original Slide</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "47%",
                }}
              >
                {newSlide ? (
                  <img
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    src={URL.createObjectURL(newSlide)}
              
                    alt={`Selected ${newSlide.name}`}
                    onClick={() => handleImageClick()}
                  />
                ) : (
                  <img
                    src={replaceSide}
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImageClick()}
                  />
                )}

                {/* {Object.keys(newSlide).length === 0 ? (
                  <img
                    src={replaceSide}
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleChangeSlide()}
                  />
                ) : (
                  <img
                    style={{
                      width: "100%",
                      borderRadius: "20px",
                      cursor: "pointer",
                    }}
                    src={URL.createObjectURL(newSlide)}
                    alt={`Selected ${newSlide.name}`}
                    onClick={() => handleChangeSlide()}
                  />
                )} */}

                {/* <img
                  src={replaceSide}
                  style={{
                    width: "100%",
                    borderRadius: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleChangeSlide()}
                /> */}
                <p className={styles.slideType}>New Slide</p>
                <input
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  accept="image/png, image/jpeg, video/*, image/gif, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "center",
                justifyContent: "center",
                alignItems: "center",
                gap: "10%",
                marginBottom: "20px",
              }}
            >
              <button
                variant="contained"
                onClick={discardEdit}
                style={{
                  width: "150px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                className={styles.discardBtn}
              >
                Discard
              </button>
              <button
                variant="contained"
                onClick={handleSubmit}
                style={{
                  width: "150px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                className={styles.saveBtn}
              >
                Save
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PptSlideEdit;
