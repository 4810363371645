import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { api_token } from "../../Utils/Network";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Holiday() {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [datas, setData] = useState(null);
  const [academics, setAcademic] = useState([
    {
      date: "",
      title: "",
    },
  ]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;

  useEffect(() => {
    getHolidays();
  }, [])

  const getHolidays = () => {
    api_token
    .get(`profile/v1/school_holidays/`)
    .then((res) => {

      if(res.status == 200 || res.status == 201){
        let list = [];
        const datas = res.data.data;

        datas.map((vals, idx) => {
          let innerdata = {};
          innerdata.date = vals.date;
          innerdata.title = vals.title;

          list.push(innerdata);
        })
        setAcademic(list)
      }
    })
    .catch((err) => console.log(err))
  }

  // const handleDeleteRow = (index) => {
  //   const updateAcademic = [...academics];
  //   updateAcademic.splice(index, 1);
  //   setAcademic(updateAcademic);
  // }

  const handleDeleteRow = (index) => {
    setOpenDialog(true);
    setData(index);
  };

  const handleDelete = () => {
    const updateAcademic = [...academics];
    updateAcademic.splice(datas, 1);
    setAcademic(updateAcademic);
    setOpenDialog(false);
  };

  const handleChange = (e, rowIndex) => {
    const { name, value } = e.target;
    console.log(name, value);

    setAcademic((prevTeacherEdit) => {
      const updatedTeacherEdit = [...prevTeacherEdit];
      updatedTeacherEdit[rowIndex] = {
        ...updatedTeacherEdit[rowIndex],
        [name]: value,
      };
      return updatedTeacherEdit;
    });
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const handleClick = () => {
    setAcademic([
      ...academics,
      {
        date: "",
        title: "",
      },
    ]);
  };

  // Function to check if keys inside an object are not empty
  function areKeysNotEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false;
      }
    }
    return true;
  }

  // Function to check all objects in the array
  function isArrayValid(arr) {
    for (const obj of arr) {
      if (!areKeysNotEmpty(obj)) {
        return false;
      }
    }
    return true;
  }

  const GotoGrade = () => {
    const isValid = isArrayValid(academics);

    if (isValid) {
      api_token
        .post(`profile/v1/school_holidays/bulk_upload_holidays/`, {
          data: academics,
        })
        .then((res) => {
          if (res.status == 200) {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Successfully Added Holiday Details",
              errorType: "success",
            });
            setTimeout(() => {
              navigate(`/onboard/selectShift`);
            }, 2000);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Some fields are empty",
        errorType: "error",
      });
    }
  };

  return (
    <div className={styles.subContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>
      </div>

      <div className={styles.SingleAddContainer}>
        <div>
          <p className={styles.heads} style={{ marginBottom: "30px" }}>
            Add Holidays
          </p>

          <div className={styles.tableData}>
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr
                  className={`${styles.border_bottom} ${styles.borderRedBottom}`}
                >
                  {["Date", "Holiday"].map((row, rowIndex) => (
                    <th style={{ borderBottom: "1px solid white" }} className={styles.paras}>{row}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {academics &&
                  academics.map((row, rowIndex) => (
                    <tr key={rowIndex} className={styles.bodyData}>
                      <td style={{ border: "none" }}>
                        <input
                          type="date"
                          name="date"
                          value={row?.date}
                          className={styles.inputBox}
                          onChange={(e) => handleChange(e, rowIndex)}
                        />
                      </td>
                      <td style={{ border: "none" }}>
                        <input
                          type="text"
                          name="title"
                          value={row?.title}
                          className={styles.inputBox}
                          onChange={(e) => handleChange(e, rowIndex)}
                        />
                      </td>
                      <td style={{ border: "none" }}>
                        <button onClick={() => handleDeleteRow(rowIndex)} className={styles.delete}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className={styles.addRow} onClick={() => handleClick()}>
              <AddIcon /> Add New Row
            </div>
          </div>
        </div>
      </div>

      <div>
        <button className={styles.btns} onClick={() => GotoGrade()}>
          Next
        </button>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Alert !</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this ?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setOpenDialog(false); setData(null)}}>Cancel</Button>
          <Button onClick={handleDelete}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Holiday;
