import React from 'react'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./index.module.css"
// import videoSlide from "../../Assets/ContentMangement/ppt/videoSlide.png";
import videoslide from "../../Assets/ContentMangement/ppt/videoSlide.png"


function CostomButton({
  children,
  cardsPerSlide,
  arrows,
  images = [],
  showThumbs = false,
  imageKey = "",
  showIndicators = true,
  centerMode = false,
  infiniteLoop = true,
  autoPlay = false,
}) {
  const renderSlides = () => {
    const slides = React.Children.toArray(children);

    const groupedSlides = [];
    for (let i = 0; i < slides.length; i += cardsPerSlide) {
      groupedSlides.push(slides.slice(i, i + cardsPerSlide));
    }

    return groupedSlides.map((group, index) => (
      <div key={index} className={styles.slideGroup}>
        {group}
      </div>
    ));
  };
  return (
    <Carousel 
    showArrows={true}
    showStatus={false}
    showIndicators={showIndicators}
    infiniteLoop={infiniteLoop}
    dynamicHeight={false}
    className={styles.mySwiper}
    centerMode={centerMode}
    autoPlay={autoPlay}
    swipeable={true}
    emulateTouch={true}
    renderThumbs={() =>
      showThumbs &&
      images.map((image, index) => (
        <img
          key={index}
          src={image?.is_file == 1 ? image?.url : image?.is_file == 2 ? videoslide : image[imageKey]}
          alt="piece"
          width="300"
          height="60"
          // style={{ background: "green" , overflow:'auto'}}
          style={{borderRadius:"10px"}}
        />
      ))
    }
  >
    {renderSlides()}
    </Carousel >
  )
}

export default CostomButton