// import React from 'react'

// function AddSubject() {
//   return (
//     <div>AddSubject</div>
//   )
// }

// export default AddSubject

import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
// import { makeStyles } from '@mui/styles';
// import Slider from '@mui/material/Slider';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AddSubject({ setBoardGrade = () => {}, boardGrade }) {
  const navigate = useNavigate();
  const { gradeList, userState } = useContext(UserCredsContext);
  const [selectedItems, setSelectedItems] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newSubjectText, setNewSubjectText] = useState("");
  const [values, setValue] = useState(null);

  const handleSubjectChange = (event) => {
    const selectedSubjectId = event.target.value;
    setValue(selectedSubjectId)
    const selectedSubject = subjects.find(
      (subject) => subject.id === selectedSubjectId
    );
    if (!selectedSubjects.some((subject) => subject.id === selectedSubjectId)) {
      setSelectedSubjects([...selectedSubjects, selectedSubject]);
      setValue(null);
    }else{
      alert("Subject Already Added")
    }
  };

  const handleDeleteSubject = (index) => {
    const updatedSubjects = [...selectedSubjects];
    updatedSubjects.splice(index, 1);
    setSelectedSubjects(updatedSubjects);
  };

  const handleAddSubject = () => {
    setOpenDialog(true);
  };

  const handleNewSubjectTextChange = (event) => {
    setNewSubjectText(event.target.value);
  };

  const handleAddNewSubject = () => {
    const newSubject = {
      id: 1000100,
      title: newSubjectText,
      status: true,
      icon: "",
    };
    setSelectedSubjects([...selectedSubjects, newSubject]);
    setOpenDialog(false);
    setNewSubjectText("");
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const getSubjects = () => {
    api_token
      .get(`base/v1/subject/all_subjects`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          setSubject(res.data.data);
          if (userState?.school_profile?.is_onboarded) {
            let arr = [];
            let resData = res.data.data;
            resData.map((v, i) => {
              arr.push(v?.id);
            });
            setSelectedItems(arr);
            setBoardGrade({ ...boardGrade, subjects: [...arr] });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const GotoGrade = () => {
    const filteredData = selectedSubjects.filter(item => item.id !== 1000100);
    const filteredData2 = selectedSubjects.filter(item => item.id === 1000100);
    const subject = filteredData.map(item => {return item.id});

    if(filteredData2.length > 0){
      const modifiedData = filteredData2.map(item => ({ title: item.title }));

      api_token
      .post(`base/v1/create_subject`, modifiedData)
      .then((res) => {
        console.log(res, "res")
        if(res.data.length > 0){
          debugger
          let datas = [...subject]
          res.data.map((v, idx) => {
            datas.push(v.id);
          })
          console.log(filteredData2, filteredData, subject, "filterData")
          if (selectedSubjects.length > 0) {
            api_token
              .post(`external/v1/sync_db`, {
                board: [boardGrade.board],
                grade: boardGrade?.grade,
                subjects: datas,
              })
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  navigate(`/onboard/addsection`);
                }
              })
              .catch((err) => {
                setAlertDetails({
                  ...alertDetails,
                  open: true,
                  message: "Please Check the Details While Selection",
                  errorType: "error",
                });
              });
          } else {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Please Select Subject",
              errorType: "error",
            });
          }
      
          navigate(`/onboard/addsection`);
       
        }
      })
      .catch((err) => alert('something went wrong'))

    }else{
      console.log(filteredData2, filteredData, subject, "filterData")
      if (selectedSubjects.length > 0) {
        api_token
          .post(`external/v1/sync_db`, {
            board: [boardGrade.board],
            grade: boardGrade?.grade,
            subjects: subject,
          })
          .then((res) => {
            if (res.status == 200 || res.status == 201) {
              navigate(`/onboard/addsection`);
            }
          })
          .catch((err) => {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Please Check the Details While Selection",
              errorType: "error",
            });
          });
      } else {
        setAlertDetails({
          ...alertDetails,
          open: true,
          message: "Please Select Subject",
          errorType: "error",
        });
      }
  
      navigate(`/onboard/addsection`);
   
    }
    };

  const handleSelectGrade = (index) => {
    console.log(index, "indexxxx");
    const updatedSelectedItems = [...selectedItems];
    if (updatedSelectedItems.includes(index)) {
      //If grade is present in the array, then removing using splice method.
      updatedSelectedItems.splice(updatedSelectedItems.indexOf(index), 1);
    } else {
      updatedSelectedItems.push(index); //If grade is not present, then adding using push method.
    }
    setSelectedItems(updatedSelectedItems);
    setBoardGrade({ ...boardGrade, subjects: [...updatedSelectedItems] });
  };

  console.log(selectedSubjects, boardGrade, "selectedItemssss");
  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p className={styles.heads}>Powered by Ed5</p>
        </div>

        <div className={styles.centerContainer}>
          <h1 className={styles.selectionHeading}>Select Subjects</h1>

          <div style={{textAlign: 'left'}}>
            <div className={styles.mainSelection}>
              <div style={{marginRight:'50px'}}>Sr no</div>
              <div>Subject</div>
            </div>
            {selectedSubjects.map((subject, index) => (
              <div key={index} className={styles.subjectData}>
                <div className={styles.number}>{index+1}</div>

                <FormControl fullWidth className={styles.subjectMain}>
                  <InputLabel id={`select-label-${index}`} style={{color: 'white'}}>
                    Subject {index + 1}
                  </InputLabel>
                  <Select
                    labelId={`select-label-${index}`}
                    id={`select-${index}`}
                    value={subject.id}
                    label={`Subject ${index + 1}`}
                    // disabled
                  >
                    <MenuItem value={subject.id} style={{color: 'white !important'}}><p style={{color: 'white', margin: 0}}>{subject.title}</p></MenuItem>
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  style={{backgroundColor: 'white', color: 'black', margin: '0 10px'}}
                  onClick={() => handleDeleteSubject(index)}
                >
                  Delete
                </Button>
              </div>
            ))}
            <FormControl fullWidth style={{margin: '25px', width: '95%'}}>
              <InputLabel id="select-label" style={{color: 'white'}}>Select a Subject</InputLabel>
              <Select
              value={values}
                labelId="select-label"
                id="select"
                label="Select a Subject"
                onChange={handleSubjectChange}
                style={{color: 'white'}}
              >
                {subjects.map((subject) => (
                  <MenuItem key={subject.id} value={subject.id}>
                    {subject.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <Button variant="contained" onClick={handleAddSubject}>
              Add Subject
            </Button> */}
            <div style={{margin: '5px 23px', width: 'max-content', color: 'white', cursor: 'pointer'}} onClick={handleAddSubject}>+ Add New Row</div>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Add New Subject</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  label="Subject Name"
                  type="text"
                  fullWidth
                  value={newSubjectText}
                  onChange={handleNewSubjectTextChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <Button onClick={handleAddNewSubject}>Add</Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {subjects &&
              subjects.map((v, i) => (
                <div className={styles.boxData}>
                  <div
                    style={{ whiteSpace: "nowrap" }}
                    className={styles.titles}
                  >
                    {v?.title}
                  </div>
                  <div
                    style={{
                      border: "1px solid #fbd7e9",
                      marginTop: "20px",
                      height: "40px",
                      borderRadius: "8px",
                      backgroundColor: selectedItems.includes(v.id)
                        ? "white"
                        : "transparent",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleSelectGrade(v?.id);
                    }}
                  ></div>
                </div>
              ))}
          </div> */}
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddSubject;

const tabSwitch = [
  {
    title: "Product",

    id: 1,
  },

  {
    title: "WishList",

    id: 2,
  },
];

const board = [
  {
    id: 1,
    title: "ICSE",
  },
  {
    id: 2,
    title: "CBSE",
  },
  {
    id: 3,
    title: "International",
  },
];
