
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styles from "./UploadPPTSuccess.module.css"
import completed from "../../Assets/ContentMangement/ppt/completed.png";


const UploadPPTSuccess = ({ openSuccess }) => {
    const [open, setOpen] = useState(false);




    const handleClose = () => {
        setOpen(false);
    };





    return (
        <div>
            {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Give Feedback
      </Button> */}
            <Modal
                open={openSuccess}
                onClose={handleClose}
                aria-labelledby="feedback-modal-title"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',

                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                    className={styles.popUpCon}
                    style={{ textAlign: "center" }}

                >
                    <div style={{ width: "100%", display: "flex", flexDirection: "column",justifyContent: "center", alignItems: "center" , padding : "60px"}}>
                        <div>
                            <img src={completed} style = {{width : "150px"}}/>


                        </div>
                        <h1 className = {styles.heading}>Changes Successfully Made</h1>
                    </div>






                </Box>
            </Modal >
        </div >
    );
};

export default UploadPPTSuccess;