import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import fileDownload from "../../Assets/file_download.svg";
 
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
 
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function DownloadTimeTable() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [typeShift, setTypeShift] = useState([]);
  const [dynamicColor, setDynamicColor] = useState(null);
  const [timetable, setTimeTable] = useState([]);
 
  useEffect(() => {
    getShifts();
  }, []);
 
  useEffect(() => {
    getTimeTable(dynamicColor, value);
  }, [dynamicColor, value]);
 
  const getShifts = () => {
    api_token
      .get(`/calendar/v1/shifts/`)
      .then((res) => {
        const resultData = res.data.data;
        if (res.data.data) {
          setTypeShift(resultData);
          getTimeTable(resultData[0].id, value);
        }
      })
      .catch((err) => console.log(err));
  };
 
  const getTimeTable = (id, val) => {
    api_token
      .get(`calendar/v1/time_table/curriculum_table?shift=${id}&type=${val + 1}`)
      .then((res) => {
        if (res.data.data) {
          setTimeTable(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
 
  const GotoGrade = () => {
    navigate(`/dashboard/analytics`);
  };
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const handleShift = (v) => {
    console.log(v, value, "VID");
    setDynamicColor(v.id);
  };
 
  const handleDownloadPdf = (item) => {
    let postData = {
      shift: item?.shift,
      generation: item?.generation,
      class_id: item?.class_id,
    };
    api_token
      .post(`calendar/v1/time_table/generate_pdf/`, postData)
      .then((response) => {
        const resultData = response.data;
        renderPDF(resultData)
        // console.log(item, response, "hemaResponseee");
        // if (response.data.data) {
        //   window.open(resultData, "_blank");
        // }
      })
      .catch((err) => console.log(err));
  };
 
  const renderPDF = (pdfData) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  };
  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={`${styles.heading}`}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p className={styles.heads}>Powered by Ed5</p>
        </div>
        <div className={styles.timetableMain}>
          <h1 className={styles.curriculumText}>Curriculum Table</h1>
 
          <div className={styles.mainTable}>
            <div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {typeData.map((v, i) => (
                      <Tab
                        label={v.title}
                        {...a11yProps(0)}
                        sx={{
                          color: "white !important",
                          fontSize: "20px",
                          fontFamily: "Barlow",
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>
                {typeData.map((datas, i) => (
                  <TabPanel value={value} index={i}>
                    <div className={styles.shiftsTypes}>
                      {typeShift &&
                        typeShift.map((v, i) => (
                          <div
                            //   className={styles.singleShift}
                            className={`${styles.singleShift}
                    ${v.id == dynamicColor ? styles.selectedSingleShift : ""}
                  `}
                            onClick={() => handleShift(v)}
                          >
                            {v.title}
                          </div>
                        ))}
                    </div>
                  </TabPanel>
                ))}
              </Box>
            </div>
          </div>
 
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow className={styles.tableHeadRow}>
                  <TableCell className={styles.tableHeadItem}>
                    Curriculum Name
                  </TableCell>
                  <TableCell className={styles.tableHeadItem}>
                    Assigned To
                  </TableCell>
                  <TableCell className={styles.tableHeadItem}>
                    Created
                  </TableCell>
                  <TableCell className={styles.tableHeadItem}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
 
              <TableBody>
                {timetable.map((row) => (
                  <TableRow key={row.id} className={styles.tableDataRow}>
                    <TableCell className={styles.tableRowItem}>
                      {row.title}
                    </TableCell>
                    <TableCell className={styles.tableRowItem}>
                      {row.assigned_to}
                    </TableCell>
                    <TableCell className={styles.tableRowItem}>
                      {row.created}
                    </TableCell>
                    <TableCell className={styles.tableRowItem}>
                      <img
                        src={fileDownload}
                        alt="download_icon"
                        onClick={() => {
                          handleDownloadPdf(row);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
 
        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
 
export default DownloadTimeTable;
 
const typeData = [
  {
    id: 1,
    title: "Class",
  },
  {
    id: 2,
    title: "Teacher",
  },
];
 