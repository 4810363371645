import React, { useContext, useState } from "react";
import styles from "./ContentPptChapter.module.css";
import EditIcon from "@mui/icons-material/Edit";
import editSlideImg from "../../Assets/ContentMangement/ppt/editSlideImg.png";
import deleteSub from "../../Assets/ContentMangement/ppt/deleteSub.png";
import sub_1 from "../../Assets/ContentMangement/ppt/sub_1.png";
import { useLocation, useNavigate } from "react-router-dom";
import ContentPptTopic from "../../Components/ContentPptTopic/ContentPptTopic";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";

const ContentPptChapter = (props) => {
  const {
    chapter,
    index,
    handleChangeItem,
    openDeleteImgDialogue,
    openEditSlideImgDialogue,
    handleOpenFullScreenSlide,
    getEbookData
  } = props;
  let navigate = useNavigate();
  const { userState } = useContext(UserCredsContext);
  console.log(chapter, "chapter");


  const handleChangeToEdit = (selectedChapter) => {
    handleChangeItem(selectedChapter);
    navigate(`/dashboard/content/ppt/edit`, { state: chapter });
  };

  const onEditSlideImg = (slide, e) => {
    e.stopPropagation();
    openEditSlideImgDialogue(slide);
  };
  const onDeleteSlideImg = (slide, e) => {
    e.stopPropagation();
    openDeleteImgDialogue(slide);
  };

  const setOpenFullScreen = (item, e) => {
    e.stopPropagation();
    handleOpenFullScreenSlide(item);
  };

  const onGetEbookData = () => {
    getEbookData()
  }
  return (
    <div>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <h1>{`${chapter?.index}. ${chapter?.title}`}</h1>
        {/* {
          userState?.user_type === 5 && (
            <div
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "auto",
                borderRadius: "10px",
                padding: "8px",
                backgroundColor: "#ED388F",
                gap: "10px",
              }}
              onClick={() => handleChangeToEdit(chapter)}
            >
              <EditIcon
                style={{
                  fontSize: "30px",
                  padding: "3px",
                  borderRadius: "50%",
                  color: "white",
                }}
              />
              <p style={{ color: "white", margin: "0px" }}>Edit PPT</p>
            </div>

          )
        } */}

      </div>

      <div style={{ display: 'flex', gap: '20px', alignItems: "center", width: "100%" }}>
        {chapter?.ppt?.map((topic, topcIndex) => {
          return (
            <div id={topcIndex}>
              <ContentPptTopic
                topic={topic}
                key={topic?.id}
              // onEditSlideImg={onEditSlideImg}
              // getEbookData={onGetEbookData}
              // onDeleteSlideImg={onDeleteSlideImg}
              // setOpenFullScreen={setOpenFullScreen}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContentPptChapter;
