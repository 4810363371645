import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function EditorCms({ onChange = () => { }, height, question}) {

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    // const handleChange = (content, editor) => {
    //     if (typeof (onChange) === "function") {
    //         onChange(content, editor)
    //     }

    // }

    return (
        <div>
            <Editor
                apiKey='56hhymzpida1mnlix3mv94l0fjze25bgjrnskcqz7res1cxe'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={question}
                onEditorChange={onChange}
                init={{
                    height: height,
                    menubar: false,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </div>
    )
}

export default EditorCms