import React, { useState, useEffect } from "react";
import CustomCalendar from "../../../Components/CustomCalendar/CustomCalendar";
import { convertCalendarDate, getCurrentDateTime } from "../../../Utils/Utils";
import { api_token } from "../../../Utils/Network";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
} from "@mui/material";
import styles from "./index.module.css";
import DrawerComp from "../../../Components/DrawerComp/DrawerComp";
import moment from "moment";

function Test() {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [selectGrade, setSelectGrade] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  const [open,setOpen] = useState(false);
  const [eventCategory, setEventCategory] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [teacherList, setTeacher] = useState([]);
  const [classDetails, setClassDetail] = useState({
    event_type_id: "",
    title: "",
    description: "",
    start: "",
    end: "",
    commence: "",
    conclude: "",
    event_for: null,
    chapter_id: null,
    classes: null,
    subject_id: null,
    event_category_id: null,
    teacher: null,
  });

  console.log(dateTime, "date");
  useEffect(() => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    getListing(mnth, yrs);
  }, [dateTime]);

  useEffect(() => {
    getEventCategory();
    getEventType();
    getTeachers();
    getClass();
  }, []);

  const getTeachers = () => {
    api_token
      .get(`/profile/v1/teachers/?page_size=100`)
      .then((res) => {
        setTeacher(res.data.data);
      })
      .catch((err) => console.log(err));
  };


  const getClass = () => {
    api_token
      .get(`base/v1/class`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const addSubjects = (e) => {
    console.log(e, "ererere");
    let subject = e?.subjects_detail || [];
    setSubject(subject);
    let grade = e?.grade;
    setSelectGrade(grade);
  };

  const handleSubject = (e) => {
    setClassDetail({ ...classDetails, subject_id: e.target.value });
    console.log(e, selectGrade, "E");
    api_token
      .get(`cms/v1/chapter/?subject=${e.target.value}&grade=${selectGrade}`)
      .then((res) => {
        setChapters(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEventCategory = () => {
    api_token
      .get(`/base/v1/event_category`)
      .then((res) => {
        setEventCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEventType = () => {
    api_token
      .get(`/base/v1/event_type`)
      .then((res) => {
        setEventType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getListing = (month, year) => {
    // const { month, year } = dateTime;
    // &month=${month}&year=${year}
    api_token
      .get(`calendar/v1/events?event_type=5&month=${month}&year=${year}`)
      .then((response) => {
        console.log(response);
        const data = convertCalendarDate(response.data.data);
        setCalendarData(data);
      })
      .catch((error) => {});
  };

  const handleClicked = () =>{
    setOpen(!open)
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChange = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChanges = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };
  const SubmitData = () => {

    let obj = {...classDetails};
    for(let key in obj){
      if(obj[key] === null){
        delete obj[key];
      }
    }

    let cat =  eventType && eventType.length > 0 && eventType?.filter((data) => data?.title == "Tests")        
    obj.event_type_id = cat[0]?.id;

    const { classes, teacher, ...rest } = obj;

    const data = {
      classes: [classes],
      teacher: [teacher],
      ...rest,
    };
    console.log(obj, "let obj")

    api_token
    .post(`calendar/v1/events/create_events/`, data)
    .then((res) => {
      if (res.status == 200 || res.status == 201) {
        let mnth;
        let yrs;
        const { month, year } = currenctDate;
        mnth = month + 1;
        yrs = year;
        getListing(mnth, yrs);
        setOpen(!open)
      }
    })
    .catch((err) => console.log(err));
  }

  let events = eventType && eventType.length > 0 && eventType?.filter((data) => data?.title == "Tests")


  console.log(events,eventType, "events,dfdf");
  return (
    <div>
      <div className={styles.mainData}>
        <Button variant="contained" onClick={handleClicked}>Add Test</Button>
      </div>
      <div>
        <CustomCalendar calendarData={calendarData} setDateTime={setDateTime} />
      </div>

      <div>
        <DrawerComp
          open={open} 
          onClose={() => setOpen(false)} 
          anchor="right"
        >
          <div className={styles.drawerContain}>
            <h2>Add Test</h2>
            <div className={styles.textData}>
              <TextField
                name="title"
                label="Title"
                style={{ width: "100%" }}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                name="description"
                label="Description"
                style={{ width: "100%" }}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="start"
                label="start date"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="end"
                label="end date"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="commence"
                label="start time"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="conclude"
                label="end time"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event for</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_for"
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Student</MenuItem>
                  <MenuItem value={3}>Teacher</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Class</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="classes"
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {classes &&
                    classes.map((v, i) => (
                      <MenuItem value={v.id} onClick={() => addSubjects(v)}>
                        {v.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Subject</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  // value={selectedOption}
                  onChange={handleSubject}
                >
                  {subjects &&
                    subjects.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Chapter</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="chapter_id"
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {chapters &&
                    chapters.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Type</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_type_id"
                  disabled
                  value={events[0]?.id}
                  onChange={handleDropdownChange}
                >
                  {eventType &&
                    eventType.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Category</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_category_id"
                  // value={events[0]?.id}
                  onChange={handleDropdownChange}
                >
                  {eventCategory &&
                    eventCategory.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Teacher</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="teacher"
                  // value={selectedOption}
                  onChange={handleDropdownChanges}
                >
                  {teacherList &&
                    teacherList.map((v, i) => (
                      <MenuItem
                        value={v?.user?.id}
                      >{`${v?.user?.first_name} ${v?.user?.last_name}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <Button onClick={() => SubmitData()} variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </DrawerComp>
      </div>
    </div>
  );
}

export default Test;
