import React, { useContext, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import DashboardAnalytics from "../../Pages/DashboardAnalytics/DashboardAnalytics";
import Profile from "../../Pages/Profile/Profile";
import StudentProfileView from "../../Pages/StudentProfileView/StudentProfileView";
import TeacherProfileView from "../../Pages/TeacherProfileView/TeacherProfileView";
import Parents from "../../Pages/Parents/Parents";
import AdminProfile from "../../Pages/AdminProfile/AdminProfile";
import CustomCalendar from "../../Components/CustomCalendar/CustomCalendar";
import Navbar from "../../Components/Navbar/Navbar";
import styles from "./home.module.css";
import CustomAccordion from "../../Components/CustomAccordion/CustomAccordion";
import dashboard_img from "../../Assets/nonDashboard.png";
import analytics_img from "../../Assets/nonAnalytics.png";
import account_management from "../../Assets/nonAccountManagement.png";
import content_management from "../../Assets/nonContentManagement.png";
import academic_calendar from "../../Assets/nonAcademicCalendar.png";
import attendance from "../../Assets/nonAttendance.png";
import fee_management from "../../Assets/nonFeeManagement.png";
import top_image from "../../Assets/bgTop.png";
import top_leftimage from "../../Assets/bgCenter.png";
import bottom_right from "../../Assets/bgBottom.png";
import ed5logo from "../../Assets/ed5Logo.png";
import CalendarEvents from "../../Pages/CalendarEvents/CalendarEvents";
import ContentManagement from "../../Pages/ContentManagement/ContentManagement";
import Attendance from "../../Pages/Attendance/Attendance";
import school from "../../Assets/nonSchool.png";
import Teachers from "../../Assets/nonTeacher.png";
import student from "../../Assets/nonStudents.png";
import Parentsicon from "../../Assets/Parentsicon.png";
import videos from "../../Assets/nonVideo.png";
import PPT from "../../Assets/nonPpt.png";
import Assignments from "../../Assets/nonAssignment.png";
import Tests from "../../Assets/nonTest.png";
import Ebooks from "../../Assets/nonEbooks.png";
import Mindmap from "../../Assets/nonMindmap.png";
import Daily_classes from "../../Assets/nonDailyClasses.png";
import holidays from "../../Assets/nonHolidays.png";
import special_event from "../../Assets/nonEvents.png";
import exams from "../../Assets/nonExams.png";
import Questions from "../../Assets/nonQA.png";
import Mcq from "../../Assets/nonMcq .png";
import Birthdays from "../../Assets/nonBirthdays.png";
import fee_due_dates from "../../Assets/nonFeeDueDates.png";
import AcademicCalendar from "../../Pages/AcademicCalendar/AcademicCalendar";
import Vector from "../../Assets/Vector.png";
import AccountManagement from "../../Pages/AccountManagement/AccountManagement";
import CustomVideoPlayer from "../../Pages/CustomVideoPlayer/CustomVideoPlayer";
import PendingFeeList from "../../Pages/PendingFeesList/PendingFeeList";
import { checkEmptyObject } from "../../Utils/Utils";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import StudentProfile from "../../Pages/ProfileView/StudentProfile/StudentProfile";
import Main from "../../Pages/ProfileView/StudentProfile/CreateProfile/Main/Main";
import Mains from "../../Pages/ProfileView/TeacherProfile/CreateProfile/Main/Main";

import TeacherProfile from "../../Pages/ProfileView/TeacherProfile/TeacherProfile";
import ParentProfile from "../../Pages/ProfileView/ParentsProfile/ParentProfile";
import CreateParent from "../../Pages/ProfileView/ParentsProfile/CreateParent";

import whiteParent from "../../Assets/whiteParent.png";
import whiteSchool from "../../Assets/activeSchool.png";
import whiteAnalytic from "../../Assets/activeAnalytics.png";
import whiteStudent from "../../Assets/activeStudent.png";
import whiteTeacher from "../../Assets/activeTeacher.png";
import whiteSpecial from "../../Assets/activeEvents.png";
import whiteDailyClasses from "../../Assets/activeDailyClasses.png";
import whiteHoliday from "../../Assets/activeHolidays.png";
import whiteExams from "../../Assets/activeStatus.png";
import whiteTests from "../../Assets/activeTest.png";
import whiteBirthdays from "../../Assets/activeBirthdays.png";
import whiteFeeDue from "../../Assets/activeFeeDueDate.png";
import whitePpt from "../../Assets/activePPT.png";
import whiteTest from "../../Assets/activeTest.png";
import whiteQA from "../../Assets/activeQusetion.png";
import whiteMcq from "../../Assets/activeMcq.png";


import whiteEbook from "../../Assets/activeEbook.png";
import whiteVideo from "../../Assets/activeVideo.png";
import whiteMindmap from "../../Assets/activeMindmap.png";
import whiteAssignment from "../../Assets/activeAssignment.png";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { api_token } from "../../Utils/Network";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px) !important`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px) !important`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginLeft: "auto",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Dashboard() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeStatus, setActiveStatus] = useState(0);
  const [accordianId, setAccordianId] = useState(0);
  const [opens, setOpens] = useState(false);

  console.log(accordianId, activeStatus);

  console.log(activeStatus, "activeddd");
  const {
    boardList,
    gradeList,
    classList,
    subjectList,
    userState,
    setUrl,
    content_selection,
    setUserState,
  } = useContext(UserCredsContext);

  console.log(pathname, "pathnameeee");
  const SubmitFile = () => {
    api_token
      .get(`external/v1/clear_curriculum_data/`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          navigate(`/onboard/board`);
        }
      })
      .catch((err) => console.log(err));
  };

  console.log(userState, setUrl, content_selection, "usiakakisiisiis");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  console.log(pathname, "SelectedPath");

  React.useEffect(() => {
    if (pathname === "/dashboard" || pathname === "/dashboard/") {
      navigate(`/dashboard/analytics`);
    } else if (pathname === "/dashboard/content/select") {
      setAccordianId(3);
      setActiveStatus(21);
    }
    for (var i = 0; i < drawerList.length; i++) {
      for (var j = 0; j < drawerList[i].list.length; j++) {
        console.log(drawerList[i].list[j].url, pathname, "selectedTabPath");
        if (`/dashboard/${drawerList[i].list[j].url}` == pathname) {
          setAccordianId(drawerList[i].id);
          setActiveStatus(drawerList[i].list[j].id);
        }
      }
    }
  }, [pathname]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  console.log(activeStatus, "activeStatussss");

  const handleRoute = (title, url) => {
    console.log(title, url, "title-urlll");
    if (url !== "") {
      if (title === "Curriculum Planner") {
        // navigate(`/onboard/board`);
        // setOpens(true)

        if (userState?.school_profile?.is_onboarded) {
          setOpens(true)
        }else{
          navigate(`/onboard/board`);
        }
      } else {
        let value = url.includes("content"); //Initially while clicking ContentManagement Subtopics, data will be false due to ontent_selection object prop values are null.
        if (value) {
          const data = checkEmptyObject(content_selection); //After seleting three tabs, content_selection object props has true values, so data is true.
          console.log(content_selection, data, "contentSgatt");
          if (data) {
            navigate(`/dashboard/${url}`);
          } else {
            navigate(`/dashboard/content/select`);
            setUrl(`/dashboard/${url}`);
          }
        } else {
          navigate(`/dashboard/${url}`);
        }
      }
    }
  };

  const allRoutes = [
    { key: 1, path: "/account/*", element: AccountManagement },
    { key: 2, path: "/content/*", element: ContentManagement },
    { key: 3, path: "fees/*", element: PendingFeeList },
    { key: 4, path: "/calendar/*", element: AcademicCalendar },
    { key: 5, path: "/attendance/*", element: Attendance },
    { key: 6, path: "analytics", element: DashboardAnalytics },
  ];

  console.log(userState, "userState");
  console.log(drawerList, "drawerList");

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          width: "280px",
          "& .MuiDrawer-paper": {
            width: "280px",
          },
        }}
      >
        <div>
          <DrawerHeader>
            <img
              src={ed5logo}
              className={styles.logoImage}
              style={{ width: `${open ? "20%" : "65%"}` }}
            />
            {open && <p className={styles.logoName}>Ed5</p>}
            <IconButton>
              {!open ? (
                <ChevronRightIcon onClick={handleDrawerOpen} />
              ) : (
                <ChevronLeftIcon onClick={handleDrawerClose} />
              )}
            </IconButton>
          </DrawerHeader>
        </div>
        <Divider />

        <div style={{ width: "240px" }}>
          {drawerList.map((item, index) => {
            const RouteModule = userState?.permissions?.[0]?.module?.find(
              (a) => a.key === item.key
            );
            console.log(RouteModule, "RouteMMM");

            return (
              <div style={{ opacity: open ? 1 : 0 }}>
                <CustomAccordion
                  title={item.title}
                  mainId={item?.id}
                  keys={item}
                  RouteModule={RouteModule}
                  defaultOpen={item.id === accordianId ? true : false}
                  data={item.list}
                  handleEvent={handleRoute}
                  icon={item.icon}
                  active={activeStatus}
                  setActive={setActiveStatus}
                />
              </div>
            );
          })}
        </div>

        <Divider />
      </Drawer>
      <Box component="main" style={{ width: "calc(100% - 280px)" }}>
        <Navbar />
        <div className={styles.dashboardRSection}>
          <Routes>
            {/* <Route exact strict path="analytics" element={<DashboardAnalytics />} />
            <Route path="/account/*" element={<AccountManagement />} />
            <Route path="/content/*" element={<ContentManagement />} />
            <Route exact strict path="fees/*" element={<PendingFeeList />} />
            <Route path="/calendar/*" element={<AcademicCalendar />} />
            <Route path="/attendance/*" element={<Attendance />} /> */}
            {userState?.permissions[0]?.module?.map((m) => {
              const RouteModule = allRoutes.find((a) => a.key === m.key);
              console.log(userState?.permissions[0]?.module, "DDDDDDDDDDD");
              console.log(RouteModule, "RouteModule");
              return (
                <Route
                  path={RouteModule.path}
                  element={
                    <RouteModule.element
                      readAccess={m.read}
                      updateAccess={m.update}
                      writeAccess={m.write}
                      deleteAccess={m.delete}
                    />
                  }
                />
              );
            })}

            {/* <Route path="/content/*" element={<ContentManagement />} /> */}
            {/* <Route exact strict path="/profile/:id" element={<StudentProfileView />} /> */}
            <Route
              exact
              strict
              path="/profile/:id"
              element={<StudentProfile />}
            />
            <Route exact strict path="/profile/create" element={<Main />} />
            <Route
              exact
              strict
              path="/profile/CreateParents"
              element={<CreateParent />}
            />
            <Route
              exact
              strict
              path="/profile/CreateTeacher"
              element={<Mains />}
            />
            {/* <Route exact strict path="/teacherprofile/:id" element={<TeacherProfileView />} /> */}
            {/* <Route exact strict path="/teacherprofile/:id" element={<TeacherProfileView />} /> */}
            {/* <Route exact strict path="/teacherprofile/:id" element={<TeacherProfileView />} /> */}
            <Route
              exact
              strict
              path="/teacherprofile/:id"
              element={<TeacherProfile />}
            />
            {/* <Route exact strict path="/parents/:id" element={<Parents />} /> */}
            <Route
              exact
              strict
              path="/parents/:id"
              element={<ParentProfile />}
            />
            <Route path="/videoplayer" element={<CustomVideoPlayer />} />
            <Route exact strict path="/profile/" element={<StudentProfile />} />
          </Routes>
        </div>
        <img src={top_image} className={styles.topImage} />
        <img src={top_leftimage} className={styles.topLeft} />
        <img src={bottom_right} className={styles.bottomRight} />
      </Box>

      <DialogBox
        open={opens}
        onClose={() => setOpens(false)}
        dataSend={() => SubmitFile()}
        title="Reset Curriculum"
      >
        <p>Do you want to edit the curriculum?</p>
      </DialogBox>
    </Box>
  );
}

const drawerList = [
  {
    title: "Home",
    key: 6,
    id: 1,
    icon: dashboard_img,
    list: [
      {
        itemId: 1,
        title: "Dashboard",
        id: 0,
        url: "analytics",
        icon: analytics_img,
        activeIcon: whiteAnalytic,
      },
    ],
  },
  {
    title: "Account Management",
    id: 2,
    key: 1,
    icon: account_management,
    list: [
      {
        itemId: 2,
        title: "School Profile",
        id: 11,
        url: "account/school",
        icon: school,
        activeIcon: whiteSchool,
      },
      {
        itemId: 2,
        title: "Teacher Profile",
        id: 12,
        url: "account/teacher",
        icon: Teachers,
        activeIcon: whiteTeacher,
      },
      {
        itemId: 2,
        title: "Student Profile",
        id: 13,
        url: "account/student",
        icon: student,
        activeIcon: whiteStudent,
      },
      // {
      //   itemId: 2,
      //   title: "Parent Profile",
      //   id: 14, //initially 13, changed to 14.
      //   url: "account/parents",
      //   icon: Parentsicon,
      //   activeIcon: whiteParent,
      // },
    ],
  },
  {
    title: "Content Management",
    id: 3,
    key: 2,
    icon: content_management,
    list: [
      // {
      //   title: "Select",
      //   id: 20,
      //   url: "content/select"
      // },
      {
        itemId: 3,
        title: "PPT",
        id: 21,
        url: "content/ppt/view",
        icon: PPT,
        activeIcon: whitePpt,
      },
      {
        itemId: 3,
        title: "Ebooks",
        id: 22,
        url: "content/ebooks",
        icon: Ebooks,
        activeIcon: whiteEbook,
      },
      {
        itemId: 3,
        title: "Mindmaps",
        id: 23,
        url: "content/mindmap",
        icon: Mindmap,
        activeIcon: whiteMindmap,
      },
      {
        itemId: 3,
        title: "MCQ",
        id: 24,
        url: "content/test/testList",
        icon: Mcq,
        activeIcon: whiteMcq,
      },
      {
        itemId: 3,
        title: "Q&A",
        id: 25,
        url: "content/qa/qalist",
        icon: Questions,
        activeIcon: whiteQA,
      },
      {
        itemId: 3,
        title: "Assignments",
        id: 26,
        url: "content/assignment/assignList",
        icon: Assignments,
        activeIcon: whiteAssignment,
      },
      {
        itemId: 3,
        title: "Videos",
        id: 27,
        url: "content/videos/videoslist",
        icon: videos,
        activeIcon: whiteVideo,
      },
    ],
  },
  {
    title: "Attendance",
    id: 4, //initially id=2, changed to 3
    key: 3,
    icon: academic_calendar,
    list: [
      {
        itemId: 4,

        title: "Attendance",
        id: 31,
        icon: student,
        url: "attendance/students",
        activeIcon: whiteStudent,
      },
      // {
      //   title: "Teachers",
      //   id: 32,
      //   url: "attendance/teachers",
      //   icon: Parentsicon
      // },
    ],
  },
  {
    title: "Academic Calendar",
    id: 5, //initially 3, changed to 4
    key: 4,
    icon: attendance,
    list: [
      {
        itemId: 5,

        title: "Daily Classes",
        id: 41,
        url: "calendar/daily-classes",
        icon: Daily_classes,
        activeIcon: whiteDailyClasses,
      },
      {
        itemId: 5,

        title: "Holidays",
        id: 42,
        url: "calendar/holidays",
        icon: holidays,
        activeIcon: whiteHoliday,
      },
      {
        itemId: 5,

        title: "Special Events",
        url: "events",
        id: 43,
        url: "calendar/special-events",
        icon: special_event,
        activeIcon: whiteSpecial,
      },
      {
        itemId: 5,

        title: "Exams",
        id: 44,
        url: "calendar/exams",
        icon: exams,
        activeIcon: whiteExams,
      },
      // {
      //   itemId: 5,

      //   title: "Tests",
      //   id: 45,
      //   url: "calendar/tests",
      //   icon: Tests,
      //   activeIcon: whiteTests,
      // },
      {
        itemId: 5,

        title: "Birthdays",
        id: 46,
        url: "calendar/birthdays",
        icon: Birthdays,
        activeIcon: whiteBirthdays,
      },
      {
        itemId: 5,

        title: "Fee due dates",
        id: 47,
        url: "calendar/fee-due-dates",
        icon: fee_due_dates,
        activeIcon: whiteFeeDue,
      },
      {
        itemId: 6,
        title: "Curriculum Planner",
        id: 1,
        url: "/onboard/board",
        icon: analytics_img,
        activeIcon: whiteAnalytic,
        disabled: 0,
      },
    ],
  },
  {
    title: "Fee Management",
    id: 6, //initially 3, changed to 5
    key: 5,
    icon: fee_management,
    list: [
      //   {
      //   title: "Ledger",
      //   id: 51,
      //   url: "",
      //   icon: content_management
      // },

      {
        itemId: 6,

        title: "Students",
        id: 52,
        url: "fees/feelist",
        icon: student,
        activeIcon: whiteStudent,
      },
    ],
  },
];

// const birthdays = [
//   {
//     id: 7,
//     first_name: "",
//     last_name: "",
//     dateofBirth: "",
//     image: "",
//   },
//   {
//     id: 8,
//     first_name: "",
//     last_name: "",
//     dateofBirth: "",
//     image: "",
//   },
//   {
//     id: 6,
//     first_name: "",
//     last_name: "",
//     dateofBirth: "",
//     image: "",
//   },
// ]
