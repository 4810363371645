export function convertCalendarDate(_data) {
  console.log(_data, "dailyClassess")
  var custom_data = [..._data];
  for (var i = 0; i < custom_data.length; i++) {
    const { year, month, date } = getDate(custom_data[i].date);
    console.log(year, month, date, "year, month, date ");
    const {
      hr: s_hr,
      min: s_min,
      sec: s_sec,
    } = getTime(custom_data[i].commence);
    const {
      hr: e_hr,
      min: e_min,
      sec: e_sec,
    } = getTime(custom_data[i].conclude);

    // custom_data[i].title = (
    //   <div style={{ position: "relative", fontSize: "12px", fontWeight: "500", lineHeight: 1.3, padding:"5px" , boxSizing:"border-box" }}>
    //    <div> {custom_data[i]?.title}</div>
    //    {/* <div>{custom_data[i]?.id}</div>
    //    <div> {custom_data[i]?.date}</div> */}

    //    <div style={{display:"flex", gap:'5px'}}>
    //     <img width={60}  src={(custom_data[i]?.subject?.icon) ? custom_data[i]?.subject?.icon : custom_data[i]?.subject?.icon1 } />

    //     <div style={{color:"#fff"}}>
    //         <p style={{ margin:"5px 0 "}}> {custom_data[i]?.start_time} - {custom_data[i]?.end_time} </p>
    //         <span style={{margin:"5px 0 ", padding:"2px 5px", backgroundColor:"#FF54A4", borderRadius:"10px"}}>{custom_data[i]?.event_type?.title}</span>
    //         <p style={{ margin:"5px 0 "}}> {(custom_data[i]?.class_info[0]?.title) ? custom_data[i]?.class_info[0]?.title : "" }</p>
    //     </div>
    //    </div>
    //    <div style={{display:"flex", gap:"5px", alignItems:"center", justifyContent:"flex-end"}}>
    //     <img width={25} height={25} src={custom_data[i]?.teacher_info[0]?.avatar}  />
    //     <p style={{ margin:"5px 0 "}}>{custom_data[i]?.teacher_info[0]?.first_name} {custom_data[i]?.teacher_info[0]?.last_name}</p>
    //    </div>
    //   </div>
    // )

    custom_data[i].title = (
      <div>
        <p style={{margin:"0"}}>{custom_data[i]?.title}</p>
       </div>
    )

    custom_data[i].start = new Date(year, month - 1, date, s_hr, s_min, s_sec);
    custom_data[i].end = new Date(year, month - 1, date, e_hr, e_min, e_sec);
  }
  
  return custom_data;
}

export function getDate(_date) {
  var myDate = _date.split("-");
  const year = Number(myDate[0]);
  const month = Number(myDate[1]);
  const date = Number(myDate[2]);
  return { year, month, date };
}

export function getTime(_time) {
  var myTime = _time.split(":");
  const hr = Number(myTime[0]);
  const min = Number(myTime[1]);
  const sec = Number(myTime[2]);
  return { hr: myTime[0], min: myTime[1], sec: myTime[2] };
}

export function getCurrentDateTime() {
  const _date = new Date();
  const year = _date.getFullYear();
  const month = _date.getMonth();
  const date = _date.getDate();
  return { year, month, date };
}

export function checkEmptyObject(params) {
  return [...Object.values(params)].every(Boolean);
}

export function checkObjectValues(obj) {
  // debugger;
  for (let key in obj) {
    if (obj[key]) {
      return false;
    }
  }
  return true;
}

export default function MillisecondsToTime(milliseconds) {
  // const seconds = Math.floor((milliseconds / 1000) % 60);
  // const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  // const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  // return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
  //   2,
  //   "0"
  // )}:${String(seconds).padStart(2, "0")}`;

  const date = new Date(milliseconds);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  const formattedTime = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
console.log(formattedTime, "formattedtime")
  return formattedTime

//  export function ArrayFunction(val) {
//     var a = [];
//     for (var i = 1; i < val; i++) {
//         a.push(i);
//     }
//     return a;
// }


}


export function getCurrentTimeHours(now) {
  const hours = now.getHours();
const minutes = now.getMinutes();
const seconds = now.getSeconds();

// Ensure two-digit formatting for hours, minutes, and seconds 
 const formattedHours = hours < 10 ? `0${hours}` : hours;
const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}