import React, { useState, useEffect } from "react";
import { Alert, Button, InputLabel, MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { api_token } from "../../Utils/Network";


const ContentTopicAndSubtopicList = ({chapterId, handleSelect = () => { }, data}) => {
  
  const [topicList, setTopicList] = useState([]);
  const [subtopicList, setSubtopicList] = useState([]);
  const [formData, setFormData] = useState({
    topic_id : data?.topic?.id,
    subtopic_id : data?.subtopic?.id
  })

  console.log("formData", formData)

  const getIndvidualSubtopics = (topicId) => {
    api_token
      .get(`/content/v1/subtopic_v2?topic=${topicId}`)
      .then((response) => {
        setSubtopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(data, "divyadata")

  const getTopicData = () => {
    api_token
      .get(`cms/v1/topic?chapter=${chapterId?.id || chapterId}`)
      .then((response) => {
        setTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(data, "finalData")

  const getSubtopicData = () => {
    api_token
      .get(`/content/v1/subtopic_v2?chapter=${chapterId?.id || chapterId}`)
      .then((response) => {
        setSubtopicList(response?.data?.data);
      });
  };

  const handleSetFormData=(e) => {
    console.log(e.target, "fkakaka")
    // console.log(e, "harie")
    handleSelect(e)
    const {name, value} = e.target;
    console.log(name, value, "name-value")
    setFormData({...formData, [name] : value})
      // let topic_id = topic.target.value;
      if (name == "topic_id") {
        getIndvidualSubtopics(value);
      }
  }

  useEffect(() => {
    getTopicData();
    getSubtopicData();
  }, []);
  return (
    <div style = {{display : "flex", flexDirection : "row", alignItems : "center", width : "100%", gap : "20px"}}> 
      <div style={{ width: "40%" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Topic Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Topic Name"
            name="topic_id"
            value = {formData?.topic_id}
            // value={data[chapterFilter]?.id}


            // onChange={(topic) => {
            //     handleSelect(topic)
            //   let topic_id = topic.target.value;
            //   if (topic_id) {
            //     getIndvidualSubtopics(topic_id);
            //   }
            // }}
            onChange = {(e) =>handleSetFormData(e)}
            style={{ width: "100%" }}
          >
            {topicList &&
              topicList.map((topic, i) => (
                <MenuItem value={topic.id}>{topic.title}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ width: "40%"}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Subtopic Name</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Subtopic Name"
            name="subtopic_id"
            value = {formData?.subtopic_id}

            // onChange={(topic) => {
            //     handleSelect(topic)

            // }}
            onChange = {(e) =>handleSetFormData(e)}

            style={{ width: "100%" }}
          >
            {subtopicList &&
              subtopicList.map((subtopic, i) => (
                <MenuItem value={subtopic.id}>{subtopic.title}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default ContentTopicAndSubtopicList;
