import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import Uploads from "../../Assets/Onboarding/Upload.png";
import uploadTeacher from "../../Assets/Onboarding/uploadTeacher.png";

import { useNavigate } from "react-router-dom";
import { read, utils, write, writeFile } from "xlsx";
import { saveAs } from "file-saver";
import Dropzone from "react-dropzone";
import { api_token, base_url, _access_token } from "../../Utils/Network";
import axios from "axios";
import Teacher from "../../File/Teachers.xlsx";
import UploadTeacherRow from "./UploadTeacherRow";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function UploadTeacher() {
  const [data, setData] = useState(null);
  const [heading, setHeading] = useState([]);
  const [download, setDownload] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;

  

  const GotoGrade = async () => {
    if(data){
      const worksheet = getWorksheet(data);
      console.log(worksheet, "Shock");
      generateXlsxFile(worksheet, "myFile");
    }else{
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please Upload File",
        errorType: "error",
      });
    }
   
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const getWorksheet = (data) => {
    const headings = [[...heading]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    const worksheet = utils.sheet_add_json(ws, data, {
      origin: "A2",
      skipHeader: true,
    });
    return worksheet;
  };

  const generateXlsxFile = async (worksheet, filename) => {
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // const file = write(workbook, { type: 'binary', bookType: 'xlsx' });
    const file = write(workbook, { type: "buffer", bookType: "xlsx" });
    // const blob = new Blob([file], { type: 'application/vnd.ms-excel' });
    const blob = new Blob([file], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const files = new File([blob], "teacher.xlsx", {
      type: blob.type,
      lastModified: new Date().getTime(),
    });

    let data = {
      file: files,
    };
    const formData = new FormData();
    if (data.file) formData.append("file", data.file);
    try {
      await axios({
        method: "Post",
        url: `${base_url}external/v1/extract_teacher/`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        headers: { Authorization: `Bearer ${_access_token}` },
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            // navigate(`/onboard/selectShift`);
            
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Successfully Added Teachers Data",
              errorType: "success",
            });
            setTimeout(() => {
              navigate(`/onboard/academic`);
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Something went wrong, Please check the data and try again",
            errorType: "error",
          });
        });
    } catch (error) {
      console.log(error);
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Something went wrong, Please check the data and try again",
        errorType: "error",
      });
    }
  };

  function convertToJSON(file) {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    console.log(reader, "FFFFFff");
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet, {
          blankrows: true,
          defval: null,
          raw: true,
          header: 1,
          cellDates: false,
          cellText: true,
        });
        console.log(json, "JJJJJJJJJJJ");
        setHeading(json[0]);
        json.shift();
        resolve(json);
      };
      reader.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  const handleFileUpload = (files) => {
    const file = files[0];
    convertToJSON(file)
      .then((json) => {
        setData(json);
      })
      .catch((error) => {
        console.error(error, "JJSJHds");
      });
  };

  const handleCellChange = (rowIndex, columnIndex, value) => {
    const newData = [...data];
    newData[rowIndex][columnIndex] = value;
    setData(newData);
  };

  return (
    <div className={styles.mainBackground}>
      {
        (currentPage == 0) ? 
        <UploadTeacherRow setCurrentPage={setCurrentPage}/>:


      <div className={styles.subContainer}>
        <div className={styles.headerContainer}>
          <div className={styles.heading}>
            <ArrowBackIcon style={{ fontSize: "30px" }} />
            <p>Powered By Ed5</p>
          </div>
        </div>
        <div>
          {!data ? (
          <div>
            <div className={styles.uploadContainer}>
              <div className={styles.teacherContainer}>
                <p className={styles.heads}>Upload Teacher's Data</p>
                <div style={{ textAlign: "start" }}>
                  <p className={styles.paras}>
                    1. Download the sample Excel.
                    <a
                      href={Teacher}
                      download
                      style={{ textDecoration: "none" }}
                    >
                      <span
                        style={{
                          marginLeft: "20px",
                          color: "#F69BC7",
                          cursor: "pointer",
                        }}
                      >
                        Download
                        <img
                          src={uploadTeacher}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                        />
                      </span>
                    </a>
                  </p>

                  <p className={styles.paras}>
                    2. Download the sample Excel, fill in the form field details
                    in the respective columns (ensuring mandatory fields are
                    completed), and then upload the edited Excel.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <Dropzone onDrop={handleFileUpload}>
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={styles.dragDrop}>
                        <img src={Uploads} alt="images" />
                        <input {...getInputProps()} />
                        <p style={{ color: "white" }}>
                          Drag and drop File Here
                        </p>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
            </div>
          </div>
        ) : (
          //After uploading file, data is not null. so, table will show......
          <div className={styles.dataTable}>
            {/* <div>You can edit the Fields here</div> */}
            <div
              style={{
                color: "white",
                fontSize: "32px",
                fontWeight: "500",
                textAlign: "center",
                marginTop: "80px",
                marginBottom: "60px",
              }}
            >
              Total Number of Teachers{" "}
              <span
                style={{
                  marginLeft: "20px",
                  padding: "10px",
                  borderRadius: "8px",
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "25px",
                  fontWeight: "400",
                }}
              >
                {data?.length}
              </span>
            </div>
            <div className={styles.tableData}>
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr
                    className={`${styles.border_bottom} ${styles.borderRedBottom}`}
                  >
                    {heading &&
                      heading !== undefined &&
                      heading.map((row, rowIndex) => (
                        <th style={{ border: "none" }} className={styles.parasRow}>{row}</th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((row, rowIndex) => (
                      <tr key={rowIndex} className={styles.bodyData}>
                        {row &&
                          row.map((cell, columnIndex) => (
                            <td key={columnIndex} style={{ border: "none" }}>
                              <input
                                type="text"
                                value={cell}
                                className={styles.inputBox}
                                onChange={(e) =>
                                  handleCellChange(
                                    rowIndex,
                                    columnIndex,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* <button>Upload</button> */}
            <div>
              <button
                className={styles.uploadBtn}
                style={{
                  alignSelf: "center",
                  marginBottom: "10px",
                  marginLeft: "10px",
                }}
                onClick={() => setData(null)}
              >
                Upload
              </button>
            </div>
          </div>
        )}
        </div> 

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>

        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
      </div>      }
    </div>
  );
}

export default UploadTeacher;
