import React, { useEffect, useState, useContext } from "react";
import styles from "./analytics.module.css";
import DashboardChart from "../../Components/DashboardChart/DashboardChart";
import DashBoardTopBar from "../../Components/DashboardTopBar/DashBoardTopBar";

import DashboardPendingFee from "../../Components/DashboardPendingFees/DashboardPendingFee";
import DashDailyCalender from "../../Components/DashDailyCalender/DashDailyCalender";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import BoxShadow from "../../Components/BoxShadow/BoxShadow";
import { api_token } from "../../Utils/Network";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import DialogBox from "../../Components/DialogBox/DialogBox";

function DashboardAnalytics(props) {
  const [value, onChange] = useState(new Date());
  const [totalCount, setTotalCount] = useState({});
  const [birthdaysData, setbirthdaysData] = useState([]);
  const [open, setOpen] = useState(false);
  const { userState } = useContext(UserCredsContext);
  console.log(birthdaysData, "birthdaysDatatatta");

  let navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`/dashboard/attendance/students`);
  };

  //while clicking on Birthdays view all , it navigates to birthday calendar....
  const handleBirthData = () => {
    navigate(`/dashboard/calendar/birthdays`);
  };

  useEffect(() => {
    getCountData();
    getBirthDays();
  }, []);

  const getCountData = () => {
    api_token
      .get(`/calendar/v1/school_dashboard_attendence/`)
      .then((res) => {
        let data = res.data.data;
        setTotalCount((prev) => data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const StartOnBoard = () => {
    if (userState?.school_profile?.is_onboarded) {
      setOpen(true)
    }else{
      navigate(`/onboard/board`);
    }
  };


  const SubmitFile = () => {
    api_token
    .get(`external/v1/clear_curriculum_data/`)
    .then((res) => {
      if(res.status == 200 || res.status == 201){
        navigate(`/onboard/board`);
      }
    })
    .catch((err) => console.log(err))
  }

  const getBirthDays = () => {
    api_token
      .get(`calendar/v1/birthdays`)
      .then((res) => {
        let data = res.data.data;
        console.log(data, "dataaaa");
        let demo = data.splice(0, 6);
        console.log(demo, "demakaka");
        setbirthdaysData(demo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const students = [
    {
      name: "Present",
      value: totalCount?.todays_attendance?.student_percentile,
      fill: "#FB7D5B",
    },
    {
      name: "Absent",
      value: 100 - totalCount?.todays_attendance?.student_percentile,
    },
  ];

  const teachers = [
    {
      name: "Present",
      value: totalCount?.todays_attendance?.teacher_percentile,
      fill: "#FCC43E",
    },
    {
      name: "Absent",
      value: 100 - totalCount?.todays_attendance?.teacher_percentile,
    },
  ];

  const staff = [
    {
      name: "Present",
      value: totalCount?.todays_attendance?.staff,
      fill: "#303972",
    },
    { name: "Absent", value: 100 - totalCount?.todays_attendance?.staff },
  ];

  console.log(totalCount, "hariiiHariii");

  return (
    <>
      <div className={styles.container}>
        <div style={{ marginBottom: "30px" }}>
          <DashBoardTopBar count={totalCount?.total_resource} />
        </div>

        {/* <div style={{ margin: "20px 0" }}>
          <Button variant="contained" onClick={() => StartOnBoard()}>
            Start Onboarding
          </Button>
        </div> */}
        <div className={styles.analyticsHeader} style={{ width: "100%" }}>
          <div className={styles.flexDiv}>
            <BoxShadow>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ fontSize: "32px" }}>Todays Attendance</span>
                <Button
                  variant="outlined"
                  onClick={handleRedirect}
                  style={{ width: "100px", height: "50px" }}
                >
                  View All
                </Button>
              </div>
              <div className={styles.chartMain}>
                <DashboardChart
                  students={students}
                  teachers={teachers}
                  // staff={staff}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid black",
                }}
              >
                {/* <div className={styles.flexIndex}>
                  <div
                    className={styles.label}
                    style={{ backgroundColor: "#303972" }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    Staff &#40;{staff?.[0]?.value}&#37;&#41;
                  </div>
                </div> */}
                <div className={styles.flexIndex}>
                  <div
                    className={styles.label}
                    style={{ backgroundColor: "#FCC43E" }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    Teachers &#40;{teachers?.[0]?.value}&#37;&#41;
                  </div>
                </div>
                <div className={styles.flexIndex}>
                  <div
                    className={styles.label}
                    style={{ backgroundColor: "#FB7D5B" }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    Students &#40;{students?.[0]?.value}&#37;&#41;
                  </div>
                </div>
              </div>
            </BoxShadow>
          </div>
          <div className={styles.flexDiv}>
            <BoxShadow>
              <DashDailyCalender />
            </BoxShadow>
          </div>
          <div className={styles.flexDiv}>
            <BoxShadow>
              <DashboardPendingFee />
            </BoxShadow>
          </div>
          <div className={styles.flexDiv}>
            <BoxShadow>
              <div className={styles.eventCalender}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span style={{ fontSize: "32px" }}>Birthdays This Month</span>
                  <Button
                    variant="outlined"
                    onClick={handleBirthData}
                    style={{ width: "100px", height: "50px" }}
                  >
                    View All
                  </Button>
                </div>

                <div className={styles.nameData}>
                  {birthdaysData.map((v, i) => (
                    <div className={styles.birthDayBox}>
                      {v.avatar ? (
                        <img
                          src={v.avatar}
                          alt="image"
                          className={styles.imgAvt}
                        />
                      ) : (
                        <AccountCircleIcon className={styles.imgAvt} />
                      )}

                      <p style={{ margin: "5px 0" }}>{v.name}</p>
                      <p style={{ margin: "5px 0" }}>{v.class}</p>
                    </div>
                  ))}
                </div>
              </div>
            </BoxShadow>
          </div>
        </div>

        <DialogBox
        open={open}
        onClose={() => setOpen(false)}
        dataSend={() => SubmitFile()}
        title="Reset Curriculum"
      >
        <p>Do you want to reset the curriculum?</p>
        
      </DialogBox>
      </div>
    </>
  );
}

export default DashboardAnalytics;

// const students = [
//   { name: "Present", value: totalCount?.todays_attendance?.student_percentile, fill: "#FB7D5B" },
//   { name: "Absent", value: 100 - totalCount?.todays_attendance?.student_percentile },
// ];

// const teachers = [
//   { name: "Present", value: 60, fill: "#FCC43E" },
//   { name: "Absent", value: 40 },
// ];

// const staff = [
//   { name: "Present", value: 30, fill: "#303972" },
//   { name: "Absent", value: 70 },
// ];
