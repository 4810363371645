import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { api_token } from "../../Utils/Network";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function UploadTeacherRow({ setCurrentPage = () => {} }) {
  const navigate = useNavigate();
  const [teacherList, setTeacherList] = useState([]);
  const [showTeacher, setShowTeacher] = useState(0);
  const [teacherEdit, setTeacherEdit] = useState([
    {
      title: "",
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      // place_of_birth: "",
      gender: "",
    },
  ]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;

  useEffect(() => {
    getTeacher();
  }, []);

  const handleCellChange = (rowIndex, key, value) => {
    console.log(rowIndex, key, value, "data Valuess");
    // const newData = [...teacherEdit];
    // newData[rowIndex][columnIndex] = value;
    // setTeacherEdit(newData);
    setTeacherEdit((prevTeacherEdit) => {
      const updatedTeacherEdit = [...prevTeacherEdit];
      updatedTeacherEdit[rowIndex] = {
        ...updatedTeacherEdit[rowIndex],
        [key]: value,
      };
      return updatedTeacherEdit;
    });
  };

  const handleClick = () => {
    setTeacherEdit([
      ...teacherEdit,
      {
        title: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        // place_of_birth: "",
        gender: "",
      },
    ]);
  };

  const getTeacher = () => {
    api_token
      .get(`profile/v1/teachers/?page_size=70`)
      .then((res) => {
        let teacher = res.data.data;
        if (teacher.length > 0) {
          let data = [];
          teacher.map((v, i) => {
            let vals = {
              title: v?.title,
              first_name: v?.user?.first_name,
              last_name: v?.user?.last_name,
              phone: v?.user?.phone,
              email: v?.user?.email,
              // place_of_birth: v?.place_of_birth,
              gender: v?.gender == 1 ? "Male" : v?.gender == 2 ? "Female" : "",
            };
            data.push(vals);
          });
          setTeacherEdit(data);
          setShowTeacher(1);
        } else {
          setShowTeacher(0);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  // Function to check if keys inside an object are not empty
  function areKeysNotEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        return false;
      }
    }
    return true;
  }

  // Function to check all objects in the array
  function isArrayValid(arr) {
    for (const obj of arr) {
      if (!areKeysNotEmpty(obj)) {
        return false;
      }
    }
    return true;
  }

  const handleDeleteRow = (index) => {
    const updatedTeacherEdit = [...teacherEdit];
    updatedTeacherEdit.splice(index, 1);
    setTeacherEdit(updatedTeacherEdit);
  };

  const GotoGrade = () => {
    const isValid = isArrayValid(teacherEdit);

    if (isValid) {
      let data = [];

      teacherEdit.map((val) => {
        if (val.gender.toLowerCase() === "male") {
          val.gender = 1;
        } else if (val.gender.toLowerCase() === "female") {
          val.gender = 2;
        } else {
          val.gender = "";
        }
      });

      console.log(teacherEdit, "TEacherEditssss");

      teacherEdit.map((v, i) => {
        let added = {
          user: {
            phone: v?.phone,
            email: v?.email,
            first_name: v?.first_name,
            last_name: v?.last_name,
            teacher_profile: {
              title: v?.title,
              // place_of_birth: v?.place_of_birth,
              gender: v?.gender,
            },
          },
        };
        data.push(added);
      });
      console.log(data, "DATAAAAAAA");
      api_token
        .post(`profile/v1/teachers/upload_teachers_bulk/`, { data: data })
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Teacher Added Successfully",
              errorType: "success",
            });
            setTimeout(() => {
              navigate(`/onboard/academic`);
            }, 2000);
          }
        })
        .catch((err) => {
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Something went wrong, Please check the Data",
            errorType: "error",
          });
        });
    } else {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Some Fields are empty.",
        errorType: "error",
      });
    }
  };

  console.log(teacherEdit, "TEACHEREDIT");
  return (
    <div className={styles.subContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>
      </div>

      <div className={styles.containButton}>
        <button className={styles.buttons} onClick={() => setCurrentPage(1)}>
          <EditOutlinedIcon />
          Bulk Upload
        </button>
      </div>

      <div className={styles.SingleAddContainer}>
        <div>
          <p className={styles.heads} style={{ marginBottom: "30px" }}>
            Teachers
          </p>

          <div className={styles.tableData}>
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr
                  className={`${styles.border_bottom} ${styles.borderRedBottom}`}
                >
                  {[
                    "Title",
                    "First Name",
                    "Last Name",
                    "Phone",
                    "Email",
                    // "Place of Birth",
                    "Gender",
                  ].map((row, rowIndex) => (
                    <th style={{ border: "none" }} className={styles.parasRow}>{row} <span style={{color: 'red'}}>*</span></th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {teacherEdit &&
                  teacherEdit.map((row, rowIndex) => (
                    <tr key={rowIndex} className={styles.bodyData}>
                      {Object.entries(row).map(([key, value], columnIndex) => (
                        <td key={columnIndex} style={{ border: "none" }}>
                          <input
                            type="text"
                            value={value}
                            name={``}
                            className={styles.inputBox}
                            onChange={(e) =>
                              handleCellChange(rowIndex, key, e.target.value)
                            }
                          />
                        </td>
                      ))}

                      <td style={{ border: "none" }}>
                        <button onClick={() => handleDeleteRow(rowIndex)} className={styles.delete}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.addRow} onClick={() => handleClick()}>
            <AddIcon /> Add New Row
          </div>
        </div>
      </div>

      <div>
        <button className={styles.btns} onClick={() => GotoGrade()}>
          Next
        </button>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UploadTeacherRow;
