import React, { useState, useEffect } from "react";
import CustomCalendar from "../../../Components/CustomCalendar/CustomCalendar";
import { convertCalendarDate, getCurrentDateTime } from "../../../Utils/Utils";
import { api_token } from "../../../Utils/Network";
import moment from "moment";
import {
  Button,
  FormControl,
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import styles from "./index.module.css";
import DrawerComp from "../../../Components/DrawerComp/DrawerComp";

function Exams() {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubject] = useState([]);
  const [selectSub, setSelectSub] = useState([]);
  const [selectGrade, setSelectGrade] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());
  const [dateTime, setDateTime] = useState();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [eventCategory, setEventCategory] = useState([]);
  const [eventType, setEventType] = useState([]);
  const [teacherList, setTeacher] = useState([]);
  const [classDetails, setClassDetail] = useState({
    event_type_id: "",
    title: "",
    description: "",
    start: "",
    end: "",
    commence: "",
    conclude: "",
    event_for: null,
    chapter_id: null,
    classes: null,
    subject_id: null,
    event_category_id: null,
    teacher: null,
  });
  const [message, setMessage] = useState({});

  useEffect(() => {
    let mnth;
    let yrs;
    if (dateTime) {
      const date = moment(dateTime, "MMM DD YYYY");
      mnth = date.format("M"); // Full month name (e.g., "June")
      yrs = date.format("YYYY"); // 4-digit year (e.g., "2023")
    } else {
      const { month, year } = currenctDate;
      mnth = month + 1;
      yrs = year;
    }
    getListing(mnth, yrs);
  }, [dateTime]);

  useEffect(() => {
    getEventCategory();
    getEventType();
    getTeachers();
    getClass();
  }, []);

  const getClass = () => {
    api_token
      .get(`base/v1/class`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const addSubjects = (e) => {
    console.log(e, "ererere");
    let subject = e?.subjects_detail || [];
    setSubject(subject);
    let grade = e?.grade;
    setSelectGrade(grade);
  };

  const getEventCategory = () => {
    api_token
      .get(`/base/v1/event_category`)
      .then((res) => {
        setEventCategory(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleSubject = (e) => {
    setClassDetail({ ...classDetails, subject_id: e.target.value });
    console.log(e, selectGrade, "E");
    api_token
      .get(`cms/v1/chapter/?subject=${e.target.value}&grade=${selectGrade}`)
      .then((res) => {
        setChapters(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getEventType = () => {
    api_token
      .get(`/base/v1/event_type`)
      .then((res) => {
        setEventType(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTeachers = (id, classes) => {
    if (id || classes) {
      api_token
        .get(`/profile/v1/teachers/?page_size=100&subject=${id}&classes=${classes}`)
        .then((res) => {
          setTeacher(res.data.data);
        })
        .catch((err) => console.log(err));
    } else {
      api_token
        .get(`/profile/v1/teachers/?page_size=100`)
        .then((res) => {
          setTeacher(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    
    if (selectGrade == null || selectSub == null) {
      getTeachers()
    } else {
      getTeachers(selectSub, selectGrade)
    }
  }, [selectSub, selectGrade])

  const getListing = (month, year) => {
    api_token
      .get(`calendar/v1/events?event_type=7&month=${month}&year=${year}`)
      .then((response) => {
        console.log(response);

        const new_data = response?.data?.data.map((obj) => {
          var s_time = moment(obj?.commence, "HH:mm:ss").format("h:mm A");
          var e_time = moment(obj?.conclude, "HH:mm:ss").format("h:mm A");
          return {
            ...obj,
            start_time: s_time,
            end_time: e_time,
          };
        });
        // const data = convertCalendarDate(response.data.data);

        const data = convertCalendarDate(new_data);
        console.log(data, "datatatataa");
        setCalendarData(data);
        setCalendarData(data);
      })
      .catch((error) => { });
  };

  const handleClicked = (id) => {
    setOpen(!open);
    setSelectGrade();
    setSelectSub();

    if (id) {
      setClassDetail({});
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChange = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  const handleDropdownChanges = (e) => {
    console.log(e.target, "E");
    const { name, value } = e.target;
    setClassDetail({ ...classDetails, [name]: value });
  };

  // edit api work
  const handleCalendarDataUpdate = (data) => {
    // Handle the received data here in the parent component
    console.log("Received data in parent component:", data);

    // Example: Update state with received data
    // setCalendarData(data);
    // setEditData(data)
    handleClicked(data?.id);
    setClassDetail({
      event_type_id: data?.event_type?.id,
      title: data?.title?.props?.children[0]?.props?.children[1],
      description: data?.description,
      start: data?.date,
      end: data?.date,
      commence: data?.commence,
      conclude: data?.conclude,
      event_for: data?.event_for,
      chapter_id: data?.chapter?.id,
      classes: data?.classes[0],
      subject_id: data?.subject?.id,
      event_category_id: data?.event_category?.id,
      teacher: data?.teacher[0],
      id: data?.id,
    });

    setSelectGrade(data?.classes[0])
    setSelectSub(data?.subject?.id)
  };

  useEffect(() => {
    getClass();
    if (classDetails?.classes) {
      console.log(classes, classDetails?.classes, "useEffectclassDetails");
      const newObj = classes.find((item) => item?.id === classDetails?.classes);
      console.log(newObj, "newObj");
      let subject2 = newObj?.subjects_detail || [];
      setSubject(subject2);
      let subject = classDetails?.subject_id;

      let grade = newObj?.grade;
      // setSelectGrade(grade);
      forEditchapterApi(subject, grade);
    }
  }, [classDetails?.id]);

  const forEditchapterApi = (subject, grade) => {
    console.log(subject, grade, "forEditchapterApi");
    api_token
      .get(`cms/v1/chapter/?subject=${subject}&grade=${grade}&page_size=20`)
      .then((res) => {
        setChapters(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const OpenMessage = () => {
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }

    const { classes, teacher, ...rest } = classDetails;

    const data = {
      classes: [classes],
      teacher: [teacher],
      ...rest,
    };

    delete data?.id;

    let checkData = {
      date: data.start,
      commence: data.commence,
      conclude: data.conclude,
      class_id: data.classes,
      teacher_id: data.teacher,
    };

    for (let key in checkData) {
      if (checkData[key] === null || checkData[key] === undefined || checkData[key] === "") {
        delete checkData[key];
      } else if (Array.isArray(checkData[key]) && checkData[key].length === 1) {
        checkData[key] = checkData[key][0]; // Extract the single element from array
      }
    }
    api_token
      .post(`calendar/v1/events/validate_event/`, checkData)
      .then((res) => {
        const result = res.data.data[0];
        setOpen(false);
        setMessage(result);
        setOpenDialog(true);
      })
      .catch((err) => { });
  };

  const SubmitData = () => {
    let obj = { ...classDetails };
    for (let key in obj) {
      if (obj[key] === null) {
        delete obj[key];
      }
    }

    console.log(obj, "let obj");
    const { classes, teacher, ...rest } = classDetails;

    const data = {
      classes: [classes],
      teacher: [teacher],
      ...rest,
    };

    delete data?.id;

    let checkData = {
      date: data.start,
      commence: data.commence,
      conclude: data.conclude,
      class_id: data.classes,
      teacher_id: data.teacher,
    };

    for (let key in checkData) {
      if (
        checkData[key] === null ||
        checkData[key] === undefined ||
        checkData[key] === ""
      ) {
        delete checkData[key];
      } else if (Array.isArray(checkData[key]) && checkData[key].length === 1) {
        checkData[key] = checkData[key][0]; // Extract the single element from array
      }
    }

    let holidays =
      eventType &&
      eventType.length > 0 &&
      eventType?.filter((data) => data?.title == "Exams");
    data.event_type_id = holidays[0]?.id;

    if (classDetails?.id) {
      console.log(classDetails?.id, data, "hitpatch ");

      api_token
        .patch(`calendar/v1/events/${classDetails?.id}/`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            let mnth;
            let yrs;
            const { month, year } = currenctDate;
            mnth = month + 1;
            yrs = year;
            getListing(mnth, yrs);
            setOpen(false);
            setOpenDialog(false)
            setClassDetail({});
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log(classDetails?.id, data, "hitpatch ");

      api_token
        .post(`calendar/v1/events/create_events/`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            let mnth;
            let yrs;
            const { month, year } = currenctDate;
            mnth = month + 1;
            yrs = year;
            getListing(mnth, yrs);
            setOpen(false);
            setOpenDialog(false)
          }
        })
        .catch((err) => console.log(err));
    }
    // api_token
    // .post(`calendar/v1/events/create_events/`, data)
    // .then((res) => {
    //   if (res.status == 200 || res.status == 201) {
    //     let mnth;
    //     let yrs;
    //     const { month, year } = currenctDate;
    //     mnth = month + 1;
    //     yrs = year;
    //     getListing(mnth, yrs);
    //     setOpen(!open)
    //   }
    // })
    // .catch((err) => console.log(err));
  };

  const handleAddNewSubject = () => {
    setOpenDialog(false);
  };
  let holidays =
    eventType &&
    eventType.length > 0 &&
    eventType?.filter((data) => data?.title == "Exams");
  console.log(calendarData, "calendarData");
  return (
    <div>
      <div className={styles.mainData}>
        <Button variant="contained" onClick={handleClicked}>
          Add Exam
        </Button>
      </div>
      <div>
        <CustomCalendar
          calendarData={calendarData}
          setDateTime={setDateTime}
          onUpdateCalendarData={handleCalendarDataUpdate}
        />
      </div>

      <div>
        <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
          <div className={styles.drawerContain}>
            <h2>Add Exam</h2>
            <div className={styles.textData}>
              <TextField
                name="title"
                label="Title"
                style={{ width: "100%" }}
                value={classDetails?.title}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                name="description"
                label="Description"
                style={{ width: "100%" }}
                value={classDetails?.description}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="start"
                label="start date"
                value={classDetails?.start}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="date"
                style={{ width: "100%" }}
                name="end"
                label="end date"
                value={classDetails?.end}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="commence"
                label="start time"
                value={classDetails?.commence}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className={styles.textData}>
              <TextField
                type="time"
                style={{ width: "100%" }}
                name="conclude"
                label="end time"
                value={classDetails?.conclude}
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event for</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_for"
                  value={classDetails?.event_for}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  <MenuItem value={1}>All</MenuItem>
                  <MenuItem value={2}>Student</MenuItem>
                  <MenuItem value={3}>Teacher</MenuItem>
                  {/* Add more options as needed */}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Class</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="classes"
                  value={classDetails?.classes}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {classes &&
                    classes.map((v, i) => (
                      <MenuItem value={v.id} onClick={() => addSubjects(v)}>
                        {v.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Subject</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  // value={selectedOption}
                  value={classDetails?.subject_id}
                  onChange={handleSubject}
                >
                  {subjects &&
                    subjects.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Chapter</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="chapter_id"
                  value={classDetails?.chapter_id}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {chapters &&
                    chapters.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Type</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_type_id"
                  disabled
                  value={holidays[0]?.id}
                  // value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {eventType &&
                    eventType.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Event Category</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="event_category_id"
                  value={classDetails?.event_category_id}
                  // value={events[0]?.id}
                  onChange={handleDropdownChange}
                >
                  {eventCategory &&
                    eventCategory.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div style={{ padding: "10px" }}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel id="dropdown2-label">Teacher</InputLabel>
                <Select
                  labelId="dropdown2-label"
                  id="dropdown2"
                  name="teacher"
                  value={classDetails?.teacher}
                  // value={selectedOption}
                  onChange={handleDropdownChanges}
                >
                  {teacherList &&
                    teacherList.map((v, i) => (
                      <MenuItem
                        value={v?.user?.id}
                      >{`${v?.user?.first_name} ${v?.user?.last_name}`}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ padding: "10px" }}>
              <Button onClick={() => OpenMessage()} variant="contained">
                Submit
              </Button>
            </div>
          </div>
        </DrawerComp>
      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>{message?.message}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={SubmitData}>Override and schedule</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Exams;
