import React, { useState } from "react";
import styles from "./ContentPptTopic.module.css";
import sub_1 from "../../Assets/ContentMangement/ppt/sub_1.png";
import deleteSub from "../../Assets/ContentMangement/ppt/deleteSub.png";
import editSlideImg from "../../Assets/ContentMangement/ppt/editSlideImg.png";
import replacePpt from "../../Assets/ContentMangement/ppt/replacePpt.svg";

import emptySlides from "../../Assets/ContentMangement/ppt/emptySlides.png";
import videoSlide from "../../Assets/ContentMangement/ppt/videoSlide.png";
import gif from "../../Assets/ContentMangement/ppt/gif.png";
import Carousel from "react-multi-carousel";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";



const ContentPptTopic = (props) => {
  const { topic, onEditSlideImg, onDeleteSlideImg, setOpenFullScreen, getEbookData } = props;
  // const [activeSubtopic, setActiveSubTopic] = useState(topic?.subtopics[0]);
  const navigate = useNavigate();
  const handleSelectSubTopic = (subtopic) => {
    // setActiveSubTopic(subtopic);
  };
  console.log(topic, "activeSubtopic");


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  // console.log(activeSubtopic, "activeSubtopic")
  const handelPPTview = (id) => {
    navigate(`/dashboard/content/ppt/view/${id}`,
      // {state: {subtopic : activeSubtopic}} 
    )
  }
  const handleChangeToEdit = (selectedChapter) => {
    // handleChangeItem(selectedChapter);
    navigate(`/dashboard/content/ppt/edit`, { state: topic });
  };

  return (
    <div style={{ width: "400px" }}>

      {/* {
        topic?.topics?.length > 0 &&

        (<> */}
      {/* <h3 style={{ fontSize: "25px", fontWeight: "500" }}>{topic?.title}</h3> */}
      <div style={{ width: "400px", position: 'relative' }}>
        <img onClick={() => { handelPPTview(topic?.id) }} width={"100%"} style={{ borderRadius: "10px" }} src={topic?.thumbnail} alt="ppt" />
        { 
         topic?.provider !== 1
             && (
            <EditIcon
            onClick={() => handleChangeToEdit(topic)}
              className={styles.editIconsty}
            />
          )
        }
      </div>

      {/* </>)
      } */}

      {/* {topic?.subtopics?.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "30px",
            marginBottom: "20px",
          }}
          className={styles.contentCode}
        >
          {topic?.subtopics?.map((subtopic, k) => {
            console.log(subtopic, "subtopic");

            return (
              <div>
                <div
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      subtopic?.id == activeSubtopic?.id
                        ? "#ED388F"
                        : "transparent",
                    color:
                      subtopic?.id == activeSubtopic?.id ? "white" : "black",
                    padding: "10px",
                    border: "1px solid #ED388F",
                    borderRadius: "10px",
                    fontWeight: "500",
                    minWidth: "100px",
                    fontSize: "20px",
                    whiteSpace: 'nowrap'
                  }}
                  key={subtopic?.id}
                  onClick={() => handleSelectSubTopic(subtopic)}
                >
                  {subtopic?.title}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {activeSubtopic?.slides?.length > 0 ? (
       
       
      //  <div
      //     style={{
      //       display: "flex",
      //       gap: "3%",
      //       flexWrap: "wrap",
      //       width: "100%",
      //     }}
      //   >
      <div style = {{width : "100%"}}>
         
                <Carousel
                  swipeable={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  responsive={responsive}
                >
          {activeSubtopic?.slides?.map((slide, l) => {
            console.log(slide, "activeSlide");
            return (
              <div
                style={{
                  width: "95%",
                  position: "relative",
                  marginBottom: "20px",
                  cursor : "pointer"
                }} 
                // onClick = {(e) => setOpenFullScreen(slide,e)}
                  onClick={() => handelPPTview(activeSubtopic?.id)}
              >
                <img
                  src={slide?.thumbnail ? slide?.thumbnail : sub_1}
                  style={{ width: "100%", borderRadius: "20px" }}
                  key={l}
                />
                {slide?.is_file_str == "Video" && (
                  <img
                    src={videoSlide}
                    style={{
                      position: "absolute",
                      bottom: "50%",
                      left: "50%",
                      right: "50%",
                      left: "50%",
                    }}
                  />
                )}
                {slide?.is_file_str == "GIF" && (
                  <img
                    src={gif}
                    style={{
                      position: "absolute",
                      bottom: "50%",
                      left: "50%",
                      right: "50%",
                      left: "50%",
                    }}
                  />
                )}
                <div
                  style={{
                    position: "absolute",
                    bottom: "8%",
                    left: "80%",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  {slide.provider !== 1 && <img
                    src={replacePpt}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => onEditSlideImg(slide,e, getEbookData)}
                  />}
                  {slide.provider !== 1 && <img
                    src={deleteSub}
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => onDeleteSlideImg(slide,e)}
                  />}
                </div>
              </div>
            );
          })}
          </Carousel>
        </div>

      ) : (
        <div>
        <div style={{ textAlign: "center" }}>
          <img src={emptySlides} style={{ width: "30%", height: "30%" }} />
         
        </div>
         <p style = {{marginTop : "0px", marginBottom : "20px", fontSize : "20px"}}>No Slides Present</p>
         </div>
      )} */}
    </div>

  );
};

export default ContentPptTopic;
