import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import styles from "./index.module.css";
import videoImg from "../../Assets/ContentMangement/mindmap/number.png";
import videoDecimal from "../../Assets/ContentMangement/mindmap/Decimal.png";
import matrics from "../../Assets/ContentMangement/mindmap/matrix.png";
import edfive from "../../Assets/ContentMangement/mindmap/Ed5.png";
import cnm from "../../Assets/ContentMangement/mindmap/cnm.png";
import Carousel from "react-multi-carousel";
import Tens from "../../Assets/ContentMangement/mindmap/Ten.png";
import "react-multi-carousel/lib/styles.css";
import CustomProgress from "../../Components/CustomProgress/CustomProgress";
import Pdf from "../../pdf/Management Process & Organizational Behavior.pdf";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import addSubTopic from "../../Assets/ContentMangement/mindmap/addSubTopic.svg";
import deleteSubTopic from "../../Assets/ContentMangement/mindmap/deleteSubTopic.svg";
import { api_token, base_url, _access_token } from "../../Utils/Network";
import DrawerComp from "../../Components/DrawerComp/DrawerComp";
import {
  Alert,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import InputField from "../../Components/Input/InputField";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { Snackbar } from "@material-ui/core";
import Files from "../../File/mindmap.xlsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import drawerBack from "../../Assets/ContentMangement/ppt/drawerBack.png";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

function ContentMindMap(props) {
  const [bookId, setBookId] = useState(null);
  const [topicList, setTopicList] = useState([]);
  const { content_selection } = useContext(UserCredsContext);
  const [mindMapTableData, setMindMapTableData] = useState([]);
  const [subtopicList, setSubtopicList] = useState([]);
  const [getMindMap, setMindMap] = useState([]);
  console.log(getMindMap, "getMindmappp");
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [chapterFilter, setChpFilter] = useState(null);
  const observer = useRef();

  const [drawerState, setDrawerState] = useState("");
  const [submitValue, setSubmitValue] = useState({
    id: "",
    subject_id: content_selection.subject,
    board_id: content_selection.board,
    grade_id: content_selection.grade,
    language_id: 1,
    chapter_id: null,
    // topic: null,
    provider: 2,
  });

  const [filterObj, setFilterObj] = useState({
    subject: content_selection?.subject,
    grade: content_selection?.grade,
    board: content_selection?.board,
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  const [postSubData, setPostSubData] = useState({
    mindmap_id: "",
    subtopic_id: null,
    start_number: "",
    end_number: "",
  });
  const [postSubDataErrors, setPostDataErrors] = useState({});
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const [refId, setRefId] = useState([]);
  const { vertical, horizontal } = alertDetails;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [openMindmapPopup, setOpenMindmapPopup] = useState(false);
  const [deletedMindmap, setDeletedMindmap] = useState({});

  const [showingData, setShowingData] = useState("mindmapListing");
  const [mindmapPdf, setMindmapPdf] = useState({});
  //Mindmap table data basing on book id....
  const getMindMapTableData = (id) => {
    api_token
      .get(`content/v1/mindmap_subtopic/?mindmap=${id}`)
      .then((response) => {
        setMindMapTableData(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  //Topic List basing on chapter id....
  const getTopicData = (chapter_id) => {
    api_token
      .get(`cms/v1/topic?chapter=${chapter_id}`)
      .then((response) => {
        setTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Subtopic List basing on topic id...
  const getSubTopicList = (topicId) => {
    api_token
      .get(
        `content/v1/book_subtopic/listing_subtopic_on_topic/?topic_id=${topicId}`
      )
      .then((response) => {
        setSubtopicList(response?.data?.data);
      })

      .catch((error) => {
        console.log(error, "error");
      });
  };

  useEffect(() => {
    getMindMapData();
  }, []);

  const getMindMapData = (paramObj = { ...filterObj }, list = []) => {
    api_token
      .get(`content/v1/chapter_wise_mindmaps/`, { params: paramObj })
      .then((res) => {
        // setMindMap(res.data.data);

        if (res?.data?.next_page) paramObj.page = res?.data?.next_page;
        else delete paramObj.page;
        setMindMap([...list, ...res.data.data]);
        setFilterObj(paramObj);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const newDataOpen = (filelink) => {
    console.log(filelink, "filelink");
    setMindmapPdf(filelink);
    setShowingData("mindmapPdf");
    // window.open(filelink, "_blank");
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    setSubmitValue((prev) => ({ ...prev, [name]: value }));
    if (name == "chapter_id") {
      let chp = getMindMap.findIndex((x) => {
        return x.id == value;
      });
      setChpFilter(chp);
    }
    if (name == "topic") {
      getSubTopicList(value);
    }
  };

  const openBulk = () => {
    setOpenDialog(true);
  };

  //newData function will call during open drawer....
  const newData = (data) => {
    const {
      id,
      language: language_id,
      provider,
      subject: subject_id,
      chapter: chapter_id,
      topic_v2: topic,
      board: board_id,
    } = data;
    setSubmitValue({
      id,
      language_id,
      provider,
      subject_id,
      chapter_id,
      // topic,
      board_id,
    });
    let chp = getMindMap.findIndex((x) => {
      return x.id == chapter_id;
    });
    setChpFilter(chp);
    setPostSubData({ ...postSubData, mindmap_id: id });
    setOpen(true);
    getTopicData(chapter_id);
    getMindMapTableData(id);
    getSubTopicList(topic);
    setBookId(id);
    setPostDataErrors({});
  };

  //Uploading Book file...
  const SubmitFile = async () => {
    let data = {
      file: file,
    };
    const formData = new FormData();
    if (data.file) formData.append("file", data.file);
    try {
      await axios({
        method: "Post",
        url: `${base_url}external/v1/sync/mindmap/`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        headers: { Authorization: `Bearer ${_access_token}` },
      })
        .then((response) => {
          // setLoading(false)
          if (response.status === 200 || response.status === 201) {
            if (response.data.data) {
              // alert("Mind Map Bulk Data Uploaded Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "Mind Map Bulk Data Uploaded Successfully",
                errorType: "success",
              });
              setFile(null);
              setOpenDialog(false);
              getMindMapData();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const submitData = async (e) => {
    let data = {
      ...submitValue,
      ebook: file,
    };
    const formData = new FormData();

    // if (data.topic) formData.append("topic", data.topic);
    if (data.chapter_id) formData.append("chapter_id", data.chapter_id);
    if (data.language_id) formData.append("language_id", data.language_id);
    if (data.grade_id) formData.append("grade_id", data.grade_id);
    if (data.subject_id) formData.append("subject_id", data.subject_id);
    if (data.board_id) formData.append("board_id", data.board_id);
    if (data.ebook) formData.append("mindmap_file", data.ebook);
    if (data.provider) formData.append("provider", data.provider);

    if (data.id === "" || data.id === undefined) {
      try {
        let { id, ...rest } = data;
        let datas = [...Object.values(rest)].every(Boolean);
        if (!datas) {
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Please Fill proper details",
            errorType: "error",
          });
          return;
        }

        await axios({
          method: "Post",
          url: `${base_url}content/v1/mindmaps/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              // alert("Mind Map Added Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "Mind Map Added Successfully",
                errorType: "success",
              });
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                language_id: 1,
                chapter_id: null,
                topic: "",
              });
              setFile(null);
              setOpen(false);
              getMindMapData();
            }
          })
          .catch((error) => {
            console.log(error, "error");
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Error Filling Data",
              errorType: "error",
            });
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios({
          method: "Patch",
          url: `${base_url}content/v1/mindmaps/${data.id}/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              // alert("Mind Map Updated Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "Mind Map Updated Successfully",
                errorType: "success",
              });
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                language_id: 1,
                chapter_id: null,
                topic: "",
              });
              setFile(null);
              setOpen(false);
              getMindMapData();
            }
          })
          .catch((error) => {
            console.log(error, "error");
          });
      } catch (error) {
        console.log(error);
        setAlertDetails({
          ...alertDetails,
          open: true,
          message: "Error Filling Data",
          errorType: "error",
        });
      }
    }
  };

  //handle change data while adding new row.....
  const handleChangeSubData = (e) => {
    const { name, value } = e.target;
    setPostSubData({ ...postSubData, [name]: value });
    setPostDataErrors({
      ...postSubDataErrors,
      [name]: "",
    });
  };

  //Post API For posting/adding new row data....

  //i)Validating post data...
  const validateForm = () => {
    const newErrors = {};

    if (postSubData.subtopic_id == "" || postSubData.subtopic_id == null) {
      newErrors.subtopic_id = "*Subtopic is required.";
    }
    if (isNaN(parseInt(postSubData.start_number, 10))) {
      newErrors.start_number = "*Start Page must be an Integer.";
    }
    if (postSubData.start_number == "" || postSubData.start_number == null) {
      newErrors.start_number = "*Start Page is required.";
    }
    if (isNaN(parseInt(postSubData.end_number, 10))) {
      newErrors.end_number = "*End Page must be an Integer.";
    }
    if (postSubData.end_number == "" || postSubData.end_number == null) {
      newErrors.end_number = "*End Page is required.";
    }

    if (
      !newErrors.start_number && // Check if there's no error for start_number
      !newErrors.end_number && // Check if there's no error for end_number
      parseInt(postSubData.start_number, 10) >
        parseInt(postSubData.end_number, 10)
    ) {
      newErrors.end_number = "*End Page must be greater than Start Page.";
    }
    setPostDataErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //ii)posting new data....
  const handlePostSubData = () => {
    if (validateForm()) {
      api_token
        .post(`content/v1/mindmap_subtopic/add_mindmapsubtopic/`, postSubData)
        .then((response) => {
          if (response?.status == "200" || response?.status == "201") {
            alert("Subtopic is uploaded Successfully!");
            getMindMapTableData(bookId);
            setPostSubData({
              mindmap_id: bookId,
              subtopic_id: null,
              start_number: "",
              end_number: "",
            });
            setPostDataErrors({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //Deleting Subtopic from the table
  const handleDeleteSubTopic = (deletedSubtopicRow) => {
    console.log(deletedSubtopicRow, "deleteShshhs");
    let data = {
      mindmap_id: deletedSubtopicRow?.mindmap?.id,
      subtopic_id: deletedSubtopicRow?.subtopic?.id,
    };

    api_token
      .post(`content/v1/mindmap_subtopic/delete_mindmapsubtopic/`, data)

      .then((response) => {
        if (
          response?.status == "200" ||
          response?.status == "OK" ||
          response?.status == "201"
        ) {
          getMindMapTableData(bookId);
          alert("Subtopic Deleted Successfully!");
          setPostDataErrors({});
        } else {
          throw new Error("erro");
        }
      })
      .catch((err) => {
        console.log("error");
      });
  };
  const lastItemRef = useCallback(
    (node) => {
      if (loading || !getMindMap.length) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && filterObj.page)
          getMindMapData(filterObj, getMindMap);
      });
      if (node) observer.current.observe(node);
    },
    [loading, filterObj.page]
  );
  //handle input change data on updating row data....
  const handleInputChange = (index, key, value, data) => {
    console.log(data, "valooooo");
    const {
      subtopic: { id },
    } = data;
    if (id) {
      setRefId([...refId, id]);
      mindMapTableData[index].subtopic[key] = value;
      setMindMapTableData([...mindMapTableData]);
    }

    // console.log(bookTableData, "")
  };

  //Updating/Patching API row data.....
  const handleUpdateSubTopic = () => {
    const uniqueArray = Array.from(new Set(refId));
    let finalPatchData = [];
    uniqueArray.map((item) => {
      mindMapTableData.forEach((sub) => {
        const {
          subtopic: { id },
        } = sub;
        if (item === id) {
          finalPatchData.push(sub);
        }
      });
    });

    for (let data of finalPatchData) {
      let apiData = {
        mindmap_subtopic_id: data?.id,
        mindmap_id: data?.mindmap?.id,
        subtopic_id: data?.subtopic?.id,
        start_number: data?.subtopic?.start_number,
        end_number: data?.subtopic?.end_number,
      };
      api_token
        .post(`content/v1/mindmap_subtopic/edit_mindmapsubtopic/`, apiData)
        .then((response) => {
          alert("Successfully Details Updated...");
          getMindMapTableData(bookId);
        })
        .catch((error) => {
          alert("Please fill proper details....");
          console.log(error);
        });
    }
    // handlePostSubData()

    if (Object.values(postSubData).every((value) => value !== null)) {
      handlePostSubData();
    }
  };

  //DELETE API FOR DELETING Mindmap....

  const handleDeleteMindmap = (info) => {
    api_token
      .delete(`${base_url}content/v1/mindmaps/${info?.id}/`)
      .then((response) => {
        if (response?.status == 204 || response?.status == 200) {
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Mindmap Deleted Successfully!",
            errorType: "success",
          });
          setOpenMindmapPopup(false);

          getMindMapData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //DELETE POPUP  FOR DELETING Mindmap....

  const handleOpenDeleteMindmapPopup = (item) => {
    setOpenMindmapPopup(true);
    setDeletedMindmap(item);
  };
  return (
    <div>
      {showingData === "mindmapListing" && (
        <div>
          <div className={styles.Ebooks_main}>
            <div className={styles.head}>MindMap</div>
            <div>
              {/* <Button onClick={() => openBulk()}>Bulk Upload</Button> */}
              <Button
                onClick={() => {
                  setOpen(true);
                  setDrawerState("addMindmap");
                }}
              >
                Add Mindmap
              </Button>
              {/* {props?.access?.writeAccess && (
            <Button onClick={() => openBulk()}>Bulk Upload</Button>
          )}
          {props?.access?.writeAccess && (
            <Button
              onClick={() => {
                setOpen(true);
                setDrawerState("addMindmap");
              }}
            >
              Add Mindmap
            </Button>
          )} */}
            </div>
          </div>
          <div>
            {getMindMap &&
              getMindMap.map((v, i) => (
                <div ref={i + 1 < getMindMap.length ? null : lastItemRef}>
                  <p className={styles.subHead}>{`${i + 1}. ${v.title}`}</p>
                  <div>
                    <Carousel
                      swipeable={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      responsive={responsive}
                    >
                      {v.mindmaps.map((info, index) => (
                        <div
                          style={{
                            position: "relative",
                            width: "95%",
                            margin: "20px",
                          }}
                        >
                          {props?.access?.updateAccess &&
                            info?.provider !== 1 && (
                              <EditIcon
                                style={{
                                  position: "absolute",
                                  top: "20px",
                                  left: "20px",
                                  zIndex: "999",
                                  fontSize: "30px",
                                  padding: "3px",
                                  background: "white",
                                  borderRadius: "50%",
                                }}
                                onClick={() => {
                                  newData(info);
                                  setDrawerState("editMindmap");
                                }}
                              />
                            )}
                          {info?.provider !== 1 && (
                            <img
                              src={deleteSubTopic}
                              style={{
                                position: "absolute",
                                top: "20px",
                                right: "20px",
                                zIndex: "999",
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleOpenDeleteMindmapPopup(info);
                              }}
                            />
                          )}

                          <div
                            key={info}
                            className={styles.cardMind}
                            onClick={() => newDataOpen(info)}
                          >
                            {/* <img src={edfive} alt="images" className={styles.imgLogo} /> */}
                            {/* <div style={{height: '240px'}}> */}
                            {info.thumbnail ? (
                              <img
                                src={info.thumbnail}
                                alt="image"
                                className={styles.imgCard}
                              />
                            ) : (
                              <img
                                src={videoImg}
                                alt="image"
                                className={styles.imgCard}
                              />
                            )}
                            {/* </div> */}
                            <div>
                              {info.topic}
                              {/* <CustomProgress value={30} /> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </Carousel>
                  </div>
                </div>
              ))}
          </div>

          <div>
            <DrawerComp open={open} anchor="right">
              {drawerState === "editMindmap" && (
                <div className={styles.BooksContainer}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ fontSize: "19px", marginBottom: "10px" }}>
                      Edit Mindmap
                    </div>
                    <div
                      onClick={() => {
                        setOpen(false);
                        setPostSubData({
                          mindmap_id: "",
                          subtopic_id: null,
                          start_number: "",
                          end_number: "",
                        });
                      }}
                    >
                      <img
                        src={drawerBack}
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <FormControl fullWidth style={{ width: "400px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Chapter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={chapterObj.chapter_id}
                        value={getMindMap[chapterFilter]?.id}
                        label="Chapter"
                        disabled
                        name="chapter_id"
                        onChange={handleData}
                      >
                        {getMindMap &&
                          getMindMap.map((v, i) => (
                            <MenuItem value={v.id}>{v.title}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    {/* <InputField
              label="Topic Name"
              value={submitValue?.topic}
              width="400px"
              name="topic"
              onChange={handleData}
            /> */}

                    <FormControl fullWidth style={{ width: "400px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Topic
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Topic"
                        value={submitValue?.topic}
                        name="topic"
                        onChange={handleData}
                      >
                        {topicList &&
                          topicList.map((topic, i) => (
                            <MenuItem value={topic.id}>{topic.title}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* <div style={{ marginBottom: "10px" }}>
            <FormControl fullWidth style={{ width: "400px" }}>
              <InputLabel id="demo-simple-select-label">Chapter</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={chapterObj.chapter_id}
                value={getMindMap[chapterFilter]?.id}
                label="Chapter"
                name="chapter_id"
                onChange={handleData}
              >
                {getMindMap &&
                  getMindMap.map((v, i) => (
                    <MenuItem value={v.id}>{v.title}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div> */}
                  <div>
                    <div>Upload Mindmap</div>
                    <input
                      type="file"
                      accept="file/*"
                      onChange={handleChange}
                    />
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead
                          sx={{ backgroundColor: "black", color: "#fff" }}
                        >
                          <TableRow>
                            {/* <TableCell
                      sx={{ color: "#fff", fontSize: "20px" }}
                      align="center"
                    >
                      Topic Name
                    </TableCell> */}

                            <TableCell
                              sx={{ color: "#fff", fontSize: "20px" }}
                              align="center"
                            >
                              Subtopic
                            </TableCell>
                            <TableCell
                              sx={{ color: "#fff", fontSize: "20px" }}
                              align="center"
                            >
                              Start Page
                            </TableCell>
                            <TableCell
                              sx={{ color: "#fff", fontSize: "20px" }}
                              align="center"
                            >
                              End Page
                            </TableCell>
                            <TableCell
                              sx={{ color: "#fff", fontSize: "20px" }}
                              align="center"
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {mindMapTableData?.length !== 0 ? (
                            <>
                              {mindMapTableData.map((data, index) => {
                                return (
                                  <TableRow>
                                    {/* <TableCell
                              align="center"
                              style={{ fontSize: "16px" }}
                            >
                              {data?.topic?.title}
                            </TableCell> */}

                                    <TableCell
                                      align="center"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <TextField
                                        id="standard-basic"
                                        // label="Standard"
                                        // variant="standard"
                                        style={{ textAlign: "center" }}
                                        value={data?.subtopic?.title}
                                        // onChange={handleEditSubTopic}
                                      />
                                      {/* {data?.subtopic?.title} */}

                                      {/* <FormControl
                                fullWidth
                                style={{ width: "400px" }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  Subtopic Name
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label="Subtopic Name"
                                  value={data?.subtopic?.id}
                                  name="subtopic_id"
                                  onChange={handleEditSubTopic}
                                  style={{ width: "100%" }}
                                >
                                  {subtopicList &&
                                    subtopicList.map((subtopic, i) => (
                                      <MenuItem value={subtopic.id}>
                                        {subtopic.title}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl> */}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {/* {data?.subtopic?.start_number} */}
                                      <TextField
                                        id="standard-basic"
                                        // label="Standard"
                                        // variant="standard"
                                        style={{ textAlign: "center" }}
                                        value={data?.subtopic?.start_number}
                                        // onChange={handleEditSubTopic}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "start_number",
                                            e.target.value,
                                            data
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ fontSize: "16px" }}
                                    >
                                      {/* {data?.subtopic?.end_number} */}
                                      <TextField
                                        id="standard-basic"
                                        // label="Standard"
                                        // variant="standard"
                                        style={{ textAlign: "center" }}
                                        value={data?.subtopic?.end_number}
                                        // onChange={handleEditSubTopic}
                                        onChange={(e) =>
                                          handleInputChange(
                                            index,
                                            "end_number",
                                            e.target.value,
                                            data
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      style={{ fontSize: "16px" }}
                                    >
                                      <img
                                        src={deleteSubTopic}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          handleDeleteSubTopic(data);
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                              <TableRow>
                                {/* <TableCell
                          align="center"
                          style={{ fontSize: "16px" }}
                        >
                          {bookId}
                        </TableCell> */}

                                <TableCell
                                  // align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  <FormControl
                                    fullWidth
                                    error={
                                      postSubDataErrors.subtopic_id && true
                                    }
                                  >
                                    <InputLabel id="demo-simple-select-label">
                                      Subtopic Name
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label="Subtopic Name"
                                      name="subtopic_id"
                                      value={postSubData?.subtopic_id}
                                      // onChange={(topic) => {
                                      //     handleSelect(topic)

                                      // }}
                                      onChange={handleChangeSubData}
                                      style={{ width: "100%" }}
                                    >
                                      {subtopicList &&
                                        subtopicList.map((subtopic, i) => (
                                          <MenuItem value={subtopic.id}>
                                            {subtopic.title}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                      {postSubDataErrors.subtopic_id}
                                    </FormHelperText>
                                  </FormControl>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  <TextField
                                    error={
                                      postSubDataErrors.start_number && true
                                    }
                                    id="outlined-error-helper-text"
                                    helperText={postSubDataErrors.start_number}
                                    label="Start Page"
                                    width="100%"
                                    name="start_number"
                                    value={postSubData?.start_number || null}
                                    onChange={handleChangeSubData}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  <TextField
                                    error={postSubDataErrors.end_number && true}
                                    id="outlined-error-helper-text"
                                    label="End Page"
                                    width="100%"
                                    name="end_number"
                                    value={postSubData?.end_number || null}
                                    helperText={postSubDataErrors.end_number}
                                    onChange={handleChangeSubData}
                                  />
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ fontSize: "16px" }}
                                >
                                  <img
                                    src={addSubTopic}
                                    style={{
                                      width: "30px",
                                      height: "30px",
                                      cursor: "pointer",
                                    }}
                                    onClick={handlePostSubData}
                                  />{" "}
                                </TableCell>
                              </TableRow>
                            </>
                          ) : (
                            <TableRow>
                              <TableCell
                                // align="center"
                                style={{ fontSize: "16px" }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">
                                    Subtopic
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Subtopic Name"
                                    name="subtopic_id"
                                    value={postSubData?.subtopic_id}
                                    // onChange={(topic) => {
                                    //     handleSelect(topic)

                                    // }}
                                    onChange={handleChangeSubData}
                                    style={{ width: "100%" }}
                                  >
                                    {subtopicList &&
                                      subtopicList.map((subtopic, i) => (
                                        <MenuItem value={subtopic.id}>
                                          {subtopic.title}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </FormControl>
                                {postSubDataErrors.subtopic_id && (
                                  <p style={{ color: "red" }}>
                                    *{postSubDataErrors.subtopic_id}
                                  </p>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: "16px" }}
                              >
                                <InputField
                                  label="Start Page"
                                  width="100%"
                                  name="start_number"
                                  value={postSubData?.start_number || null}
                                  onChange={handleChangeSubData}
                                />
                                {postSubDataErrors.start_number && (
                                  <p style={{ color: "red" }}>
                                    *{postSubDataErrors.start_number}
                                  </p>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: "16px" }}
                              >
                                <InputField
                                  label="End Page"
                                  width="100%"
                                  name="end_number"
                                  value={postSubData?.end_number || null}
                                  onChange={handleChangeSubData}
                                />
                                {postSubDataErrors.end_number && (
                                  <p style={{ color: "red" }}>
                                    *{postSubDataErrors.end_number}
                                  </p>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontSize: "16px" }}
                              >
                                <img
                                  src={addSubTopic}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handlePostSubData}
                                />{" "}
                              </TableCell>
                            </TableRow>
                          )}
                          <div>
                            <Button
                              onClick={() => {
                                handleUpdateSubTopic();
                              }}
                              className={styles.btns}
                              style={{
                                alignSelf: "center",
                                backgroundColor: "blue",
                                marginBottom: "10px",
                                marginLeft: "10px",
                              }}
                            >
                              Update
                            </Button>
                          </div>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  {/* <div>
            <Button
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={() => submitData()}
            >
              Add
            </Button>
          </div> */}
                </div>
              )}

              {drawerState === "addMindmap" && (
                <div className={styles.BooksContainer}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <div style={{ fontSize: "19px", marginBottom: "10px" }}>
                      Add Mindmap
                    </div>
                    <div
                      onClick={() => {
                        setOpen(false);
                        setPostSubData({
                          book_id: "",
                          subtopic_id: null,
                          start_number: "",
                          end_number: "",
                        });
                      }}
                    >
                      <img
                        src={drawerBack}
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </div>

                  {/* <div style={{ marginBottom: "20px" }}>
                <InputField
                  label="Topic Name"
                  value={submitValue?.topic}
                  width="300px"
                  name="topic"
                  onChange={handleData}
                />
              </div> */}

                  <div style={{ marginBottom: "10px" }}>
                    <FormControl fullWidth style={{ width: "300px" }}>
                      <InputLabel id="demo-simple-select-label">
                        Chapter
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={chapterObj.chapter_id}

                        value={getMindMap[chapterFilter]?.id}
                        label="Chapter"
                        name="chapter_id"
                        onChange={handleData}
                      >
                        {getMindMap &&
                          getMindMap.map((v, i) => (
                            <MenuItem value={v.id}>{v.title}</MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <div>Select file</div>

                    <input
                      type="file"
                      accept=".pdf"
                      onChange={handleChange}
                    />
                  </div>

                  <div>
                    <Button
                      className={styles.btns}
                      onClick={() => submitData()}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              )}
            </DrawerComp>
          </div>

          <div>
            <DialogBox
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              dataSend={() => SubmitFile()}
            >
              <div>
                <h3>Upload File</h3>
                <p>
                  Get Sample File here
                  <a href={Files} download>
                    {" "}
                    Click to download
                  </a>
                </p>
                <input type="file" accept="file/*" onChange={handleChange} />
              </div>
            </DialogBox>
          </div>

          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={alertDetails.open}
            style={{ zIndex: 9999 }}
            onClose={handleClose}
            autoHideDuration={6000}
          >
            <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
              {alertDetails.message}
            </Alert>
          </Snackbar>

          <div>
            <Modal
              open={openMindmapPopup}
              // onClose={handleClose}
              aria-labelledby="feedback-modal-title"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",

                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
                className={styles.popUpCon}
                style={{
                  textAlign: "center",
                }}
              >
                <div style={{ width: "100%" }}>
                  <h3 className={styles.editHead}>
                    Are you sure you want to delete?
                  </h3>
                  <img
                    src={deletedMindmap?.thumbnail}
                    className={styles.deletedEbookImg}
                    alt="Deleted_Mindmap"
                  />
                  <div className={styles.btnsContainer}>
                    <button
                      className={styles.deleteBtn}
                      onClick={() => handleDeleteMindmap(deletedMindmap)}
                    >
                      Delete
                    </button>
                    <button
                      className={styles.cancelBtn}
                      onClick={() => setOpenMindmapPopup(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      )}

      {showingData === "mindmapPdf" && (
        <div>
          <div className={styles.topicData}>
            <ArrowBackIosNewIcon
              className={styles.arrows}
              onClick={() => setShowingData("mindmapListing")}
            />
            <div>{mindmapPdf?.title}</div>
          </div>

          <embed
            src={`${mindmapPdf?.mindmap_file}`}
            // src="http://prod-v3-api.eduaratech.com/files/books/E_06TH_CBSE_BIO_ENG_01.pdf"
            // src="https://d31j8vyj7gdpxq.cloudfront.net/data/files/content_notes/gess1ps_merged.pdf"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="900px"
            width="100%"
            toolbar="0"
            // hidecontrols="1"
          ></embed>
        </div>
      )}
    </div>
  );
}

export default ContentMindMap;

const Number = [
  {
    id: 1,
    image: videoImg,
    icons: edfive,
    title: "Decimals System Problem Solving",
  },
  {
    id: 2,
    image: matrics,
    icons: cnm,
    title: "Number System -NCERT",
  },
];

const Decim = [
  {
    id: 1,
    image: videoDecimal,
    icons: edfive,
    title: "Introduction to Number System",
  },
  {
    id: 2,
    image: Tens,
    icons: cnm,
    title: "Intoduction to Decimal System ",
  },
  {
    id: 3,
    image: videoDecimal,
    icons: edfive,
    title: "Introduction to Number System",
  },
  {
    id: 4,
    image: Tens,
    icons: cnm,
    title: "Intoduction to Decimal System ",
  },
];
