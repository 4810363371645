import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

export default function CustomAccordion({
  defaultOpen = false,
  active,
  setActive,
  keys,
  RouteModule,
  title,
  mainId,
  handleEvent,
  data,
  icon,
  disabled = false,
  ...props
}) {
  console.log(
    defaultOpen,
    keys,
    RouteModule,
    title,
    handleEvent,
    data,
    icon,
    "outpurttt"
  );
  const [open, setOpen] = React.useState(false);
  const [mainTabId, setMainTabId] = useState(mainId);
  const [dataTitle, setDataTitle] = useState("");
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  console.log(selectedIndex, "seletedIndexxx");

  //handleClick trigger whenever u clicking on parent list item.....
  const handleClick = (values) => {
    setMainTabId(values); //Dashboard, Account Management.....
    setOpen(!open);
  };

  //handleListItemClick, handleRoute trigger whenever u clicking on child list item......
  const handleListItemClick = (index) => {
    console.log(index, "indexxx");
    setSelectedIndex(index);
  };

  React.useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  const handleRoute = (item, url) => {
    // setTitle(item?.itemId)
    handleEvent(item, url);

    // setTitle(prev => item);
    // setDataTitle(prev => title)
    // console.log(titles, "ITEMs")
  };

  console.log(defaultOpen, "keykeykey");
  return (
    <>
      {RouteModule?.key === keys?.key && (
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {/* parent Item */}
          <ListItemButton onClick={() => handleClick(mainId)}>
            <ListItemIcon>
              <img src={icon} style={{ width: "30px" }} />
            </ListItemIcon>
            <div>
              <p
                style={{
                  margin: "0px",
                  width: "100%",
                  overflowWrap: "break-word",
                  whiteSpace: "initial",
                  width: "170px",
                }}
              >
                {title}
              </p>
            </div>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {data.map((item, idx) => {
                return (
                  <ListItemButton
                    disabled={(item?.disabled) ? true: false}
                    sx={{ pl: 6 }}
                    onClick={() => {
                      handleRoute(item.title, item.url);
                      handleListItemClick(item?.id);
                      setActive(item?.id);
                    }}
                    // style={(dataTitle == title && item.title == titles) ? {background: 'red'} : {}}
                    // selected={titles === title && selectedIndex === idx}
                    style={{
                      backgroundColor:
                        item?.id === active ? "#ED388F" : "white",
                      color: item?.id === active && "white",
                    }}
                  >
                    {/* <ListItemIcon>
                            <StarBorder />
                        </ListItemIcon> */}

                    {/* Child Item.... */}
                    <img
                      src={item?.id === active ? item?.activeIcon : item.icon}
                      style={{ width: "30px", marginRight: "10px" }}
                    />
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                );
              })}
            </List>
          </Collapse>
        </List>
      )}
    </>
  );
}
