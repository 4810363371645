import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import { CloseOutlined } from "@mui/icons-material";
import DialogBox from "../../Components/DialogBox/DialogBox";

function AddTeacher() {
  const navigate = useNavigate();
  // const { subjectList, classList } = useContext(UserCredsContext)
  const [teacherSelection, setTeacherSelection] = useState(null);
  const [teachers, setTeacher] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [subClass, setSubClass] = useState({ teacher: null, group: null });

  const [subjectList, setSubjectList] = useState([]);
  const [classList, setClassesList] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [shift, setShift] = useState([]);
  const [currentShift, setCurrentShift] = useState(null);

  useEffect(() => {
    getShifts();
  }, []);

  useEffect(() => {
    getTeachers();
  }, [filterObj]);

  const getShifts = () => {
    api_token
      .get(`calendar/v1/shifts`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          getSubjects();
          setShift(res.data.data);
          setCurrentShift(res.data.data[0]?.id);
          getClasses(res.data.data[0]?.id);
          setFilterObj({ ...filterObj, shift: res.data.data[0]?.id });
        }
      })
      .catch((err) => console.log(err));
  };

  const getSubjects = () => {
    // api_token.get(`/`)
    api_token.get(`/base/v1/subject/all_subjects`)
    .then((res) => {
      setSubjectList(res.data.data);
    });
  };

  const getClasses = (val) => {
    api_token
      .get(`/calendar/v1/shifts/${val}/shifts_listings_by_type?type=1`)
      .then((res) => {
        setClassesList(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getTeachers = (
    paramObj = {
      ...filterObj,
      page_size: 100,
    }
  ) => {
    api_token
      .get(`profile/v1/teachers/all_teacher_allocated_subject_classes`, {
        params: paramObj,
      })
      .then((res) => {
        setTeacher(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleShift = (val) => {
    setCurrentShift(val?.id);
    getClasses(val?.id);
    setFilterObj({ ...filterObj, shift: val?.id });
  };

  const selectTeacher = (v) => {
    setTeacherSelection(v);
    setSubClass({ teacher: null, group: null });
  };

  const addSubClasses = (teacherId, groupId) => {
    // debugger;
    console.log(groupId, "GROUPID");
    subClass.teacher = teacherId?.teacher?.id;
    subClass.group = groupId?.subject?.id;
    setSubClass({ ...subClass });
    setTeacherSelection(teacherId?.teacher?.id);
  };

  const handleAddSubject = (obj) => {
    let data = {
      tid: teacherSelection,
    };

    console.log(data, obj, "OBJ");

    if (teacherSelection) {
      // Find the teacher in the teachers array based on the teacherSelection.
      const teacher = teachers.find((t) => t.teacher.id === teacherSelection);

      if (teacher) {
        // Check if the subject is not already added to the teacher's allocated_subject_classes.
        const subjectExists = teacher.allocated_subject_classes.some(
          (group) => group.subject.id === obj.id
        );

        if (!subjectExists) {
          // Add the subject to the teacher's allocated_subject_classes.
          teacher.allocated_subject_classes.push({
            subject: obj,
            classes: [], // Initialize an empty classes array for the subject.
          });

          // Update the state to reflect the changes.
          setTeacher([...teachers]);
        }
      }
    }
  };

  const handleAddClasses = (obj) => {
    console.log(obj, "OBJ");

    if (teacherSelection && subClass.teacher && subClass.group) {
      // Find the teacher in the teachers array based on teacherSelection.
      const teacher = teachers.find((t) => t.teacher.id === teacherSelection);

      if (teacher) {
        // Find the subject in the teacher's allocated_subject_classes array.
        const subjectGroup = teacher.allocated_subject_classes.find(
          (group) => group.subject.id === subClass.group
        );

        if (subjectGroup) {
          // Check if the class is not already added to the selected subject.
          const classExists = subjectGroup.classes.some(
            (cls) => cls.id === obj.id
          );

          if (!classExists) {
            // Add the class to the selected subject.

            api_token
              .post(`profile/v1/teacher_subject_class/`, {
                teacher: teacherSelection,
                subject_id: subClass.group,
                classe_id: obj?.id,
              })
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  setFilterObj({ ...filterObj, shift: currentShift });
                }
              })
              .catch((err) => console.log(err));
            // subjectGroup.classes.push(obj);

            // console.log(subjectGroup, "SubjectGroupssss")

            // // Update the state to reflect the changes.
            // setTeacher([...teachers]);
          }
        }
      }
    }
  };

  const removeSubject = (tid, vals) => {
    // console.log(tid, vals, teachers, "teach");
    // vals?.subject?.id

    api_token
    .post(`profile/v1/teacher_subject_class/delete_teacher_subject_class/`, {teacher_id: tid, subject_id: vals?.subject?.id})
    .then((res) => {
      if(res.status == 200 || res.status == 201 || res.status == 204){
        setFilterObj({ ...filterObj, shift: currentShift });
      }
    })
    .catch((err) => console.log(err))
  };

  const removeClass = (tid, sub, cls) => {
    api_token
    .post(`profile/v1/teacher_subject_class/delete_teacher_subject_class/`, {teacher_id: tid, subject_id: sub?.subject?.id, classe_id: cls})
    .then((res) => {
      if(res.status == 200 || res.status == 201 || res.status == 204){
        setFilterObj({ ...filterObj, shift: currentShift });
      }
    })
    .catch((err) => console.log(err))
  }

  const GotoNext = () => {
    navigate(`/onboard/addChapters`);
  };

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.teacherContainer}>
          {shift &&
            shift.map((v, i) => (
              <div
                onClick={() => handleShift(v)}
                className={`${styles.btnColor} ${
                  currentShift === v?.id ? styles?.btnSelected : ``
                }`}
              >
                {v?.title}
              </div>
            ))}
        </div>
        <div className={styles.teacherContainer}>
          <div className={styles.teacherBox}>
            <div className={styles.fontData}>Teacher</div>
            <div>
              {teachers &&
                teachers.map((v, i) => (
                  <div>
                    {
                      <div
                        className={
                          v?.teacher?.id == teacherSelection
                            ? styles.selectedTeacher
                            : ""
                        }
                        style={{ marginTop: "20px" }}
                      >
                        <div
                          onClick={() => selectTeacher(v?.teacher?.id)}
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginBottom: "10px",
                            cursor: "pointer",
                            marginLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {v?.teacher?.user?.first_name}
                          <div style={{
                              width: "40%",display: 'flex'}}>
                          {v?.teacher?.fitness_check}%
                          <div
                            style={{
                              width: "100%",
                              height: "20px",
                              border: `1px solid ${
                                v?.teacher?.fitness_check > 70
                                  ? "green"
                                  : "#e02323"
                              }`,
                              borderRadius: "12px",
                              overflow: "hidden",
                              margin: "0 10px",
                            }}
                          >
                            <div
                              style={{
                                width: `${v?.teacher?.fitness_check}%`,
                                padding: "20px 0",
                                backgroundColor: `${
                                  v?.teacher?.fitness_check > 70
                                    ? "green"
                                    : "#e02323"
                                }`,
                              }}
                            ></div>
                          </div>
                          </div>
                          
                        </div>
                        <div className={styles.groupingBox}>
                          {v?.allocated_subject_classes.length > 0 &&
                            v?.allocated_subject_classes.map((values, idx) => (
                              <div
                                onClick={() => addSubClasses(v, values)}
                                className={
                                  subClass?.teacher == v?.teacher?.id &&
                                  subClass?.group == values?.subject?.id
                                    ? styles.selectSubjectContainer
                                    : styles.subjectContainer
                                }
                              >
                                <CloseOutlined
                                  style={{
                                    position: "absolute",
                                    right: "0",
                                    fontSize: "14px",
                                    cursor: "pointer",
                                  }}
                                    onClick={() =>
                                      removeSubject(v?.teacher?.id, values)
                                    }
                                />
                                <div
                                  className={
                                    subClass?.teacher == v?.teacher?.id &&
                                    subClass?.group == values?.subject?.id
                                      ? styles.selectedgroupTitle
                                      : styles.groupTitle
                                  }
                                >
                                  {values?.subject?.title}
                                </div>
                                {values?.classes?.map((vals, i) => (
                                  <div
                                    className={styles.subGrouping}
                                    key={vals.id}
                                  >
                                    {vals.title}{" "}
                                    <CloseOutlined
                                      style={{
                                        fontSize: "14px",
                                        marginLeft: "10px",
                                        cursor: "pointer",
                                      }}
                                        onClick={() =>
                                          removeClass(
                                            v?.teacher?.id,
                                            values,
                                            vals.id
                                          )
                                        }
                                    />{" "}
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    }
                    {/* {(singleteacher == i) && <div style={{ textAlign: 'right', marginTop: '20px' }}><buttom onClick={() => handleClick(i + 1)} className={styles.nextButton}>Submit</buttom></div>} */}
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.teacherBox}>
            <div>
              <div className={styles.fontData}>Subjects</div>
              <div className={styles.subjectMain}>
                {subjectList &&
                  subjectList.map((v, i) => (
                    <div
                      className={styles.subjectBox}
                      onClick={() => handleAddSubject(v)}
                      // onClick={() => handleAddSubject(v)}
                    >
                      {v.title}
                    </div>
                  ))}
              </div>
            </div>
            <div>
              <div className={styles.fontData}>Classes</div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {classList &&
                  classList.map((v, i) => (
                    <div
                      className={styles.subjectBox}
                      onClick={() => handleAddClasses(v)}
                    >
                      {v.title}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoNext()}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddTeacher;
