import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import styles from "./index.module.css";
import universityIcon from "../../Assets/Assignment/universityIcon.png";
import ed5Logo from "../../Assets/ed5Logo.png"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AssignmentCard from "../../Components/AssignmentCard/AssignmentCard";
import Vector from "../../Assets/Assignment/Vector.png";
import University from "../../Assets/Assignment/universityIcon.png";
import { UserCredsContext } from '../../ContextApi/UserCredsContext/UserCredsContext';
import { Button } from "@mui/material";
import { api_token, base_url, _access_token } from "../../Utils/Network";
import { useNavigate } from "react-router-dom";
import DialogBox from "../../Components/DialogBox/DialogBox";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import axios from "axios";
import Files from "../../File/mcq_questions.xlsx";

function ContentTest({access}) {
  let navigate = useNavigate();
  const [testData, setTestData] = useState([]);
  const { content_selection } = useContext(UserCredsContext);
  const [datas, setData] = useState(0);
  const [assignList, setAssignmentList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [chapterId, setChapterId] = useState(null)
  const observer = useRef();
  const [filterObj, setFilterObj] = useState({
    subject: content_selection?.subject,
    grade: content_selection?.grade,
    board: content_selection?.board,
    test_format: 1,
    page: 1,
    });
    const [loading, setLoading] = useState(false);



  useEffect(() => {
    getTest();
  }, []);


  const getTest = (paramObj = { ...filterObj }, list = []) => {
    api_token
      // .get(`cms/v1/chapter_test_listing/?board=${content_selection.board}&grade=${content_selection.grade}&subject=${content_selection.subject}`)
      .get(`cms/v1/chapter_test/`, { params: paramObj })
      .then((res) => {
        // setTestData(res.data.data);

        if (res?.data?.next_page) paramObj.page = res?.data?.next_page;
        else delete paramObj.page;
        setTestData([...list, ...res.data.data]);
        setFilterObj(paramObj);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
  }


  const openBulk = () => {
    setOpenDialog(true);
  }

  const SubmitFile = async () => {

    let data = {
      file: file,
    }
    const formData = new FormData();
    if (data.file) formData.append('file', data.file)
    try {

      await axios({
        method: "Post",
        url: `${base_url}external/v1/sync/mcq_questions/`,
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
        headers: { 'Authorization': `Bearer ${_access_token}` },
      })
        .then(response => {
          // setLoading(false)
          console.log(response, "rRRRRRRR")
          if (response.status === 200 || response.status === 201) {
            if (response.data.data) {
              alert("MCQ Bulk Data Uploaded Successfully")
              setFile(null);
              setOpenDialog(false);
              getTest();
            }
          }
        })
        .catch(error => {
          console.log(error, 'error');
        })
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = e => {
    setFile(e.target.files[0]);
  };

  const viewAssignList = (vals, num) => {
    console.log(vals, "valsssss")
    setChapterId(vals?.id)
    setAssignmentList(vals?.test);
    setData(num);
  }

  const redirectTo = () => {
    navigate('/dashboard/content/test/createTest')
  }

  const goToCreateAssignment = (vals, chapterId) => {
    navigate(`/dashboard/content/test/addQuestion/${vals.id}`, { state: vals })
  }

  const lastItemRef = useCallback(
    (node) => {
      if (loading || !testData.length) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && filterObj.page)
        getTest(filterObj, testData);
      });
      if (node) observer.current.observe(node);
    },
    [loading, filterObj.page]
  );
  console.log(testData, "TestData")
  return (
    <div>
      <div className={styles.Container}>
        {/* <div className={styles.headerTitle}>
                <h3 className={styles.title}>ICSE  | </h3>
                <h3  className={styles.title}   >Physics |</h3>
                <h3   className={styles.title} >Grade VI</h3>
            </div>  */}

        <div className={styles.assign_data}>
          <div className={styles.head}>MCQ</div>
          {/* <div className={styles.assignment}>
            <KeyboardArrowLeftIcon className={styles.iconStyles} />
            <p className={styles.iconStyles}> November 2022</p>
            <KeyboardArrowRightIcon className={styles.iconStyles} />
          </div> */}
          <div>
          {access?.writeAccess && <Button onClick={() => openBulk()}>Bulk Upload</Button>}

{access?.writeAccess && <Button onClick={() => redirectTo()}>Create MCQ</Button>}
          </div>
        </div>

        {(datas == 0) && <div className={styles.AssignmentcardCon}>
          {testData && testData.map((v, i) => (
            <div className={styles.container} ref={i + 1 < testData.length ? null : lastItemRef} onClick={() => { (v.test.length > 0) ? viewAssignList(v, 1) : alert("No Assignment") }}>
              <div className={styles.cardContainer} style={{ justifyContent: 'space-between' }}>
                <div className={styles.cardContainer} >
                  <div className={styles.imageContainer}>
                    {(v.test[0]?.thumbnail) ? <img className={styles.mainImage} src={v.test[0]?.thumbnail} /> : <img className={styles.mainImage} src={ed5Logo} />}
                  </div>
                  <div className={styles.infoflexCon}>
                    <div className={styles.infoContainer}>
                      <div>
                        <span className={styles.infoHeader}>
                          {v.title}
                        </span>
                        <p>{v?.school?.description}</p>
                      </div>
                    </div>


                  </div>
                </div>

                {/* <div className={styles.iconContainer}>
                  <img src={Vector} />
                </div> */}
              </div>
            </div>
          ))}
        </div>}

        {datas == 1 &&
          <div>
            <div className={styles.arrowBack} onClick={() => setData(0)}> <ArrowBackIosIcon /> Back</div>
            <div>
              {
                assignList.length > 0 && assignList.map((vals, index) => (
                  <div key={index} className={`${styles.subAssignment} ${styles.assignmentList}`}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <img src={(vals?.thumbnail) ? vals?.thumbnail : ed5Logo} alt="university" style={{ width: '60px' }} />
                      </div>
                      <div style={{ marginLeft: '20px' }}>
                        <div className={styles.fontTitle}>{vals.title}</div>
                        <div className={styles.questionData}>
                          <div >Total Marks: {vals.total_marks}</div>
                          <div>Total Question: {vals.total_questions}</div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <Button onClick={() => goToCreateAssignment(vals, chapterId)}>View MCQ</Button>
                    </div>
                  </div>
                ))
              }

            </div>
          </div>
        }

      </div>

      <div>
        <DialogBox open={openDialog} onClose={() => setOpenDialog(false)} dataSend={() => SubmitFile()}>
          <div>
            <h3>Upload File</h3>
            <p>Get Sample File here
              <a href={Files} download> Click to download</a>
            </p>
            <input type="file" accept='file/*' onChange={handleChange} />
          </div>
        </DialogBox>
      </div>
    </div>
  )
}

export default ContentTest