import React, { useState, useEffect } from "react";
import {
  api_token,
  base_url,
  formDataApi,
  _access_token,
} from "../../Utils/Network";

import { useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "@material-ui/core";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import InputField from "../../Components/Input/InputField";
import ContentPPtEditSlideImg from "../../Components/ContentPPtEditSlideImg/ContentPPtEditSlideImg";
import styles from "./ContentPptEdit.module.css";
import UploadPPTSuccess from "../../Components/UploadPPTSuccess/UploadPPTSuccess";
import PPTSlideDelete from "../../Components/PPTSlideDelete/PPTSlideDelete";
import newDelSub from "../../Assets/ContentMangement/ppt/newDelSub.png";
import addImage from "../../Assets/ContentMangement/ppt/addImage.png";
import emptySlides from "../../Assets/ContentMangement/ppt/emptySlides.png";
import addImgCon from "../../Assets/ContentMangement/ppt/addImgCon.png";

function ContentPPtEdit(props) {
  const location = useLocation();
  let navigate = useNavigate();
  const { title, topic, id , chapter } = location?.state;
  const [filterObj, setFilterObj] = useState({
    chapter: chapter,
  });
  
  const [topicList, setTopicList] = useState([]); //Topic List......
  const [subtopicList, setSubtopicList] = useState([]); //Subtopic List.....
  const [slideData, setSlideData] = useState([]); //Slide data........

  const [openSuccessDialog, setOpenSuccessDialog] = useState(false); //success message after successful save....
  const [deleteImg, setOpenDeleteImg] = useState(false); //Delete slide dailogue box......

  const [selectedSlideImage, setSelectedSlideImage] = useState([]);

  const [deletedImg, setDeletedImg] = useState();
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });

  const { vertical, horizontal } = alertDetails;

  useEffect(() => {
    getTopicData();
    getSubtopicData();
  }, []);

  const handleClikSave = () => {
    //Make post api call here ....If successfull then show     setOpenSuccessDialog(true);....
    setOpenSuccessDialog(true);
    setTimeout(() => {
      setOpenSuccessDialog(false);
      navigate(`/dashboard/content/ppt/view`);
    }, 2000);
  };

  const handleClickDiscard = () => {
    navigate(`/dashboard/content/ppt/view`);
  };

  //Initial Api calling for getting topic data....
  const getTopicData = () => {
    api_token
      .get(`cms/v1/topic?chapter=${chapter}`)
      .then((response) => {
        setTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Initial Api calling for getting subtopic data....
  const getSubtopicData = () => {
    api_token.get(`/content/v1/subtopic_v2?chapter=${chapter}`).then((response) => {
      setSubtopicList(response?.data?.data);
    });
  };

   //Api calling for getting subtopic data after selecting topic....
   const getIndvidualSubtopics = (topicId) => {
    api_token
      .get(`/content/v1/subtopic_v2?topic=${topicId}`)
      .then((response) => {
        setSubtopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  

 
  //Getting slides data.....Have to check this one......

  const getSlideData = (obj) => {
    if (obj?.topic && obj?.subtopic && obj?.chapter) {
      api_token
        .get(
          `/content/v1/ppt_slides?topic=${obj?.topic}&subtopic=${obj?.subtopic}&chapter=${obj?.chapter}`
        )
        .then((response) => {
          setSlideData(response?.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Select Topic and Subtopic is mandatory....");
    }
  };

  const openDeleteImgDialogue = (item) => {
    setDeletedImg(item);
    setOpenDeleteImg(true);
  };


  //Deleting api......
  const deleteSlideFromSubTopic = (deleteSlide) => {
    api_token
      .delete(`/content/v1/ppt_slides/${deleteSlide?.id}/`)

      .then((response) => {
        console.log(response, "responseee");

        if (response?.data?.data) {
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "PPT Slide Deleted Successfully!",
            errorType: "success",
          });
          getSlideData();
          setOpenDeleteImg(false);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [...selectedSlideImage];

    for (let i = 0; i < files.length; i++) {
      newImages.push(files[i]);
    }
    console.log(newImages, "newImages");
    setSelectedSlideImage(newImages);
  };
  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <div>
      <div className={styles.mainContainer}>
        <h1>Edit PPT</h1>
        <div
          style={{
            marginLeft: "auto",
            gap: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => handleClickDiscard()}
            style={{
              color: "#ED388F",
              backgroundColor: "transparent",
              borderRadius: "10px",
              width: "100px",
              cursor: "pointer",
              border: "1px solid #ED388F",
            }}
          >
            Discard
          </Button>
          <Button
            onClick={() => handleClikSave()}
            style={{
              color: "white",
              backgroundColor: "#ED388F",
              borderRadius: "10px",
              width: "100px",
              cursor: "pointer",
            }}
          >
            Save
          </Button>
        </div>
      </div>

      <div className={styles.inputfieldContainer}>
        <div style={{ width: "45%" }}>
          <InputField
            style={{ background: "white", width: "100%", marginBottom: "15px" }}
            label="Chapter Name"
            value={title}
            size="normal"
            type="text"
          />
        </div>
        <div style={{ width: "45%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Topic Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Topic Name"
              onChange={(topic) => {
                let topic_id = topic.target.value;

                let obj = filterObj;
                if (topic_id) {
                  obj.topic = topic_id;
                  getIndvidualSubtopics(topic_id);
                }
                getSlideData(obj);
              }}
              style={{ width: "100%" }}
            >
              {topicList &&
                topicList.map((topic, i) => (
                  <MenuItem value={topic.id}>{topic.title}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "45%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Subtopic Name</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Subtopic Name"
              onChange={(subtopic) => {
                let subtopic_id = subtopic.target.value;
                let obj = filterObj;
                if (subtopic_id) {
                  obj.subtopic = subtopic_id;
                }
                getSlideData(obj);
              }}
              style={{ width: "100%" }}
            >
              {subtopicList &&
                subtopicList.map((subtopic, i) => (
                  <MenuItem value={subtopic.id}>{subtopic.title}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "45%" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">File Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="File Type"
              // onChange={(subtopic) => {
              //   let subtopic_id = subtopic.target.value;
              //   let obj = filterObj;
              //   if (subtopic_id) {
              //     obj.subtopic = subtopic_id;
              //   }
              //   getSlideData(obj);
              // }}
              style={{ width: "100%" }}
            >
              {fileTypeData &&
                fileTypeData.map((file, index) => (
                  <MenuItem value={file.id}>{file.file_type}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={{ marginTop: "40px" }}>
        {slideData?.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "3%",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {slideData?.map((slide, index) => {
              return (
                <div
                  className={styles.mincontainer}
                  style={{ width: "30%", marginBottom: "20px" }}
                  key={index}
                >
                  <img
                    src={slide?.thumbnail}
                    className={styles.image}
                    style={{ width: "100%", borderRadius: "20px" }}
                  />
                  <div
                    className={styles.middle}
                    style={{ width: "80px", height: "80px" }}
                    onClick={() => openDeleteImgDialogue(slide)}
                  >
                    <img
                      src={newDelSub}
                      style={{
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div
              style={{
                // backgroundImage: `url(${addImgCon})`,
                // backgroundSize: "cover",
                width: "30%",
                // height: "200px",
                marginTop: "20px",
                marginBottom: "20px",
                // border: "1px solid #ED388F",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={styles.bookImage}
              onClick={() => handleImageClick()}
            >
              <img src={addImgCon} style={{ width: "100%" }} />
              <img
                src={addImage}
                className={styles.sellerImage}
                style={{ cursor: "pointer" }}
              />
            </div>
            <input
              type="file"
              id="file-input"
              multiple
              style={{ display: "none" }}
              onChange={handleImageChange}
              accept="image/png, image/jpeg"
            />
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={emptySlides} style={{ width: "30%" }} />
          </div>
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        style={{ zIndex: 9999 }}
        onClose={handleClose}
        autoHideDuration={6000}
      ></Snackbar>

      <UploadPPTSuccess openSuccess={openSuccessDialog} />
      <PPTSlideDelete
        openMod={deleteImg}
        deletedImg={deletedImg}
        setOpenDeleteImg={setOpenDeleteImg}
        deleteSlideFromSubTopic={deleteSlideFromSubTopic}
      />
    </div>
  );
}

export default ContentPPtEdit;

const fileTypeData = [
  {
    id: 1,
    file_type: "Image",
  },
  {
    id: 2,
    file_type: "Video",
  },
  {
    id: 3,
    file_type: "Gif",
  },
];
