import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import InputField from "../../Components/Input/InputField";
import { Draggable } from "react-drag-reorder";
import Dots from "../../Assets/Onboarding/drag.png";
import DotsBlack from "../../Assets/Onboarding/drag_indicator.png";
import moment from "moment";
import { Button } from "@mui/material";
import { api_token } from "../../Utils/Network";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
 
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 
function TabPanel(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
 
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
 
function AddShift() {
  const [boardType, setBoardType] = useState();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState(dayjs("2018-01-01T00:00:00.000Z"));
  const [conclude, setConclude] = React.useState(
    dayjs("2018-01-01T00:00:00.000Z")
  );
  const [words, setWords] = useState(timetableData);
  const [typeShift, setTypeShift] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [timeLapse, setTimeLaspse] = useState([]);
  console.log(timeLapse, "timeLapse");
  const { state } = useLocation();
  console.log(state, "state");
 
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;
 
 
  const [tabData, setTabData] = useState(
    state.map((v, index) => ({
      startTime: dayjs("2018-01-01T00:00:00.000"),
      endTime: dayjs("2018-01-01T00:00:00.000"),
      total_periods: "",
      period_length: "",
      brk: "",
      total_working_days: "",
    }))
  );
  useEffect(() => {
    getShifts();
  }, []);
  useEffect(() => {
    getShiftData();
  }, [value]);
 
  const getShiftData = () => {
    // debugger;
    const filteredData =
      typeShift &&
      typeShift.filter((obj) => obj.title === `Shift ${value + 1}`);
    const time = filteredData[0]?.time_lapse;
 
    setTimeLaspse(time);
    setCurrentData(filteredData);
    console.log(filteredData, "filterDATA");
  };
 
  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };
 
  const getShifts = () => {
    api_token
      .get(`/calendar/v1/shifts/`)
      .then((res) => {
        console.log(res.data.data, tabData, "resres");
        const resultData = res.data.data;
        console.log(resultData, "resultData");
        // if (resultData.length > 0) {
        //   setTabData(
        //     resultData.map((v, index) => ({
        //       startTime: dayjs(`2018-01-01T${v.commence}`),
        //       endTime: dayjs(`2018-01-01T${v.conclude}`),
        //       total_periods: v.total_periods,
        //       period_length: v.period_length,
        //       brk: v.breaks.length,
        //       total_working_days: v.total_working_days,
        //     }))
        //   );
        // }
        var myData = [];
        for (var i = 0; i < state.length; i++) {
          if (resultData.length >= i + 1) {
            myData.push({
              startTime: dayjs(`2018-01-01T${resultData[i].commence}`),
              endTime: dayjs(`2018-01-01T${resultData[i].conclude}`),
              total_periods: resultData[i].total_periods,
              period_length: resultData[i].period_length,
              brk: resultData[i].breaks.length,
              total_working_days: resultData[i].total_working_days,
            });
          } else {
            myData.push({
              startTime: dayjs("2018-01-01T00:00:00.000"),
              endTime: dayjs("2018-01-01T00:00:00.000"),
              total_periods: "",
              period_length: "",
              brk: "",
              total_working_days: "",
            });
          }
        }
        console.log(myData, "myData");
        setTabData(myData);
        setTypeShift(resultData);
        const filteredData =
          resultData &&
          resultData.filter((obj) => obj.title === `Shift ${value + 1}`);
        const time = filteredData[0]?.time_lapse;
 
        setTimeLaspse(time);
        console.log(filteredData, "FFFFFFFFFFFf");
        setCurrentData(filteredData);
      })
      .catch((err) => console.log(err));
  };
 
  console.log(value, "tabData");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 
  const handleBoard = (value) => {
    setBoardType(value);
  };
 
  const handleSubmit = (i) => {
    if (state.length === typeShift.length) {
      const { startTime, endTime, brk, ...rest } = tabData[i];
      if (startTime >= endTime) {
        setAlertDetails({
          ...alertDetails,
          open: true,
          message: "End time must be greater than start time",
          errorType: "error",
        });
        return;
      }
      const filteredData =
        typeShift &&
        typeShift.filter((obj) => obj.title === `Shift ${value + 1}`);
      console.log(i, filteredData[0], "filteredData");
 
      let data = {
        title: `Shift ${i + 1}`,
        commence: moment(startTime?.$d).format("HH:mm"),
        conclude: moment(endTime?.$d).format("HH:mm"),
        ...rest,
      };
      console.log(data, "FinalSubmit");
 
      api_token
        .patch(`calendar/v1/shifts/${filteredData[0].id}/`, data)
        .then((res) => {
          getShifts();
          getShiftData();
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("sandesh");
      const { startTime, endTime, brk, ...rest } = tabData[i];
 
      console.log(i);
 
      if (startTime >= endTime) {
        setAlertDetails({
          ...alertDetails,
          open: true,
          message: "End time must be greater than start time",
          errorType: "error",
        });
        return;
      }
 
      let data = {
        title: `Shift ${i + 1}`,
        commence: moment(startTime?.$d).format("HH:mm"),
        conclude: moment(endTime?.$d).format("HH:mm"),
        ...rest,
      };
      console.log(data, "FinalSubmit");
 
      api_token
        .post(`calendar/v1/shifts/`, data)
        .then((res) => {
          getShifts();
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
 
  const handleDragStart = (e, index, _is_break) => {
    e.stopPropagation();
    e.dataTransfer.setData("index", index);
    console.log(index, "indeex1");
  };
 
  const handleDragOver = (e) => {
    e.preventDefault();
  };
 
  const handleDrop = (e, newIndex, _is_break) => {
    // debugger;
    e.preventDefault();
    const oldIndex = e.dataTransfer.getData("index");
 
    console.log(oldIndex, "indeex2");
 
    const newData = [...timeLapse];
    const draggedItem = newData.find(
      (item) => item.index === parseInt(oldIndex)
    );
    newData.splice(oldIndex - 1, 1);
    newData.splice(newIndex - 1, 0, draggedItem);
    newData.forEach((item, index) => {
      item.index = index + 1;
    });
    // setData(newData);
    setTimeLaspse(newData);
 
    const datas = newData.map(({ id, index }) => ({ id, index }));
    setTimeLaspse([]);
    api_token
      .patch(`/calendar/v1/shifts/update_time_lapse/`, datas)
      .then((res) => {
        setTimeLaspse(res.data.data);
        getShifts();
        // const foundIndex = typeShift.findIndex(
        //   (el) => el.title == `Shift ${value + 1}`
        // );
        // const copyShift = typeShift;
        // console.log(foundIndex, copyShift, `Shift ${value + 1}`,res, "res");
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(datas, "DAtass");
    // Update JSON with start and end positions
    // console.log(`Dragged item moved from position ${oldIndex} to ${newIndex}`);
  };
  console.log(typeShift, "typeShift");
 
  function handleChanged(currentPos, newPos) {
    const newData = [...words];
    const [removed] = newData.splice(currentPos, 1);
    newData.splice(newPos, 0, removed);
 
    // Update the index property based on the new order
    newData.forEach((item, index) => {
      item.index = index + 1;
    });
 
    setWords(newData);
 
    console.log("New order:", words);
  }
 
  const GotoGrade = () => {
    if (state.length === typeShift.length) {
      
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Successfully Added Shift Details",
        errorType: "success",
      });
      setTimeout(() => {
        navigate(`/onboard/divideShift`);
      }, 2000);
    }else{
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please fill Shift Details",
        errorType: "error",
      });
    }
  };
 
  console.log(timeLapse, state, "dfjdfdkfjdfdjfdjfkd");
  let ind = 0;
  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>
 
        <div className={styles.centerContainer}>
          <div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  {state.map((v, i) => (
                    <Tab
                      label={`${v.title}`}
                      {...a11yProps(0)}
                      style={{ color: "white", fontWeight: "bold" }}
                      className={styles.paras}
                    />
                  ))}
                  {/* <Tab label="Shift 2" {...a11yProps(1)} /> */}
                </Tabs>
              </Box>
              {state.map((v, i) => (
                <TabPanel value={value} index={i}>
                  {/* Item {v.id} */}
 
                  <div>
                    <div className={styles.startDate}>
                      <div
                        style={{
                          margin: "5px",
                          color: "white",
                          width: "140px",
                          textAlign: "left",
                          fontSize: '18px',
                        }}
                        className={styles.paras}
                      >
                        Start Time:
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopTimePicker
                            value={tabData[i].startTime}
                            onChange={(newValue) => {
                              const newTabData = [...tabData];
                              newTabData[i].startTime = newValue;
                              setTabData(newTabData);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                style={{
                                  border: "1px solid white",
                                  margin: "5px",
                                  color: "white",
                                  width: "83%",
 
                                }}
                                sx={{
                                  ".MuiInputBase-input": {
                                    margin: "5px",
                                    color: "white !important",
                                  },
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
                    <div className={styles.startDate}>
                      <div
                        style={{
                          margin: "5px",
                          color: "white",
                          width: "140px",
                          textAlign: "left",
                          fontSize: '18px',
                        }}
                        className={styles.paras}
                      >
                        End Time:
                      </div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                          <DesktopTimePicker
                            // value={conclude}
                            // onChange={(newValue) => {
                            //     setConclude(newValue);
                            // }}
                            //moment(newValue).format('hh:mm')
 
                            value={tabData[i].endTime}
                            onChange={(newValue) => {
                              const newTabData = [...tabData];
                              newTabData[i].endTime = newValue;
                              setTabData(newTabData);
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                {...params}
                                style={{
                                  border: "1px solid white",
                                  margin: "5px",
                                  width: "83%",
                                }}
                                sx={{
                                  ".MuiInputBase-input": {
                                    margin: "5px",
                                    color: "white !important",
                                  },
                                }}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </div>
 
                    <div>
                      <div className={styles.periodData}>
                        <div className={styles.dataContain}>
                          No. of Periods:
                        </div>
                        <InputField
                          type="number"
                          onInput={(event) => {
                            event.target.value = Math.max(0, parseInt(event.target.value) || 0);
                        }}
                          value={tabData[i].total_periods}
                          onChange={(newValue) => {
                            const newTabData = [...tabData];
                            newTabData[i].total_periods = newValue.target.value;
                            setTabData(newTabData);
                          }}
                          style={{
                            border: "1px solid white",
                            margin: "5px",
                            color: "white",
                          }}
                          sx={{
                            ".MuiInputBase-input": {
                              margin: "5px",
                              color: "white !important",
                            },
                          }}
                        />{" "}
                      </div>
                      <div className={styles.periodData}>
                        <div className={styles.dataContain}>
                          Periods Length:
                          <br />
                          (in mins)
                        </div>
                        <InputField
                          type="number"
                          onInput={(event) => {
                            event.target.value = Math.max(0, parseInt(event.target.value) || 0);
                        }}
                          value={tabData[i].period_length}
                          onChange={(newValue) => {
                            const newTabData = [...tabData];
                            newTabData[i].period_length = newValue.target.value;
                            setTabData(newTabData);
                          }}
                          style={{
                            border: "1px solid white",
                            margin: "5px",
                            color: "white",
                          }}
                          sx={{
                            ".MuiInputBase-input": {
                              margin: "5px",
                              color: "white !important",
                            },
                          }}
                        />{" "}
                      </div>
 
                      <div className={styles.periodData}>
                        <div className={styles.dataContain}>Breaks:</div>
                        <InputField
                          type="number"
                          onInput={(event) => {
                            event.target.value = Math.max(0, parseInt(event.target.value) || 0);
                        }}
                          value={tabData[i].brk}
                          onChange={(newValue) => {
                            let kes = [];
                            const newTabData = [...tabData];
                            newTabData[i].brk = newValue.target.value;
 
                            for (let j = 0; j < newValue.target.value; j++) {
                              let value = {
                                total_time: "",
                              };
                              kes.push(value);
                            }
                            newTabData[i].breaks = kes;
                            setTabData(newTabData);
                          }}
                          style={{ border: "1px solid white", margin: "5px" }}
                          sx={{
                            ".MuiInputBase-input": {
                              margin: "5px",
                              color: "white !important",
                            },
                          }}
                        />{" "}
                      </div>
                      {/* <div className={styles.periodData}>Break1 Duration:
                                            <InputField type="number"
                                                value={tabData[i].periodLength}
                                                onChange={(newValue) => {
                                                    const newTabData = [...tabData];
                                                    newTabData[i].periodLength = newValue.target.value;
                                                    setTabData(newTabData);
                                                }}
                                            /> min</div> */}
                      {tabData[i]?.breaks &&
                        tabData[i]?.breaks.map((v, j) => (
                          <div className={styles.periodData}>
                            <div className={styles.dataContain}>
                              Break-{j + 1} Duration:
                            </div>
                            <InputField
                              type="number"
                              value={v[i]?.total_time}
                              onInput={(event) => {
                                event.target.value = Math.max(0, parseInt(event.target.value) || 0);
                            }}
                              onChange={(newValue) => {
                                const newTabData = [...tabData];
                                newTabData[i].breaks[j].total_time =
                                  newValue.target.value;
                                setTabData(newTabData);
                              }}
                              style={{
                                border: "1px solid white",
                                margin: "5px",
                              }}
                              sx={{
                                ".MuiInputBase-input": {
                                  margin: "5px",
                                  color: "white !important",
                                },
                              }}
                            />{" "}
                            min
                          </div>
                        ))}
 
                      <div className={styles.periodData}>
                        <div className={styles.dataContain}>
                          Total Working Days:
                        </div>
                        <InputField
                          type="number"
                          value={tabData[i].total_working_days}
                          onInput={(event) => {
                            event.target.value = Math.max(0, parseInt(event.target.value) || 0);
                        }}
                          onChange={(newValue) => {
                            const newTabData = [...tabData];
                            newTabData[i].total_working_days =
                              newValue.target.value;
                            setTabData(newTabData);
                          }}
                          style={{ border: "1px solid white", margin: "5px" }}
                          sx={{
                            ".MuiInputBase-input": {
                              margin: "5px",
                              color: "white !important",
                            },
                          }}
                        />
                      </div>
                    </div>
                    {/* <button className={styles.btns} onClick={() => handleSubmit(i)}>Submit</button> */}
 
                    <Button onClick={() => handleSubmit(i)} variant="contained">
                      Submit
                    </Button>
                  </div>
                </TabPanel>
              ))}
            </Box>
          </div>
          <div>
            <div className={styles.drags}>Drag & Drop The Breaks to the desired session</div>
 
            {timeLapse !== undefined &&
              timeLapse.length > 0 &&
              timeLapse.map((word, idx) => {
 
                if(word?.is_break){
                  ind = ind
                }else{
                  ind += 1;
                }
                return (
                  // <div>
                  //     {word?.id}
                  // </div>
                  <div
                    key={word.id}
                    className={`${(word?.is_break) ? styles.breaks: null} ${styles.dragData}`}
                    // draggable={word.title !== 'Break'}
                    draggable={true}
                    onDragStart={(e) =>
                      handleDragStart(e, word.index, word.is_break)
                    }
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, word.index, word.is_break)}
                  >
                    <img src={word?.is_break ? DotsBlack: Dots} alt="dots" />
                    <div>{word?.is_break ? "Break" : `Period ${ind}`}</div>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px'}}>
                      
                      <p style={{margin: '5px'}}>{moment(word?.commence, 'HH:mm:ss').format('h:mm A')}</p> 
                      -
                      <p style={{margin: '5px'}}>{moment(word?.conclude, 'HH:mm:ss').format('h:mm A')}</p>
                      </div>
                  </div>
                );
              })}
 
            {/* <Draggable onPosChange={handleChanged}>
                            {
                                currentData[0]?.time_lapse.length > 0 && currentData[0]?.time_lapse.map((word, idx) => {
                                    return (
                                        // <div>
                                        //     {word?.id}
                                        // </div>
                                        <div key={idx} className={styles.dragData}>
                                                        <img src={Dots} alt="dots" />
                                                        <div>{(word?.is_break) ? "Break" : `Period ${idx + 1}`}</div>
                                                        <div>{word?.period_length}</div>
                                                    </div>
                                    )
                                })
                            }
                        </Draggable> */}
          </div>
        </div>
 
        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>
 
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
 
export default AddShift;
 
const timetableData = [
  {
    id: 1,
    index: 1,
    title: "Period 1",
  },
  {
    id: 2,
    index: 2,
    title: "Period 2",
  },
  {
    id: 3,
    index: 3,
    title: "Period 3",
  },
  {
    id: 4,
    index: 4,
    title: "Period 4",
  },
  {
    id: 5,
    index: 5,
    title: "Break",
  },
];
 
const shiftData = [
  {
    id: 2,
    title: "Shift 2",
  },
  {
    id: 1,
    title: "Shift 2",
  },
];