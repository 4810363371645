
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styles from "./PPTSlideDelete.module.css"


const PPTSlideDelete = ({ deletedImg, openMod, deleteSlideFromSubTopic, setOpenDeleteImg }) => {
    const [open, setOpen] = useState(false);


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    // const handleSubmit = () => {
    //     navCallBack()
    // };
    const handleDelete = (slide) => {
        deleteSlideFromSubTopic(slide);


    }

    return (
        <div>
            {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        Give Feedback
      </Button> */}
            <Modal
                open={openMod}
                onClose={handleClose}
                aria-labelledby="feedback-modal-title"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',

                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                    className={styles.popUpCon}
                    style={{
                        textAlign: "center"
                    }}
                >
                    <div style={{ width: "100%" }}>
                        {/* <h2 className={styles.editHead}>Edit the slide</h2>
            <p className={styles.editPara}>Drag and drop the desired slide to replace this slide</p>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "5%" }} className={styles.imagesCon}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "47%" }}>
                <img src={slide?.thumbnail} style={{ width: "100%", borderRadius: "20px" }} />
                <p className={styles.slideType}>Original Slide</p>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "47%" }}>
                <img src={replaceSide} style={{ width: "100%", borderRadius: "20px" }} />
                <p className={styles.slideType}>New Slide</p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "center", justifyContent: "center", alignItems: "center", gap: "10%", marginBottom : "20px" }}>
              < button variant="contained" onClick={handleSubmit} style={{ width: "150px", fontWeight: "bold" }} className={styles.discardBtn}>
                Discard
              </button>
              < button variant="contained" onClick={handleSubmit} style={{ width: "150px", fontWeight: "bold" }} className={styles.saveBtn}>
                Save
              </button>

            </div> */}

                        <h3 className={styles.editHead}>Are you sure you want to delete?</h3>
                        <img src={deletedImg?.thumbnail} style={{ width: "60%" , borderRadius: "20px", border : "2px solid #ED388F"}} />
                        <div style={{ display: "flex", flexDirection: "row",justifyContent: "center", gap: "10%", alignItems: "center", marginTop: "20px", marginBottom : "20px" }}>
                            <button className={styles.deleteBtn} style={{ backgroundColor: "#ED388F", color: "white", borderRadius: "8px", padding: "10px", width: "150px" }} onClick={() => handleDelete(deletedImg)}>Delete</button>
                            <button className={styles.cancelBtn} style={{ border: "1px solid #ED388F", color: "black", borderRadius: "8px", padding: "10px", width: "150px" }} onClick={() => setOpenDeleteImg(false)}>Cancel</button>

                        </div>


                    </div>



                </Box>
            </Modal >
        </div >
    );
};

export default PPTSlideDelete;