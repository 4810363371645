import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import { CloseOutlined } from "@mui/icons-material";
import DialogBox from "../../Components/DialogBox/DialogBox";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
function AddNewTeacher() {
  const navigate = useNavigate();
  // const { subjectList, classList } = useContext(UserCredsContext)
  const [teacherSelection, setTeacherSelection] = useState(null);
  const [teachers, setTeacher] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [subClass, setSubClass] = useState({ teacher: null, group: null });

  const [subjectList, setSubjectList] = useState([]);
  const [classList, setClassesList] = useState([]);
  const [shiftData, setShiftData] = useState([]);
  const [shift, setShift] = useState([]);
  const [currentShift, setCurrentShift] = useState(null);
  const [teacherss, setTeachers] = useState("");
  const [subject, setSubject] = useState("");
  const [ListingTeacher, setListingTeacher] = useState([]);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [deletedata, setDelete] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleTeacherChange = (event) => {
    console.log(event.target.value);
    setTeachers(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const removeSubject = (tid, vals) => {
    // console.log(tid, vals, teachers, "teach");
    // vals?.subject?.id
    setOpenDialog(true);
    setDelete({
      teacher_id: tid,
      subject_id: vals,
      classe_id: currentSelected,
    });
    // api_token
    // .post(`profile/v1/teacher_subject_class/delete_teacher_subject_class/`, {teacher_id: tid, subject_id: vals, classe_id: currentSelected})
    // .then((res) => {
    //   if(res.status == 200 || res.status == 201 || res.status == 204){
    //     getList(currentSelected, currentShift);

    //   }
    // })
    // .catch((err) => console.log(err))
  };

  const handleDelete = () => {
    api_token
      .post(
        `profile/v1/teacher_subject_class/delete_teacher_subject_class/`,
        deletedata
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          getList(currentSelected, currentShift);
          setOpenDialog(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleAddTeacherSubject = () => {
    // Implement logic to add new teacher and subject to the list
    console.log("Teacher:", teacherss, "Subject:", subject, currentSelected);

    if (teacherss && subject && currentSelected) {
      let data = {
        teacher: teacherss,
        subject_id: subject,
        classe_id: currentSelected,
      };

      api_token
        .post(`profile/v1/teacher_subject_class/`, data)
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            getList(currentSelected, currentShift);
            setTeachers("");
            setSubject("");
          } else if (res.status == 400) {
            console.log(res, "RESSSSSS");
          }
        })
        .catch((err) => {
          let data = err?.response?.data?.error?.fields[0]?.message[0];
          alert(data);
        });
    } else {
      alert("Please Select All the Details Properly");
    }
  };

  useEffect(() => {
    getShifts();
  }, []);

  useEffect(() => {
    getTeachers();
  }, [filterObj]);

  const getShifts = () => {
    api_token
      .get(`calendar/v1/shifts`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          getSubjects();
          setShift(res.data.data);
          setCurrentShift(res.data.data[0]?.id);
          getClasses(res.data.data[0]?.id);
          setFilterObj({ ...filterObj, shift: res.data.data[0]?.id });
        }
      })
      .catch((err) => console.log(err));
  };

  const getSubjects = () => {
    // api_token.get(`/`)
    api_token.get(`/base/v1/subject/all_subjects`).then((res) => {
      setSubjectList(res.data.data);
    });
  };

  const getClasses = (val) => {
    api_token
      .get(`/calendar/v1/shifts/${val}/shifts_listings_by_type?type=1`)
      .then((res) => {
        setClassesList(res.data.data);
        getList(res.data.data[0].id, val);
        setCurrentSelected(res.data.data[0].id);
      })
      .catch((err) => console.log(err));
  };

  const handleAddClasses = (val) => {
    console.log(val);
    setCurrentSelected(val?.id);
    getList(val?.id, currentShift);
    setTeachers("");
    setSubject("");
  };

  const getList = (vals, shift) => {
    api_token
      .get(
        `profile/v1/teachers/all_classes_allocated_subject_teachers/?shift=${shift}&page_size=100&class_id=${vals}`
      )
      .then((res) => {
        if (res.status == 200 || res.status == 201 || res.status == 204) {
          setListingTeacher(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getTeachers = (
    paramObj = {
      ...filterObj,
      page_size: 100,
    }
  ) => {
    api_token
      .get(`profile/v1/teachers/all_teacher_allocated_subject_classes`, {
        params: paramObj,
      })
      .then((res) => {
        setTeacher(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const GotoNext = () => {
    navigate(`/onboard/addChapters`);
  };

  const handleShift = (val) => {
    setCurrentShift(val?.id);
    getClasses(val?.id);
    setFilterObj({ ...filterObj, shift: val?.id });
  };

  const handleChanges = (tid, subid) => {
    console.log(tid, subid, "SSSSSS");
    if (tid && subid && currentSelected) {
      let data = {
        teacher_id: tid,
        subject_id: subid,
        classe_id: currentSelected,
      };
      api_token
        .post(
          `profile/v1/teacher_subject_class/delete_teacher_subject_class/`,
          data
        )
        .then((res) => {
          if (res.status == 200 || res.status == 201 || res.status == 204) {
            let updates = {
              teacher: tid,
              subject_id: subid,
              classe_id: currentSelected,
            };
            api_token
              .post(`profile/v1/teacher_subject_class/`, updates)
              .then((res) => {
                if (res.status == 200 || res.status == 201) {
                  getList(currentSelected, currentShift);
                  setTeachers("");
                  setSubject("");
                } else if (res.status == 400) {
                  console.log(res, "RESSSSSS");
                }
              })
              .catch((err) => {
                let data = err?.response?.data?.error?.fields[0]?.message[0];
                alert(data);
              });
          }
        })
        .catch((err) => console.log(err));
    }
  };

  console.log(ListingTeacher, "Listing Teacher");

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.teacherContainer}>
          {shift &&
            shift.map((v, i) => (
              <div
                onClick={() => handleShift(v)}
                className={`${styles.btnColor} ${
                  currentShift === v?.id ? styles?.btnSelected : ``
                }`}
              >
                {v?.title}
              </div>
            ))}
        </div>

        <div className={`${styles.subjectMain} ${styles.teacherContainer}`}>
          {classList &&
            classList.map((v, i) => (
              <div
                className={`${styles.subjectBox} ${
                  currentSelected == v.id ? styles.selected : ""
                }`}
                onClick={() => handleAddClasses(v)}
              >
                {v.title}
              </div>
            ))}
        </div>

        {ListingTeacher &&
          ListingTeacher[0]?.allocated_subject_teachers.map((v, i) => (
            <div className={`${styles.listing} ${styles.teacherContainer}`}>
              <div style={{ width: "80%", margin: "10px" }}>
                <Select
                  style={{ color: "white", background: "#ffffff24" }}
                  value={v?.teacher?.user?.id}
                  fullWidth
                  // onChange={(e) => handleChanges(e.target, v?.subject?.id)}
                >
                  {/* Render dropdown options for teachers */}
                  {teachers.map((item) => (
                    <MenuItem
                      key={item.teacher.id}
                      // onClick={(e) => handleChanges(item.teacher.id, v?.subject?.id)}
                      value={item?.teacher?.user?.id}
                    >{`${item.teacher?.user?.first_name} ${item.teacher?.user?.last_name}`}</MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ width: "80%", margin: "10px" }}>
                <Select
                  style={{ color: "white", background: "#ffffff24" }}
                  value={v?.subject?.id}
                  // onChange={(e) => handleChanges(v?.teacher?.id, e.target.value)}
                  fullWidth
                >
                  {/* Render dropdown options for subjects */}
                  {subjectList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ width: "80%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ background: "white", color: "black" }}
                  onClick={() => removeSubject(v?.teacher?.id, v?.subject?.id)}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}

        <div className={`${styles.listing} ${styles.teacherContainer}`}>
          <div style={{ width: "80%", margin: "10px" }}>
            <Select
              style={{ color: "white", background: "#ffffff24" }}
              value={teacherss}
              onChange={handleTeacherChange}
              fullWidth
            >
              {/* Render dropdown options for teachers */}
              {teachers.map((item) => (
                <MenuItem
                  key={item.teacher.id}
                  value={item.teacher.id}
                >{`${item.teacher?.user?.first_name} ${item.teacher?.user?.last_name}`}</MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ width: "80%", margin: "10px" }}>
            <Select
              style={{ color: "white", background: "#ffffff24" }}
              value={subject}
              onChange={handleSubjectChange}
              fullWidth
            >
              {/* Render dropdown options for subjects */}
              {subjectList.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div style={{ width: "80%" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTeacherSubject}
            >
              Add
            </Button>
          </div>
        </div>

        <div>
          <button className={styles.btns} onClick={() => GotoNext()}>
            Next
          </button>
        </div>
      </div>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Alert !</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this ?</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setDelete({});
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleDelete}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddNewTeacher;

const data = [
  {
    id: 6,
    title: "Class6",
    mainobj: [
      {
        teacher: { id: 2, title: "Vaibhav" },
        subject: { id: 3, title: "Bio" },
      },
      {
        teacher: { id: 5, title: "Wasim" },
        subject: { id: 6, title: "Physics" },
      },
      {
        teacher: { id: 6, title: "Rushikesh" },
        subject: { id: 4, title: "Maths" },
      },
    ],
  },
];
