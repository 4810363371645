import React, {useContext, useEffect, useState} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from 'react-router-dom';
import { UserCredsContext } from '../../ContextApi/UserCredsContext/UserCredsContext';

function BoardSelection({ setBoardGrade = () => {}, boardGrade }) {
    const [boardType, setBoardType] = useState(null);
    const { boardList, userState } = useContext(UserCredsContext)
    const navigate = useNavigate();

    useEffect(() => {

        if(userState?.school_profile?.is_onboarded){
            setBoardType(boardList[0]?.id)
            setBoardGrade({...boardGrade, board: boardList[0]?.id})
        }
    }, [])

    const handleBoard = (value) => {
        setBoardGrade({...boardGrade, board: value})
        setBoardType(value)
    }


    const GotoGrade = () => {

        // {{external}}{{v1}}sync_db

        // hit this above api after grade selection i.e in next page
        
        navigate(`/onboard/grade`)
    }
    console.log(boardList, userState, "BBORADSS")

  return (
    <div className={styles.mainBackground}>
        <div className={styles.subContainer}>



        <div className={`${styles.heading}`}>
            <ArrowBackIcon style={{fontSize: '30px'}} />
            <p className={styles.heads}>Powered by Ed5</p>
        </div>

        <div className={styles.centerContainer}>
            {/* <h1 className={styles.selectionHeading}>Select Board</h1> */}
            <p className={styles.selectionHeading}>Select Board</p>

            <div className={styles.boards}>
                {
                    boardList && boardList.map((v,i) => (
                        <div key={i} className={`${styles.containerBoard} ${(boardType == v.id) ? styles.boardselect: ''}`} onClick={() => handleBoard(v.id)}>{v.title}</div>
                    ))
                }
            </div>
        </div>

        <div>
            <button className={styles.btns} onClick={() => GotoGrade()}>Next</button>  
        </div>
        </div>
    </div>
  )
}

export default BoardSelection


const board = [
    {
        id: 1,
        title: "ICSE",
    },
    {
        id: 2,
        title: "CBSE",
    },
    {
        id: 3,
        title: "International",
    }
]