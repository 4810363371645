import React, { useContext, useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Input, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function AddSection() {
  const [boardType, setBoardType] = useState();
  const [classList, setClassList] = useState([]);
  const [boolean, setBoolean] = useState(true);
  const { gradeList } = useContext(UserCredsContext);
  const [classes, setClasses] = useState([]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;
  let char = ["A", "B", "C", "D", "E", "F", "G", "H"];
  const navigate = useNavigate();

  const handleBoard = (value) => {
    setBoardType(value);
  };

  useEffect(() => {
    getGrade();
    getClasses();
  }, []);

  useEffect(() => {
    if (
      classList.length === gradeList.length &&
      classList.every((obj) => obj.class.length)
    ) {
      setBoolean(false);
    } else {
      setBoolean(true);
    }
  }, [classList]); //check-1

  const getClasses = () => {
    api_token
      .get(`base/v1/class`)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          const brows = [];
          let gradeLength = res.data.data;

          gradeLength && gradeLength.length > 0 && gradeLength.forEach((item) => {
            let existingGroup = brows.find((group) => group.id === item.grade);

            if (!existingGroup) {
              existingGroup = {
                id: item.grade,
                class: [],
              };
              brows.push(existingGroup);
            }

            existingGroup.class.push({ grade: item.grade, title: item.title });
          });

          setClassList(brows)
        }
      })
      .catch((err) => console.log(err));
  };

  const getGrade = () => {
    api_token
      .get(`base/v1/grade`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const GotoGrade = () => {
    let data = [];
    classList.map((v, i) => {
      v.class.map(({ ids, ...val }) => {
        data.push(val);
      });
    });

    console.log(data, classList);

    if (data.length > 0) {
      api_token
        .patch(`/base/v1/class/create_update_class/`, data) // as per backend dev requirements
        .then((res) => {
          if (res.status == 200 || res.status == 201) {
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Section added Successfully",
              errorType: "success",
            });
            setTimeout(() => {
              navigate(`/onboard/uploadteacher`);
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Please Fill the Data Properly",
            errorType: "error",
          });
        });
    } else {
      setAlertDetails({
        ...alertDetails,
        open: true,
        message: "Please Add All Classes",
        errorType: "error",
      });
    }
  };

  const handleChange = (e, value) => {
    const data = {
      id: value.id,
      class: [],
    };

    for (let i = 0; i < e.target.value; i++) {
      let newValue = {
        ids: i,
        grade: value.id,
        title: `${value.title}${char[i]}`,
      };
      data.class.push(newValue);
    }

    let ind = classList.findIndex((i) => {
      return i.id == data.id;
    });

    if (ind === -1) {
      setClassList([...classList, data]);
    } else {
      classList[ind] = data;
      setClassList([...classList]);
    }
  }; //check-2

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "35px", marginRight: "10px" }} />
          <p>Powered By Ed5</p>
        </div>

        <div className={styles.centerContainer}>
          <p className={styles.selectionHeading}>
            Add the number of sections in each Grade
          </p>
          <div className={styles.boards}>
            <div className={styles.gradeClass}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item xs={6}>
                    <h3
                      className={`${styles.whiteColor} ${styles.headingText}`}
                    >
                      Grades
                    </h3>
                  </Grid>
                  <Grid item xs={6}>
                    <h3
                      className={`${styles.whiteColor} ${styles.headingText}`}
                    >
                      Sections
                    </h3>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                  columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                >
                  {/* {gradeList && gradeList.map((v, i) => (<div key={i} style={{ display: 'flex', margin: '10px' }}> */}
                  {classes &&
                    classes.map((v, i) => (
                      <div key={i} style={{ display: "flex", margin: "10px" }}>
                        <Grid item xs={2} sm={4} md={6} lg={6}>
                          <p
                            className={styles.whiteColor}
                            style={{ marginRight: "20px" }}
                          >
                            {v.title}
                          </p>
                        </Grid>
                        <Grid item xs={2} sm={4} md={6} lg={6}>
                          <TextField
                            className={styles.textfields}
                            style={{
                              border: "1px solid white",
                              outline: "none",
                              color: "white",
                            }}
                            type="number"
                            onChange={(e) => handleChange(e, v)}
                            InputProps={{
                              style: { color: "white" }, // Change the text color here
                            }}
                          />
                        </Grid>
                      </div>
                    ))}
                </Grid>
              </Box>
            </div>
            <div className={styles.classes}>
              <h3 className={`${styles.whiteColor} ${styles.headingText}`}>
                Division names
              </h3>

              {classList.length > 0 ? (
                classList.map((v, i) => (
                  <div className={styles.classContainer} key={i}>
                    {v.class.map((data, index) => (
                      <div className={styles.classData} key={index}>
                        {data.title}
                      </div>
                    ))}
                  </div>
                ))
              ) : (
                <div
                  style={{
                    color: "white",
                    fontSize: "20px",
                    textAlign: "center",
                    position: "relative",
                    top: "30%",
                  }}
                >
                  No classes here
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <button
            className={styles.btns}
            onClick={() => GotoGrade()}
            // disabled={boolean}
          >
            Next
          </button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddSection;

// const classes = [
//   {
//     id: 1,
//     title: "Class 3",
//   },
//   {
//     id: 2,
//     title: "Class 4",
//   },
//   {
//     id: 3,
//     title: "Class 5",
//   },
//   {
//     id: 4,
//     title: "Class 6",
//   },
//   {
//     id: 5,
//     title: "Class 7",
//   },
//   {
//     id: 6,
//     title: "Class 8",
//   },

//   {
//     id: 7,
//     title: "Class 9",
//   },

//   {
//     id: 8,
//     title: "Class 10",
//   },
// ];

/**
 * 
 const data = [
  {
    grade:1,
    title: "Class 6 A"
  },
  {
    grade:1,
    title: "Class 6 B"
  },{
    grade:1,
    title: "Class 6 C"
  },{
    grade:1,
    title: "Class 6 D"
  },
  {
    grade:8,
    title: "Class 7 A"
  },{
    grade:8,
    title: "Class 7 A"
  },{
    grade:8,
    title: "Class 7 A"
  },
 ]


 const brows = [
  {
    class: [
      {
    grade:1,
    title: "Class 6 A"
  },
  {
    grade:1,
    title: "Class 6 B"
  },{
    grade:1,
    title: "Class 6 C"
  },{
    grade:1,
    title: "Class 6 D"
  }],
  id: 1,
  },
  {
    class: [
      {
    grade:8,
    title: "Class 7 A"
  },{
    grade:8,
    title: "Class 7 A"
  },{
    grade:8,
    title: "Class 7 A"
  }],
  id: 8,
  }
 ]
 */
