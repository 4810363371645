import React, { useEffect, useState } from "react";
import styles from "./daily.module.css";
import schoolLogo from "../../Assets/schoologo.png";
import maths from "../../Assets/maths.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { api_token } from "../../Utils/Network";
import moment from 'moment';
import { getCurrentDateTime } from "../../Utils/Utils";
import breakImg from "../../Assets/Break.png" 
import freePeriodImg from "../../Assets/Free Period.png"
import defultImg from "../../Assets/Default Icon.png"


const DashDailyCalender = () => {
  let navigate = useNavigate();
  const [dailyClass, setDailyClass] = useState([]);
  const [currenctDate, setCurrentDate] = useState(getCurrentDateTime());

  useEffect(() => {
    const { month, year } = currenctDate;
    getDailyClasses(month, year);
  }, []);

  const getDailyClasses = (month, year) => {
    api_token
      .get(`calendar/v1/events?event_type=2&month=${month + 1}&year=${year}`)
      .then((res) => {
        if (res.data.data) {
          let resdata = res.data.data;
          let demo = resdata.splice(0, 3);
          setDailyClass(demo);
        }
      })
      .catch((err) => console.log(err));
  };

  //while clicking on View All, it navigates to calendar....
  const handleRedirect = () => {
    navigate(`/dashboard/calendar/daily-classes`);
  };

  //while clicking on each card, it navigates to ppt...
  const handleData = (data) => {
    console.log(data,"handleData");
    navigate(`/dashboard/content/select`, {state: {data}})
  }

  console.log(dailyClass, "DailyClass");
  return (
    <>
      <div className={styles.dailyCalender}>
        <div className={styles.titleBar}>
          <span style={{ fontSize: "32px" }}>Daily Classes</span>
          <Button variant="outlined" onClick={handleRedirect} style = {{width : "100px", height : "50px"}}>
            View All
          </Button>
        </div>

        <div className={styles.dailyCardBox}>
          {dailyClass && dailyClass.map((v, i) => (
            <div className={styles.dailyCard} onClick={() => handleData(v)}>
              <div>
              {/* {(v?.subject?.icon) ?
                  <img src={v?.subject?.icon} alt="logo" width={50} />
                  : <img src={schoolLogo} alt="logo" width={50} />} */}
                {(v?.subject?.icon) ?
                  <img src={v?.subject?.icon} alt="logo" width={50} />
                  : v?.title == "Break" ? <img src={breakImg} alt="logo" width={50} /> :  v?.title == "Free Period" ? <img src={defultImg} alt="logo" width={50} /> :
                  <img src={schoolLogo} alt="logo" width={50} />}
              </div>
              <div className={styles.cardDescription}>
                <span className={styles.font20}>{v?.title}</span>

                <span style={{ color: "#B3B3B3" }}>{moment(v?.commence, 'HH:mm:ss').format('h:mm A')}, {parseInt(moment.duration(v.total_time, 'seconds').asMinutes())} min </span>
              </div>
            </div>))}
        </div>
      </div>
    </>
  );
};

export default DashDailyCalender;
