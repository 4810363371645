import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./index.module.css";
import videoImg from "../../Assets/ContentMangement/mindmap/number.png";
import videoDecimal from "../../Assets/ContentMangement/mindmap/Decimal.png";
import matrics from "../../Assets/ContentMangement/mindmap/matrix.png";
import edfive from "../../Assets/ContentMangement/mindmap/Ed5.png";
import cnm from "../../Assets/ContentMangement/mindmap/cnm.png";
import Carousel from "react-multi-carousel";
import Tens from "../../Assets/ContentMangement/mindmap/Ten.png";
import "react-multi-carousel/lib/styles.css";
import CustomProgress from "../../Components/CustomProgress/CustomProgress";
import Pdf from "../../pdf/Management Process & Organizational Behavior.pdf";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import drawerBack from "../../Assets/ContentMangement/ppt/drawerBack.png";

import {
  api_token,
  base_url,
  formDataApi,
  _access_token,
} from "../../Utils/Network";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import DrawerComp from "../../Components/DrawerComp/DrawerComp";
import InputField from "../../Components/Input/InputField";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import DialogBox from "../../Components/DialogBox/DialogBox";
import PPtEditSlide from "../../Components/PptSlideEdit/PptSlideEdit";
import { SUBMIT_FILTER_STROKE_TIME } from "@mui/x-data-grid";
import { Snackbar } from "@material-ui/core";
import { FileUploader } from "react-drag-drop-files";
import Files from "../../File/ppt.xlsx";
import sub_1 from "../../Assets/ContentMangement/ppt/sub_1.png";
import sub_2 from "../../Assets/ContentMangement/ppt/sub_2.png";
import sub_3 from "../../Assets/ContentMangement/ppt/sub_3.png";
import addImage from "../../Assets/ContentMangement/ppt/addImage.png";
import addImgCon from "../../Assets/ContentMangement/ppt/addImgCon.png";
import newDelSub from "../../Assets/ContentMangement/ppt/newDelSub.png";
import completed from "../../Assets/ContentMangement/ppt/completed.png";
import ContentPptChapter from "../../Components/ContentPptChapter/ContentPptChapter";
import ContentPPtEditSlideImg from "../../Components/ContentPPtEditSlideImg/ContentPPtEditSlideImg";

import PptSlideEdit from "../../Components/PptSlideEdit/PptSlideEdit";
import PPTSlideDelete from "../../Components/PPTSlideDelete/PPTSlideDelete";
import UploadPPTSuccess from "../../Components/UploadPPTSuccess/UploadPPTSuccess";
import PptFullScreenSlide from "../../Components/PptFullScreenSlide/PptFullScreenSlide";

const fileTypes = ["xlsx"];

function Contentppt(props) {
  const { content_selection } = useContext(UserCredsContext);
  let navigate = useNavigate();
  const observer = useRef();
  const [openEditSlideDialoge, setOpenEditSlideDialoge] = useState(false);
  const [filterObj, setFilterObj] = useState({
    subject: content_selection?.subject,
    grade: content_selection?.grade,
    board: content_selection?.board,
    page: 1,
  });
  const [getEbook, setEbook] = useState([]);
  console.log(getEbook, "hariEbook");
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editSlideImg, setEditSlideImg] = useState({});
  const [file, setFile] = useState(null);
  // const [file, setFile] = useState([]);
  const [chapterFilter, setChpFilter] = useState(null);
  const [chapterList, setChapterList] = useState([]);
  const [imageId, setImages] = useState(1);
  const [datas, setDatas] = useState(0);
  const [selectedChapter, setSelectedChapter] = useState({});
  const [deleteImg, setOpenDeleteImg] = useState(false);
  const [deletedImg, setDeletedImg] = useState({});
  const [selectedImages, setSelectedImages] = useState([]);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [fullScreenSlide, setFullScreenSlide] = useState({});
  const [openFullScreenSlide, setOpenFullScreenSlide] = useState(false);
  const [loading, setLoading] = useState(false);

  const [submitValue, setSubmitValue] = useState({
    id: "",
    subject_id: content_selection.subject,
    board_id: content_selection.board,
    grade_id: content_selection.grade,
    language_id: 1,
    chapter_id: null,
    // topic: "",
    provider: 2,
  });

  const [addPptTopicList, setAddPptTopicList] = useState([]);

  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //GET API basing on Subjet_id, Board_id, Grade_id......

  const getEbookData = (paramObj = { ...filterObj }, list = []) => {
    // setEbook([]);
    api_token
      .get(`/content/v1/chapter_wise_ppts/`, { params: paramObj })
      .then((res) => {
        console.log(res?.data?.data, "responseData");
        if (res?.data?.next_page) paramObj.page = res?.data?.next_page;
        else delete paramObj.page;
        setEbook([...list, ...res.data.data]);
        setFilterObj(paramObj);
        setLoading(false);
        // setEbook(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEbookData();
    getAddPptTopicList();
  }, []);

  const openPpt = () => {
    setSubmitValue({
      id: "",
      subject_id: content_selection.subject,
      board_id: content_selection.board,
      grade_id: content_selection.grade,
      language_id: 1,
      chapter_id: null,
      // topic: "",
      provider: 2,
    });
    setFile(null);
    setChpFilter(null);
    setOpen(true);
  };

  const openBulk = () => {
    setOpenDialog(true);
  };

  const newDataOpen = (file) => {
    //You can open ppt here while passing id to the get
    window.open(file, "_blank");
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size here
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      const maxSizeMB = 50; // Example: 10 MB maximum size

      if (fileSizeInMB > maxSizeMB) {
        alert(`File size cannot exceed ${maxSizeMB} MB.`);
        e.target.value = null; // Clear the file input
        return;
      }
    setFile(file);
    }
    // setFile(e.target.files[0]);
    // setFile(e);
  };

  const handleData = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "EEEEEEEEEEEe");
    setSubmitValue({ ...submitValue, [name]: value });
    if (name == "chapter_id") {
      let chp = getEbook.findIndex((x) => {
        return x.id == value;
      });
      setChpFilter(chp);
    }
  };

  const newData = (data) => {
    console.log(data, "Datassssssss");
    const {
      id,
      language: language_id,
      provider,
      subject: subject_id,
      chapter: chapter_id,
      topic,
      board: board_id,
    } = data;
    setSubmitValue({
      id,
      language_id,
      provider,
      subject_id,
      chapter_id,
      topic,
      board_id,
    });
    let chp = getEbook.findIndex((x) => {
      return x.id == chapter_id;
    });
    setChpFilter(chp);
    setOpen(true);
  };

  const SubmitFile = async () => {
    let data = {
      file: file,
    };
    const formData = new FormData();
    if (data.file) formData.append("file", data.file);
    try {
      await axios({
        method: "Post",
        url: `${base_url}external/v1/sync/ppt/`,
        data: formData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        headers: { Authorization: `Bearer ${_access_token}` },
      })
        .then((response) => {
          // setLoading(false)
          console.log(response, "rRRRRRRR");
          if (response.status === 200 || response.status === 201) {
            if (response.data.data) {
              // alert("PPT Bulk Data Uploaded Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "PPT Bulk Data Uploaded Successfully",
                errorType: "success",
              });
              setFile(null);
              setOpenDialog(false);
              // getEbookData();
            }
          }
        })
        .catch((error) => {
          console.log(error, "error");
        });
    } catch (error) {
      console.log(error);
    }

    // let apiEnd = "sync/ppt/";
    // try {
    //     // const res = await formDataApi(apiEnd, fmData)
    //     const res = await formDataApi(apiEnd, formData);
    //     console.log(res, "ResponseData")
    //     // setFile(null);
    //     // setOpen(false);
    // } catch (error) {
    //     console.log(error)
    // }
  };

  //ADD PPT API......

  const submitData = async (e) => {
    let data = {
      ...submitValue,
      ebook: file,
    };

    console.log(data, "ed5Data");
    const formData = new FormData();

    if (data.topic) formData.append("topic_id", data.topic);
    if (data.chapter_id) formData.append("chapter_id", data.chapter_id);
    if (data.language_id) formData.append("language_id", data.language_id);
    if (data.grade_id) formData.append("grade_id", data.grade_id);
    if (data.subject_id) formData.append("subject_id", data.subject_id);
    if (data.board_id) formData.append("board_id", data.board_id);
    if (data.ebook) formData.append("ppt", data.ebook);
    if (data.provider) formData.append("provider", data.provider);

    if (data.id === "") {
      try {
        let { id, ...rest } = data;
        let datas = [...Object.values(rest)].every(Boolean);
        if (!datas) {
          setAlertDetails({
            ...alertDetails,
            open: true,
            message: "Please Fill proper details",
            errorType: "error",
          });
          return;
        }

        await axios({
          method: "Post",
          url: `${base_url}content/v1/ppts_new/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              // alert("PPT Added Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "PPT Added Successfully",
                errorType: "success",
              });
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                language_id: 1,
                chapter_id: null,
                // topic: "",
              });
              setFile(null);
              setOpen(false);
              getEbookData();
              getAddPptTopicList();
            }
          })
          .catch((error) => {
            console.log(error, "error");
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Error Filling Data",
              errorType: "error",
            });
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await axios({
          method: "Patch",
          url: `${base_url}content/v1/ppts/${data.id}/`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
          headers: { Authorization: `Bearer ${_access_token}` },
        })
          .then((response) => {
            // setLoading(false)
            if (response.data.data) {
              // alert("PPT Updated Successfully")
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "PPT Updated Successfully",
                errorType: "success",
              });
              setSubmitValue({
                subject_id: content_selection.subject,
                board_id: content_selection.board,
                grade_id: content_selection.grade,
                language_id: 1,
                chapter_id: null,
                // topic: "",
              });
              setFile(null);
              setOpen(false);
              // getEbookData();
            }
          })
          .catch((error) => {
            console.log(error, "error");
            setAlertDetails({
              ...alertDetails,
              open: true,
              message: "Error Filling Data",
              errorType: "error",
            });
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const onChangeIMage = (item) => {
    setImages(item?.id);
  };

  const handleChangeItem = (selectedChapter) => {
    setSelectedChapter(selectedChapter);
  };
  console.log(selectedChapter, "selectedChapter");

  const openDeleteImgDialogue = (item) => {
    setDeletedImg(item);
    setOpenDeleteImg(true);
  };

  const openEditSlideImgDialogue = (editSlide) => {
    setEditSlideImg(editSlide);
    setOpenEditSlideDialoge(true);
  };

  const handleOpenFullScreenSlide = (item) => {
    setFullScreenSlide(item);
    setOpenFullScreenSlide(true);
  };

  const deleteSlideFromSubTopic = (deleteSlide) => {
    api_token
      .delete(`/content/v1/ppt_slides/${deleteSlide?.id}/`)

      .then((response) => {
        console.log(response, "responseee");

        if (
          response?.status == 204 ||
          response?.status == 200 ||
          response?.OK
        ) {
          console.log("deleted successfully", "deletedd");
          setOpenDeleteImg(false);
          getEbookData();
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const navCallBack = () => {
    getEbookData();
    setOpenEditSlideDialoge((prev) => !prev);
  };
  //   const deleteCallBack = () => {
  //     setOpenDeleteImg(prev => !prev)
  //   }

  const closeFullScreen = () => {
    setOpenFullScreenSlide((prev) => !prev);
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const newImages = [...selectedImages];

    for (let i = 0; i < files.length; i++) {
      newImages.push(files[i]);
    }
    setSelectedImages(newImages);
  };
  const handleImageClick = () => {
    document.getElementById("file-input").click();
  };
  console.log(selectedImages, "selectedImages");

  const handleClikSave = () => {
    setOpenSuccessDialog(true);
    setTimeout(() => {
      setOpenSuccessDialog(false);
    }, 2000);
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading || !getEbook.length) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && filterObj.page)
          getEbookData(filterObj, getEbook);
      });
      if (node) observer.current.observe(node);
    },
    [loading, filterObj.page]
  );

  //New ppt topic list...
  const getAddPptTopicList = () => {
    api_token
      .get(`cms/v1/topic/`)
      .then((response) => {
        setAddPptTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Get Individual new ppt topi list.....
  const getIndividualNewBookTopicList = (chapterId) => {
    api_token
      .get(`cms/v1/topic/?chapter=${chapterId}`)
      .then((response) => {
        setAddPptTopicList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error, "errorr");
      });
  };

  console.log(submitValue, addPptTopicList, "hariDivyaChaitutu");

  return (
    <div>
      <div className={styles.Ebooks_main}>
        <div className={styles.head}>PPT</div>
        <div>
          {/* <Button onClick={() => openBulk()}>Bulk Upload</Button> */}

          <Button onClick={() => openPpt()}>Add PPT</Button>

          {/*write Access Button*/}
          {/* {props?.access?.writeAccess && (
            <Button onClick={() => openBulk()}>Bulk Upload</Button>
          )}
          {props?.access?.writeAccess && (
            <Button onClick={() => openPpt()}>Add PPT</Button>
          )} */}
        </div>
      </div>

      <div>
        {getEbook &&
          getEbook.map((chapter, index) => {
            return (
              <div
                key={index}
                ref={index + 1 < getEbook.length ? null : lastItemRef}
              >
                <ContentPptChapter
                  chapter={chapter}
                  index={index}
                  handleChangeItem={handleChangeItem}
                  openDeleteImgDialogue={openDeleteImgDialogue}
                  openEditSlideImgDialogue={openEditSlideImgDialogue}
                  handleOpenFullScreenSlide={handleOpenFullScreenSlide}
                  getEbookData={getEbookData}
                  key={chapter?.id}
                />
              </div>
            );
          })}
      </div>

      <div>
        <DrawerComp open={open} anchor="right">
          <div className={styles.BooksContainer}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "19px",
                  fontWeight: "bold",
                }}
              >
                Add Ppt
              </div>

              <div
                onClick={() => {
                  setOpen(false);
                }}
              >
                <img
                  src={drawerBack}
                  style={{ width: "30px", height: "30px", cursor: "pointer" }}
                />
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <FormControl fullWidth style={{ width: "300px" }}>
                <InputLabel id="demo-simple-select-label">Chapter</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={getEbook[chapterFilter]?.id}
                  label="Chapter"
                  name="chapter_id"
                  onChange={(event) => {
                    let chapterId = event.target.value;

                    console.log(chapterId, "topicIdddd");
                    if (chapterId) {
                      getIndividualNewBookTopicList(chapterId);
                    }

                    handleData(event);
                  }}
                >
                  {getEbook &&
                    getEbook.map((v, i) => (
                      <MenuItem value={v.id}>{v.title}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            {/* <div style={{ marginBottom: "20px" }}>
                <FormControl
                  fullWidth
                  style={{ width: "300px" }}
                >
                  <InputLabel id="demo-simple-select-label">Topic</InputLabel>

                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    value={submitValue?.topic}
                    label="Topic"
                    name="topic"
                    onChange={handleData}
                  >
                    {addPptTopicList &&
                      addPptTopicList.map((v, i) => (
                        <MenuItem value={v.id}>{v.title}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
 */}

            {/* <div style={{ marginTop: "20px" , marginBottom: "20px"}}>
              <InputField
                label="Topic Name"
                width="100%"
                value={submitValue?.topic}
                name="topic"
                onChange={handleData}
              />
            </div> */}

            <div>
              <div>Select file</div>
              <input
                type="file"
                accept=".ppt, .pptx, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint"
                onChange={handleChange}
              />
              <div>File must be 50MB or less to upload.</div>
            </div>
            <div>
              <Button className={styles.btns} onClick={() => submitData()}>
                Add
              </Button>
            </div>
          </div>
        </DrawerComp>
      </div>

      <div>
        <DialogBox
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          dataSend={() => SubmitFile()}
        >
          <div>
            <h3>Upload File</h3>
            <p>
              Get Sample File here
              <a href={Files} download>
                {" "}
                Click to download
              </a>
            </p>
            <input type="file" accept="file/*" onChange={handleChange} />
            {/* <FileUploader handleChange={handleChange} name="file" types={fileTypes} dropMessageStyle={{backgroundColor: 'orange'}}/> */}
          </div>
        </DialogBox>
      </div>

      {/* <div>
                <DialogBox open={deleteImg} onClose={() => setOpenDeleteImg(false)} >
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "40px" }}>
                        <h3>Are you sure you want to delete?</h3>
                        <img src={deletedImg?.thumbnail} style={{ width: "100%" }} />
                        <div style={{ display: "flex", flexDirection: "row", gap: "30px", alignItems: "center", marginTop: "20px" }}>
                            <Button style={{ backgroundColor: "#ED388F", color: "white", borderRadius: "8px", padding: "10px", width: "50%" }} onClick={() => deleteSlideFromSubTopic(deletedImg)}>Delete</Button>
                            <Button style={{ border: "1px solid #ED388F", color: "black", borderRadius: "8px", padding: "10px", width: "50%" }} onClick={() => setOpenDeleteImg(false)}>Cancel</Button>

                        </div>

                    </div>
                </DialogBox>
            </div> */}

      {/* <div style = {{backgroundColor : "#ED388F", padding : "100px"}}> 
                <DialogBox open={openEditSlideDialoge} onClose={() => setOpenEditSlideDialoge(false)}  style = {{backgroundColor : "#ED388F", padding : "100px"}}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "40px", backgroundColor : "#ED388F", height : "100%" }}>
                        <h1>Edit the Slide</h1>
                        <h3>Update the slide with new slide</h3>
                        <div style = {{display : "flex", alignItems : "center", gap : "4%"}}>
                        <img src={editSlideImg?.thumbnail} style={{ width: "48%" }} />
                        <img src={editSlideImg?.thumbnail} style={{ width: "48%" }} />



                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: "30px", alignItems: "center", marginTop: "20px" }}>
                            <Button style={{ backgroundColor: "#ED388F", color: "white", borderRadius: "8px", padding: "10px", width: "50%" }} onClick={() => editedSlideImgToNew(deletedImg)}>Delete</Button>
                            <Button style={{ border: "1px solid #ED388F", color: "black", borderRadius: "8px", padding: "10px", width: "50%" }} onClick={() => setOpenEditSlideDialoge(false)}>Cancel</Button>

                        </div>

                    </div>
                    
                </DialogBox>
            </div> */}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        style={{ zIndex: 9999 }}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails.message}
        </Alert>
      </Snackbar>

      <PptSlideEdit
        openMod={openEditSlideDialoge}
        slide={editSlideImg}
        navCallBack={navCallBack}
        handleClikSave={handleClikSave}
      />
      <PPTSlideDelete
        openMod={deleteImg}
        deletedImg={deletedImg}
        deleteSlideFromSubTopic={deleteSlideFromSubTopic}
        setOpenDeleteImg={setOpenDeleteImg}
      />
      <UploadPPTSuccess openSuccess={openSuccessDialog} />
      <PptFullScreenSlide
        openMod={openFullScreenSlide}
        slide={fullScreenSlide}
        closeFullScreen={closeFullScreen}
      />
    </div>
  );
}

export default Contentppt;

const Number = [
  {
    id: 1,
    image: videoImg,
    icons: edfive,
    title: "Decimals System Problem Solving",
  },
  {
    id: 2,
    image: matrics,
    icons: cnm,
    title: "Number System -NCERT",
  },
];

const Decim = [
  {
    id: 1,
    image: videoDecimal,
    icons: edfive,
    title: "Introduction to Number System",
  },
  {
    id: 2,
    image: Tens,
    icons: cnm,
    title: "Intoduction to Decimal System ",
  },
  {
    id: 3,
    image: videoDecimal,
    icons: edfive,
    title: "Introduction to Number System",
  },
  {
    id: 4,
    image: Tens,
    icons: cnm,
    title: "Intoduction to Decimal System ",
  },
];

const dummyPPT = [
  {
    id: 1,
    chapter: "Food: Where Does It Come From?",
    Topics: [
      {
        id: 1,
        title: "Food Variety",
        subTopics: [
          {
            id: 1,
            sub_topic: "Subtopic-1",
            images: [sub_1, sub_2, sub_3],
            start_index: "01",
            end_index: "03",
          },
          {
            id: 2,
            sub_topic: "Subtopic-2",
            images: [sub_1, sub_2, sub_3, sub_3, sub_2, sub_1],
            start_index: "04",
            end_index: "09",
          },
        ],
      },
      // {
      //     id : 2,
      //     title: "Food Materials and Sources",
      //     subTopics : [
      //         {
      //             id : 1,
      //             sub_topic : "Subtopic-1",
      //             images : [sub_1, sub_2, sub_3]
      //         },
      //         {
      //             id : 2,
      //             sub_topic : "Subtopic-2",
      //             images : [sub_2,sub_1, sub_3]
      //         }
      //     ]
      // }
    ],
  },
  // {
  //     id : 2,
  //     chapter : "Components of Food",
  //     Topics : [
  //         {
  //             id : 1,
  //             title: "What Do Different Food Items Contain?",
  //             subTopics : [
  //                 {
  //                     id : 1,
  //                     sub_topic : "Subtopic-1",
  //                     images : [sub_1, sub_2, sub_3]
  //                 },
  //                 {
  //                     id : 2,
  //                     sub_topic : "Subtopic-2",
  //                     images : [sub_1, sub_2, sub_3]
  //                 }
  //             ]
  //         },
  //         {
  //             id : 2,
  //             title: "What Do Various Nutrients Do For Our Body?",
  //             subTopics : [
  //                 {
  //                     id : 1,
  //                     sub_topic : "Subtopic-1",
  //                     images : [sub_1, sub_2, sub_3]
  //                 },
  //                 {
  //                     id : 2,
  //                     sub_topic : "Subtopic-2",
  //                     images : [sub_1, sub_2, sub_3]
  //                 }
  //             ]
  //         }
  //     ]

  // },
  // {
  //     id : 3,
  //     chapter : "Getting To Know Plants",
  //     Topics : [
  //         {
  //             id : 1,
  //             title: "HERBS, SCRUBS AND TREES",
  //             subTopics : [
  //                 {
  //                     id : 1,
  //                     sub_topic : "Subtopic-1",
  //                     images : [sub_1, sub_2, sub_3]
  //                 },
  //                 {
  //                     id : 2,
  //                     sub_topic : "Subtopic-2",
  //                     images : [sub_1, sub_2, sub_3]
  //                 }
  //             ]
  //         },
  //         {
  //             id : 2,
  //             title: "STEM",
  //             subTopics : [
  //                 {
  //                     id : 1,
  //                     sub_topic : "Subtopic-1",
  //                     images : [sub_1, sub_2, sub_3]
  //                 },
  //                 {
  //                     id : 2,
  //                     sub_topic : "Subtopic-2",
  //                     images : [sub_1, sub_2, sub_3]
  //                 }
  //             ]
  //         }
  //     ]

  // },
];
