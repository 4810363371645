import React, { useContext, useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from 'react-router-dom';
import { UserCredsContext } from '../../ContextApi/UserCredsContext/UserCredsContext';
import Dropzone from 'react-dropzone';
import Uploads from "../../Assets/Onboarding/Upload.png";
import { api_token, base_url, _access_token } from "../../Utils/Network";
import { async } from 'q';
import axios from "axios";
import Subject from "../../File/books.xlsx";
import readXlsxFile from 'read-excel-file';


function AddSubjects() {
  const [boardType, setBoardType] = useState();
  const { boardList } = useContext(UserCredsContext);
  const [files, setFiles] = useState(null);
  const [xlsxData, setXlsxData] = useState([]);
  const navigate = useNavigate();


  const handleFileUpload = async (files) => {
    const file = files[0];
    setFiles(file)

    try {
      const data = await readXlsxFile(file);
      setXlsxData(data);
    } catch (error) {
      console.error('Error parsing XLSX file', error);
    }
  };

  const renderTable = (data) => {
    if (data.length === 0) {
      return <p>No data available</p>;
    }

    return (
      <div style={{width: '80%', margin: '0 auto'}}>
        <table style={{margin: '0 auto'}}>
          <thead>
            <tr>
              {data[0].map((cell, index) => (
                <th key={index} style={{ color: 'white', textTransform: 'capitalize' }}>{cell}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.slice(1).map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td key={cellIndex} style={{ color: 'white' }}>{cell}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    );
  };


  const GotoGrade = async () => {

    // let data = {
    //     file: files,
    //   }
    //   const formData = new FormData();
    //   if (data.file) formData.append('file', data.file)
    //   try {

    //     await axios({
    //       method: "Post",
    //       url: `${base_url}external/v1/sync_subject`,
    //       data: formData,
    //       config: { headers: { 'Content-Type': 'multipart/form-data' } },
    //       headers: { 'Authorization': `Bearer ${_access_token}` },
    //     })
    //       .then(response => {
    //         if (response.status === 200 || response.status === 201) {
    //           if (response.data) {
    //             setFiles(null);
    //             alert("Successfully upload Subject")
    //             navigate(`/onboard/addsection`)
    //           }
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error, 'error');
    //       })
    //   } catch (error) {
    //     console.log(error);
    //   }
    navigate(`/onboard/addsection`)

  }
  console.log(files, "BBORADSS")

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>



        <div className={styles.headerContainer}>
          <div className={styles.heading}>
            <ArrowBackIcon style={{ fontSize: '30px' }} />
            <p>Welcome to Ed5 AI Engine</p>
          </div>
          <div>
            <a href={Subject} download className={styles.sampleDownload}> Download Sample Data</a>
          </div>
        </div>

        <div>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'white' }}>Add Subject</h2>
          </div>
          <Dropzone onDrop={handleFileUpload}>
            {({ getRootProps, getInputProps }) => (

              <div {...getRootProps()} className={styles.dragDrop}>
                <img src={Uploads} alt="images" />
                <input {...getInputProps()} />
                <p style={{ color: 'white' }}>Drag and drop Folder Here</p>
                {files && <p>{files?.name}</p>}
              </div>
            )}
          </Dropzone>
        </div>

        <div>
          {files && renderTable(xlsxData)}
        </div>


        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>Next</button>
        </div>
      </div>
    </div>
  )
}

export default AddSubjects


const board = [
  {
    id: 1,
    title: "ICSE",
  },
  {
    id: 2,
    title: "CBSE",
  },
  {
    id: 3,
    title: "International",
  }
]