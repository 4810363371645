import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./index.module.css";
import images from "../../Assets/Onboarding/Bg.png";
import { useNavigate } from "react-router-dom";
import ReactKanban from "react-kanban-dnd";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import { api_token } from "../../Utils/Network";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Container({
  classes,
  shifts,
  setClasses = () => {},
  containers,
  setContainers = () => {},
}) {
  const [draggedClass, setDraggedClass] = useState(null);

  const handleDragStart = (e, classId) => {
    setDraggedClass(classId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, containerIndex) => {
    e.preventDefault();
    const classId = draggedClass;
    // Remove the class object from its current container
    const updatedClasses = classes.filter(
      (classObj) => classObj.id !== classId
    );
    // Get the dropped class object
    const droppedClass = classes.find((classObj) => classObj.id === classId);
    // Pass the dropped class object to the appropriate container
    const updatedContainers = containers.map((container, index) => {
      if (index === containerIndex) {
        return {
          ...container,
          classes: [...container.classes, droppedClass],
        };
      }
      return container;
    });
    setContainers(updatedContainers);
    console.log(updatedContainers, "UpdataedCi");
    // Update the state of classes to remove the dropped class object
    setClasses(updatedClasses);
    // Reset the dragged class state
    setDraggedClass(null);
  };

  const handleMoveClassBack = (classId) => {
    // Find the class object to move back
    const classToMove = containers.reduce((classFound, container) => {
      const classIndex = container.classes.findIndex(
        (classObj) => classObj.id === classId
      );
      if (classIndex !== -1) {
        return container.classes.splice(classIndex, 1)[0];
      }
      return classFound;
    }, null);

    if (classToMove) {
      // Update the state by adding the class back to classes
      setClasses([...classes, classToMove]);
      // Reset the dragged class state
      setDraggedClass(null);
    }
  };

  // console.log(classes1, classes2, "SSSSSSSS")
  return (
    <div className={styles.containers}>
      <div
        className="container"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div>
          {containers.map((container, index) => (
            <div
              key={container.shift}
              className={styles.dropzone}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
            >
              <h3 style={{ color: "white" }}>{container.shift}</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                }}
              >
                {container.classes.map((classObj) => (
                  <div
                    key={classObj.id}
                    // className={styles.classData}
                    className={`${styles.classData} ${styles.mainClass}`}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, classObj.id)}
                  >
                    {`${classObj?.user?.first_name} ${classObj?.user?.last_name}`}

                    <span
                      className={styles.crossIcon}
                      onClick={() => handleMoveClassBack(classObj.id)}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div>
          {classes &&
            classes.map((classObj) => (
              <div
                key={classObj.id}
                className={styles.classData}
                draggable="true"
                onDragStart={(e) => handleDragStart(e, classObj.id)}
              >
                {`${classObj?.user?.first_name} ${classObj?.user?.last_name}`}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function DivideTeacher() {
  const [boardType, setBoardType] = useState();
  const navigate = useNavigate();
  const { classList } = useContext(UserCredsContext);
  const [classes, setClasses] = useState([]);
  const [containers, setContainers] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [alertDetails, setAlertDetails] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
    errorType: "",
  });
  const { vertical, horizontal } = alertDetails;
  // useEffect(() => {
  //     setClasses(classList)
  // }, [classList])

  useEffect(() => {
    getData();
    getTeachers();
  }, []);

  useEffect(() => {
    if (shifts.length > 0) {
      const newContainers = [];
      for (let i = 0; i < shifts.length; i++) {
        newContainers.push({
          id: shifts[i].id,
          shift: shifts[i].title,
          classes: [],
        });
      }
      setContainers(newContainers);
    }
  }, [shifts]);

  const getTeachers = () => {
    api_token
      .get(`profile/v1/teachers?page_size=100`)
      .then((res) => {
        setClasses(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getData = () => {
    api_token
      .get(`calendar/v1/shifts/`)
      .then((res) => {
        setShifts(res.data.data);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    setAlertDetails({
      ...alertDetails,
      open: false,
      message: "",
      errorType: "",
    });
  };

  const GotoGrade = () => {
    let check = false;
    const updatedContainer = containers.map((item) => {
      return {
        id: item.id,
        classes: item.classes.map((classItem) => classItem.id),
      };
    });

    updatedContainer.map((v, i) => {
      if (!v.classes.length > 0) {
        setAlertDetails({
          ...alertDetails,
          open: true,
          message: "Please Fill all the Shift",
          errorType: "error",
        });
        check = true;
      }
    });

    if (!check) {
      updatedContainer.map((v, i) => {
        console.log(v.classes, "CLAAAAAAA");
        api_token
          .patch(`/calendar/v1/shifts/${v.id}/`, { teachers_id: v.classes })
          .then((res) => {
            //   if (res.data.teachers && res?.data?.teachers?.length > 0) {
            //     alert(`Successfully added Teachers in shift ${i + 1}`);
            //   }
            if (res.status == 200 || res.status == 201) {
              // setOpen(true);
              setAlertDetails({
                ...alertDetails,
                open: true,
                message: "Successfully Added Teacher",
                errorType: "success",
              });

              setTimeout(() => {
                navigate(`/onboard/addTeachers`);
              }, 1000);
            }
            // navigate(`/onboard/timetable`)
          })
          .catch((err) => console.log(err));
      });
    }
  };

  console.log(containers, "Classssssssss");

  return (
    <div className={styles.mainBackground}>
      <div className={styles.subContainer}>
        <div className={styles.heading}>
          <ArrowBackIcon style={{ fontSize: "30px" }} />
          <p>Powered By Ed5</p>
        </div>
        <div className={styles.dragstitle}>
          Drag the Teacher in the morning or afternoon shift as preferred
        </div>

        <Container
          classes={classes}
          setClasses={setClasses}
          shifts={shifts}
          setContainers={setContainers}
          containers={containers}
        />

        <div>
          <button className={styles.btns} onClick={() => GotoGrade()}>
            Next
          </button>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={alertDetails.open}
        onClose={handleClose}
        autoHideDuration={6000}
      >
        <Alert severity={alertDetails.errorType} sx={{ width: "100%" }}>
          {alertDetails?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default DivideTeacher;
